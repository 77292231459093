<template>
    <div>
      <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
      <div class="custom-container-fluid">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h3 class="fw-600">Admin</h3>
          </div>
          <div>
            <button class="btn add_service_btn" data-bs-target="#addAdminModal" data-bs-toggle="modal">add
            Admin</button>
          </div>
        </div>
        <div style="margin-top: 5%;">
            <div class="card p-3 shadow border-0 round-17">
                <div class="modal-header border-0">
                  <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Admin List
                  </h5>
                  <div class="text-center align-self-start">
                    <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0 text-"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="bi bi-three-dots" style="color: #99B2C6"></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Action</a></li>
                      <li><a class="dropdown-item" href="#">Action</a></li>
                    </ul>
                  </div>
                </div>
                <div class="table-container">
                  <div class="table-section">
                    <div class="table-responsive">
                      <table class="custom_table custom_table_header_white">
                        <thead>
                          <tr>
                            <th class="sorting_asc">Category</th>
                            <th class="sorting">Date of Expenses</th>
                            <th class="sorting">Amount</th>
                            <th class="sorting">Vendor</th>
                            <th class="sorting">Receipt File</th>
                            <th class="">Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <a class="me-2 fw-600 round_5 p-2 row_count">01</a>
                              Rent / Lease
                            </td>
                            <td>12/01/2022</td>
                            <td>
                              <a class="bg-site-darkSeaGreen round-10 px-2 text-white">$ 100</a>
                            </td>
                            <td>Vendor Name</td>
                            <td>
                              <img src="../../../assets/images/avatar-2.svg">
                            </td>
                            <td>
                              <button @click="$router.push('/update-service')" title="edit"
                                class="btn btn-sm btn-success action_btn">
                                <i class="fas fa-edit"></i>
                              </button>
                              <button title="delete" class="btn btn-sm btn-danger action_btn">
                                <i class="fas fa-trash"></i></button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a class="me-2 fw-600 round_5 p-2 row_count">02</a>
                              Rent / Lease
                            </td>
                            <td>12/01/2022</td>
                            <td>
                              <a class="bg-site-darkSeaGreen round-10 px-2 text-white">$ 100</a>
                            </td>
                            <td>Vendor Name</td>
                            <td>
                              <img src="../../../assets/images/avatar-2.svg">
                            </td>
                            <td>
                              <button @click="$router.push('/update-service')"  title="edit"
                                class="btn btn-sm btn-success action_btn">
                                <i class="fas fa-edit"></i>
                              </button>
                              <button title="delete" class="btn btn-sm btn-danger action_btn">
                                <i class="fas fa-trash"></i></button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a class="me-2 fw-600 round_5 p-2 row_count">03</a>
                              Rent / Lease
                            </td>
                            <td>12/01/2022</td>
                            <td>
                              <a class="bg-site-darkSeaGreen round-10 px-2 text-white">$ 100</a>
                            </td>
                            <td>Vendor Name</td>
                            <td>
                              <img src="../../../assets/images/avatar-2.svg">
                            </td>
                            <td>
                              <button @click="$router.push('/update-service')"  title="edit"
                                class="btn btn-sm btn-success action_btn">
                                <i class="fas fa-edit"></i>
                              </button>
                              <button title="delete" class="btn btn-sm btn-danger action_btn">
                                <i class="fas fa-trash"></i></button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a class="me-2 fw-600 round_5 p-2 row_count">04</a>
                              Rent / Lease
                            </td>
                            <td>12/01/2022</td>
                            <td>
                              <a class="bg-site-darkSeaGreen round-10 px-2 text-white">$ 100</a>
                            </td>
                            <td>Vendor Name</td>
                            <td>
                              <img src="../../../assets/images/avatar-2.svg">
                            </td>
                            <td>
                              <button @click="$router.push('/update-service')"  title="edit"
                                class="btn btn-sm btn-success action_btn">
                                <i class="fas fa-edit"></i>
                              </button>
                              <button title="delete" class="btn btn-sm btn-danger action_btn">
                                <i class="fas fa-trash"></i></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
  
                  </div>
  
  
                </div>
              </div>
        </div>
      </div>
      <div class="modal fade" id="addAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="addAdminModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content round-20">
            <div class="modal-header border-0">
              <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addAdminModalLabel">Add Admin
                <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
              </h5>
              <button type="button" class="btn-close text-site-dark" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-4 g-4">
                <div class="col-12 col-sm-6">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Name<span style="color: red; font-size: 18px">*</span></label>
                  <input type="text" class="form-control rounded-0" placeholder="Enter name">
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Email<span style="color: red; font-size: 18px">*</span></label>
                  <input type="text" class="form-control rounded-0" placeholder="Enter email">
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Email</label>
                  <select class="form-select rounded-0">
                    <option>Placeholder</option>
                  </select>
                </div> -->
              </div>
              <div class="row mb-4 g-4">
                <div class="col-12 col-sm-6">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Contact No<span style="color: red; font-size: 18px">*</span></label>
                  <input type="text" class="form-control rounded-0" placeholder="Enter contact no">
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Date Of Birth<span style="color: red; font-size: 18px">*</span></label>
                  <input type="date" class="form-control rounded-0" placeholder="Enter date of birth">
                </div>
              </div>
              <div class="modal-body">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Password<span style="color: red; font-size: 18px">*</span></label>
                  <div class="input-group" id="show_hide_password">
                      <input :type="inputTypePassword" v-model="password" class="form-control border-end-0"
                        id="inputPassword" placeholder="Enter Password" /><a href="javascript:void(0);"
                        @click="changePasswordType()" class="input-group-text bg-transparent"><i
                          :class="iconStatus ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i></a>
                    </div>
            </div>
                <div class="modal-body">
                  <label class="form-label text-site-dark ff-poppins400 fs-17">Confirm Password<span style="color: red; font-size: 18px">*</span></label>
                  <div class="input-group" id="show_hide_password">
                      <input :type="inputTypeConfirmPassword" v-model="confirmPassword"
                        class="form-control border-end-0" id="inputConfirmPassword"
                        placeholder="Enter Confirm Password" /><a href="javascript:void(0);"
                        @click="changeConfirmPasswordType()" class="input-group-text bg-transparent"><i
                          :class="iconFlag ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i></a>
                    </div>
                </div>
              </div>
            <div class="text-center p-3">
              <button type="button"
                class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Create</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src="../js/admin.js">
  </script>
  
  <style scoped>
    @import "../css/add_admin.css";
  </style>