import Loading from "vue-loading-overlay";
import Past from "../template/Past";
import { authHeader } from "../../../auth";
export default {
    name: "Checkout",
    components: {
        Loading,
        Past,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            productValue: [],
            serviceValue: [],
            selectedProduct: [],
            selectedService: [],
            productURL: "",
            addedProductList: [{
                productId: ""
            }],
            confirmedProduct: [],
            confirmedService: [],
            colorValue: [''],
            sizeValue: [''],
            subTotal: 0,
            serviceSubTotal: 0,
            grandTotal: 0,
            grandSubTotal: 0,
            isExistingUser: null,
            setURL: "",
            userList: [],
            userDetails: "",
            userName: "",
            userEmail: "",
            userMobile: "",
            shippingName: "",
            shippingEmail: "",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingContactNo: "",
            shippingCountry: "",
            shippingPostCode: "",
            stateCode: "",
            shippingCity: "",
            remarks: "",
            productList: [],
            discount: '',
            total: 0,
            serviceTotal: 0,
            idx: 0,
            service_idx: 0,
            priceList: [],
            servicePriceList: [],
            countries: [],
            countryList: [],
            shippingType: "",
            serviceTypeList: [],
            serviceType: "",
            packageType: "",
            shippingRemarks: "",
            totalWeight: 0,
            shippingCost: 0,
            addedServiceList: [
                {
                    serviceId: ""
                }
            ],
            serviceList: [],
            clientList: [],
            discountAmount: 0,
            shippingAddressData: {},
            serviceRequestDate: "",
            pastServiceList:[],
            pastProductList:[],
            filters :{
                product: "",
                service: ""
            },
            seeService: false,
            seeProduct: false,
            expireDate: "",
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: ""
            },
            paymentMethodName: "",
            paymentStatus: "Pending",
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
        }
    },
    async mounted() {
        await this.getProductList();
        await this.getServicesList();
        await this.getClientList();
    },
    methods: {
        setBillingAdd1(locationData) {
            let location = locationData.formatted_address;
            console.log(location);
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressOne = locationData.name;

            this.shippingAddressData.city = "";
            this.shippingAddressData.postCode = "";
            this.shippingAddressData.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.shippingAddressData.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.shippingAddressData.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.shippingAddressData.stateCode = addressComponent[i].short_name;
                }
            }
        },
        setBillingAdd2(locationData) {
            let location = locationData.formatted_address;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.shippingAddressData.addressTwo = locationData.name;
        },

        getProductList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.data.data.map((el) => {
                        return { value: el, label: el.name };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        formatCCnum: function () {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        getClientList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.client.clientURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.clientList = response.data.data.data.map((el) => {
                        return { value: el, label: el.clientName };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getServicesList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.data.data.map((el) => {
                        return { value: el, label: el.name };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getPastData: async function() {
            await this.getPastList();
        },
        getPastList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.pastURL + '?product=' + this.filters.product + '&service=' + this.filters.service,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.pastServiceList = response.data.service;
                    this.pastProductList = response.data.product;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        undoSeeProducts: async function() {
            this.filters.product = "";
            this.seeProduct = false;
            await this.getPastList();
        },
        undoSeeService: async function() {
            this.filters.service = "";
            this.seeService = false;
            await this.getPastList();
        },
        seeAllProducts: async function() {
            this.filters.product = "all";
            this.seeProduct = true;
            await this.getPastList();
        },
        seeAllServices: async function() {
            this.filters.service = "all";
            this.seeService = true;
            await this.getPastList();
        },
        getShippingAddress: async function (id) {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.getShippingAddress + id,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.data.statusCode == 200) {
                    this.shippingAddressData = response.data.data;
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async setUserData(data) {
            await this.getShippingAddress(data.id);
            this.userName = data.clientName;
            this.userEmail = data.email;
            this.shippingEmail = data.email;
            this.userMobile = data.phoneNo;
            // this.shippingCountry = {
            //     name: this.shippingAddressData.country,
            //     code: this.shippingAddressData.countryCode
            // }
        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        addProduct() {
            this.idx = this.idx + 1;
            this.addedProductList.push({ productId: "" });
        },
        addService() {
            this.service_idx = this.service_idx + 1;
            this.addedServiceList.push({ productId: "" });
        },
        calculateWeight: function () {
            this.totalWeight = 0;
            for (let i = 0; i < this.selectedProduct.length; i++) {
                this.totalWeight = this.totalWeight + parseFloat(this.selectedProduct[i].weight) * parseFloat(this.selectedProduct[i].quantity);
            }
        },
        productData(data, index) {
            if (this.selectedProduct[index] != undefined) {
                this.selectedProduct[index] = data;
                this.priceList[index] = data.clientPrice
                data.quantity = 1
            } else {
                data.quantity = 1
                this.selectedProduct.push(data);
                this.priceList[index] = data.clientPrice;
            }
            this.calculateTotal();
        },
        serviceData(data, index) {
            if (this.selectedService[index] != undefined) {
                this.selectedService[index] = data;
                this.servicePriceList[index] = data.price
                data.quantity = 1
            } else {
                data.quantity = 1
                this.selectedService.push(data);
                this.servicePriceList.push(data.price);
            }
            this.calculateService();
        },
        selectColor(index) {
            this.selectedProduct[index].chossedColor = this.colorValue[index];
        },
        selectSize(index) {
            this.selectedProduct[index].chossedSize = this.sizeValue[index];
        },
        increaseQuantity(index) {
            this.selectedProduct[index].quantity = this.selectedProduct[index].quantity + 1;
            this.calculateTotal();
        },
        decreaseQuantity(index) {
            (this.selectedProduct[index].quantity == 1) ? (this.selectedProduct[index].quantity = 1) : (this.selectedProduct[index].quantity = this.selectedProduct[index].quantity - 1);
            this.calculateTotal()
        },
        removeItem(index) {
            this.selectedProduct.splice(index, 1);
            this.addedProductList.splice(index, 1);
            this.productValue.splice(index, 1);
            this.calculateTotal()
        },
        removeService(index) {
            this.selectedService.splice(index, 1);
            this.addedServiceList.splice(index, 1);
            this.serviceValue.splice(index, 1);
            this.calculateService()
        },
        addDiscount: function () {
            let discountCheck = ''
            if (this.discount.slice(-1) == '%') {
                discountCheck = this.discount.slice(0, -1);
            }
            else {
                discountCheck = this.discount;
                this.grandTotal = this.grandSubTotal;
            }
            if (isNaN(discountCheck)) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter valid discount amount or percentage"
                });
                this.discount = '';
                return;
            }
            if (this.discount && !isNaN(discountCheck)) {
                if (this.discount.includes("%")) {
                    let converted_discount = parseFloat(this.discount) / 100.0;
                    this.grandTotal = this.grandSubTotal - this.grandSubTotal * converted_discount;
                    this.discountAmount = converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseFloat(this.discount);
                        this.grandTotal = this.grandSubTotal - converted_discount;
                        this.discountAmount = converted_discount;
                    }
                    catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter discount amount or percentage"
                        });
                    }
                }
            }
            else {
                this.grandTotal = this.grandSubTotal;
            }

        },
        calculateTotal() {

            try {
                this.subTotal = 0;
                for (let el in this.selectedProduct) {
                    if (this.selectedProduct[el].clientPrice && !isNaN(this.selectedProduct[el].clientPrice)) {
                        this.subTotal = this.subTotal + (this.selectedProduct[el].clientPrice ? this.selectedProduct[el].clientPrice * this.selectedProduct[el].quantity : this.selectedProduct[el].discountedPrice * this.selectedProduct[el].quantity);
                        this.total = this.subTotal;
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter valid amount"
                        });
                        this.subTotal = 0;
                        this.selectedProduct[el].clientPrice = this.priceList[el];
                        this.calculateTotal();
                    }
                }
                this.grandSubTotal = this.subTotal + this.serviceSubTotal;
                this.grandTotal = this.grandSubTotal;
                if (this.discount) {
                    this.addDiscount();
                }
                // this.calculateWeight();
            }
            catch (error) {
                console.log(error);
            }

        },
        calculateService() {
            this.serviceSubTotal = 0;
            for (let el in this.selectedService) {
                if (this.selectedService[el].price && !isNaN(this.selectedService[el].price)) {
                    this.serviceSubTotal = this.serviceSubTotal + (this.selectedService[el].price ? this.selectedService[el].price * this.selectedService[el].quantity : this.selectedService[el].discountedPrice * this.selectedService[el].quantity);
                    this.serviceTotal = this.serviceSubTotal;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter valid amount"
                    });
                    this.selectedService[el].price = this.servicePriceList[el];
                    this.calculateService();
                }

            }
            this.grandSubTotal = this.subTotal + this.serviceSubTotal;
            this.grandTotal = this.grandSubTotal
            if (this.discount) {
                this.addDiscount();
            }
        },
        getServiceList: async function () {
            await this.checkShipping();
        },
        getUpsServiceList: async function () {
            await this.upsCheckShipping();
        },
        setShippingCost: function (price) {
            this.shippingCost = price;
        },
        checkShipping: async function () {
            this.shippingAddressData.country = this.shippingCountry.name;
            this.shippingAddressData.countryCode = this.shippingCountry.code;
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        upsCheckShipping: async function () {
            let data = {
                shippingAddress: this.shippingAddressData,
                weightValue: this.totalWeight
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.upsCheckShippingURL,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.statusCode) {
                    if (response.data.statusCode == 400 || response.data.statusCode == 401 || response.data.statusCode == 403 || response.data.statusCode == 404 || response.data.statusCode == 500 || response.data.statusCode == 503) {
                        for (let i = 0; i < response.data.data.response.errors.length; i++) {
                            this.$swal.fire({
                                icon: "error",
                                text: response.data.data.response.errors[i].message
                            });
                        }
                    }
                }
                else if (response.status == 200) {
                    this.serviceTypeList = response.data;
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        checkValidation: function () {
            if (!this.userName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer name.",
                });
                return false;
            }
            if (!this.userEmail) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer email.",
                });
                return false;
            }
            if (!this.userMobile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide customer mobile.",
                });
                return false;
            }
            if (!this.shippingAddressData.billingName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing name.",
                });
                return false;
            }
            if (!this.shippingAddressData.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing email.",
                });
                return false;
            }
            if (!this.shippingAddressData.contactNo) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing contact no.",
                });
                return false;
            }
            if (this.shippingAddressData.contactNo.length < 10) {
                this.$swal.fire({
                    icon: "info",
                    text: "Mobile number must be at least 10 digit.",
                });
                return false;
            }
            if (!this.shippingAddressData.addressOne) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing address one.",
                });
                return false;
            }
            if (!this.shippingAddressData.city) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing city.",
                });
                return false;
            }
            if (!this.shippingAddressData.postCode) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please provide billing post code.",
                });
                return false;
            }
            if (this.selectedProduct.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select product.",
                });
                return false;
            }
            return true;

        },
        setPaymentAmount: function() {
            this.paymentInfo.amount = this.grandTotal;
        },
        handlePayment: async function (payment) {
            if (payment == 'Pay Later') {
                this.createOrder('Pay Later');
            } else if (payment == 'Pay Now') {
                if (this.paymentValidation()) {
                    this.createOrder('Pay Now');
                }
            } else {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select payment option."
                });
                return;
            }
        },
        async createOrder(paymentType) {
            try {
                if (this.checkValidation()) {
                    this.isLoading = true;
                    this.confirmedProduct = this.selectedProduct.map((el) => {
                        return { productId: el.id, price: el.clientPrice, quantity: el.quantity };
                    })
                    this.confirmedService = this.selectedService.map((el) => {
                        return { serviceId: el.id, price: el.price };
                    })
                    if(paymentType == 'Pay Later') {
                        this.paymentInfo = {
                            name: "",
                            cardNumber: "",
                            securityCode: "",
                            amount: ""
                        }
                        this.paymentMethodName = "Pay Later"
                    }
                    else {
                        this.paymentMethodName = "Credit/Debit Card"
                        if (this.paymentInfo.amount && this.paymentInfo.amount < this.grandTotal) {
                            this.paymentStatus = "Partial Paid"
                        }
                        else if(this.paymentInfo.amount && this.paymentInfo.amount == this.grandTotal) {
                            this.paymentStatus = "Paid" 
                        }
                    }
                    let data = {
                        customerName: this.userName,
                        email: this.userEmail,
                        contactNo: this.userMobile,
                        billingAddress: JSON.stringify({
                            name: this.shippingAddressData.billingName,
                            contactNo: this.shippingAddressData.contactNo,
                            email: this.shippingAddressData.email,
                            city: this.shippingAddressData.city,
                            postCode: this.shippingAddressData.postCode,
                            stateCode: this.shippingAddressData.stateCode,
                            country: this.shippingCountry.name,
                            countryCode: this.shippingCountry.code,
                            addressOne: this.shippingAddressData.addressOne,
                            addressTwo: this.shippingAddressData.addressTwo,
                        }),
                        ccNum: this.paymentInfo.cardNumber ? this.paymentInfo.cardNumber.split(' ').join('') : '',
                        ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                        cvv: this.paymentInfo.securityCode,
                        amount: this.paymentInfo.amount,
                        products: this.confirmedProduct,
                        services: this.confirmedService,
                        remarks: this.remarks,
                        discountAmount: this.discountAmount,
                        serviceRequestDate: this.serviceRequestDate,
                        paymentStatus: this.paymentStatus,
                        paymentMethodName: this.paymentMethodName
                    }
                    let config = {
                        method: "POST",
                        url: this.$serverURL + this.$api.order.createOrderURL,
                        data: data,
                        headers: authHeader()
                    };
                    await this.$axios(config).then((response) => {
                        this.isLoading = false;
                        if (response.data.statusCode == 200) {
                            this.$swal.fire({
                                icon: "success",
                                text: response.data.message,
                            });
                            document.getElementById("buttonClose").click();
                            this.$router.push('/checkout-list');
                        }
                        else if (response.data.statusCode == 400) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.message,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            }).then(result => {
                                console.log(result);
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.errors.responsetext,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                })
                            });
                        }
                    }).catch(error => {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            }
            catch(error){
                console.log(error);
            }
            

        }
    },
    watch: {
        isExistingUser: function () {
            if (this.isExistingUser == false) {
                this.userDetails = ""
                this.userName = "";
                this.userEmail = "";
                this.userMobile = "";
                this.shippingName = "";
                this.shippingContactNo = "";
                this.shippingAddress = ""
            }
        },
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        },
    }
}
