import { authHeader } from "../../../auth";
import { reactive } from 'vue'
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// import useEvents from '../../../composables/useEvents.js'

// const id = ref(1)

// const {getEvents, createEvent, updateEvent, deleteEvent } = useEvents()
export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      options: reactive({
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        eventClick: this.handleDateClick,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay,listDay'
        },
        editable: true,
        selectable: true,
        select: this.handleDateSelect,
        weekends: true,
        dayMaxEvents: true,
        longPressDelay: 0.1,
        events: [
          { title: 'event 1', date: '2023-02-01',}
        ],
        eventColor: '#7e0000',
        
        eventsSet: this.handleEvents
      }),
      currentEvents: [],
      isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false,
      isAppointmentList: false,
      page: 1,
      limit: 10,
      totalPage: 0,
      orderList: [],
      selectedDate: "",

    }
  },
  async mounted() {
    await this.getCalenderData();
  },
  methods: {
    getCalenderData: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.calender.calenderURL,
        headers: authHeader()
      };
      await this.$axios(config).then((response) => {
        if (response.status === 200) {
          this.options.events = response.data.map(el => {
            return {
              title: el.label,
              date: this.$filters.eventDate(el.date)
            }
          })
        }
      }).catch(error => {
        console.log(error);
      });
    },
    handleDateSelect(selectInfo) {
      this.selectedDate = selectInfo.startStr;
      let params = '?page=' + this.page + '&limit=' + this.limit;
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.dateWiseAppointmentList.dateWiseAppointmentListURL + params + "&date=" + this.selectedDate,
        headers: authHeader()
      };
      this.getAppointmentList(config);
      this.isAppointmentList = true;
    },
    async getAppointmentList(config) {
      this.orderList = [];
      try {
        await this.$axios(config).then(async (response) => {
          this.orderList = [];
          if (response.data.statusCode == 200) {
            this.orderList = await response.data.data.data;
            this.totalPage = await response.data.data.pageCount;
          }

        }).catch(error => {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      }
    },
    updateHandler: async function () {
      let params = '?page=' + this.page + '&limit=' + this.limit;
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.dateWiseAppointmentList.dateWiseAppointmentListURL + params + "&date=" + this.selectedDate,
        headers: authHeader()
      };
      await this.getOrderList(config);
    },
    handleDateClick: function (arg) {
      this.$swal.fire({
        icon: "info",
        title: arg.event.title
      })
    },
    handleEvents(events) {
      this.currentEvents = events;
      console.log(this.currentEvents)
    },
    handleToogle: function () {
      this.orderList = [];
      this.selectedDate = "";
      this.page = 1;
      this.totalPage = 0;
      this.isAppointmentList = false;
    }
  }
}
