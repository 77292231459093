import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            email: null,
            password: null,
            inputType: "password",
            iconFlag: true
        }
    },
    created() {
        document.title = "Cosmo Salon - Login";
    },
    methods: {
        signIn: function() {
            this.isLoading = true;
            if(!this.email) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter e-mail!"
                });
            }
            else if(!this.password){
              this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter password!"
                });
            }
            else {
                let data = {
                    email: this.email,
                    password: this.password
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.professionalLogin,
                    data: data
                };
                this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        localStorage.setItem("name", response.data.user.fullName);
                        localStorage.setItem("email", response.data.user.email);  
                        localStorage.setItem("role", response.data.user.role);
                        localStorage.setItem("isAdminVerified", response.data.user.isAdminVerified);
                        this.$router.push("/dashboard");
                    }
                    else {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: "Please try again"
                        });
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }
        },
        changeInputType: function() {
            if(this.inputType == "password") {
                this.inputType = "text";
                this.iconFlag = false;
            }
            else if(this.inputType == "text") {
                this.inputType = "password";
                this.iconFlag = true;
            }
        }
    }
}