<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div v-if="!isLogin && !this.watchLoginCheck" class="content-container">
      <div class="body_section" :class="[store.active ? 'close' : '']">
        <router-view></router-view>
      </div>
      <Header></Header>
      <Sidebar></Sidebar>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "./components/layouts/template/Header";
import Sidebar from "./components/layouts/template/Sidebar";
import { store } from "./store";
import { authHeader } from "./auth";
export default {
  name: "App",
  components: {
    Loading,
    Header,
    Sidebar
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      isLoading: false,
      fullPage: true,
      loader: "bars",
      isLogin: false,
      watchLoginCheck: false,
      store
    };
  },
  created() {
    if (
      this.$route.path == "/signin" ||
      this.$route.path == "/signup" ||
      this.$route.path == "/terms-and-conditions" ||
      this.$route.path == "/active-account" ||
      this.$route.path == "/forgot-password" ||
      this.$route.name == "ResetPassword"
    ) {
      if (this.token) {
        this.isLogin = false;
        this.checkAdminVerified();
        this.$router.push("/dashboard");
      } else if (!this.token) {
        this.isLogin = true;
        this.watchLoginCheck = false;
      }
    } else {
      this.isLogin = false;
    }
  },
  methods: {},
  watch: {
    $route() {
      if (
        this.$route.path == "/signin" ||
        this.$route.path == "/signup" ||
        this.$route.path == "/terms-and-conditions" ||
        this.$route.path == "/active-account" ||
        this.$route.path == "/forgot-password" ||
        this.$route.name == "ResetPassword"
      ) {
        if (this.token) {
          this.isLogin = false;
          this.checkAdminVerified();
          this.$router.push("/dashboard");
        } else if (!this.token) {
          this.isLogin = true;
        }
      } else {
        this.checkAdminVerified();
        let config = {
          method: "POST",
          url: this.$serverURL + this.$api.auth.validateTokenURL,
          headers: authHeader()
        };
        this.$axios(config)
          .then(response => {
            if (response.status == 201) {
              if (response.data === false) {
                localStorage.clear();
                this.isLogin = true;
                this.watchLoginCheck = true;
                this.$router.push("/signin");
              } else {
                this.watchLoginCheck = false;
                this.isLogin = false;
              }
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          });
      }
    }
  }
};
</script>

<style>
@import "assets/css/all.min.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "assets/css/common.scss";
@import "assets/css/custom_table.scss";
@import "assets/css/vue-loading.scss";
</style>
