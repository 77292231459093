import Loading from "vue-loading-overlay";

export default {
    name: "Report",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            reports: [
                {title: 'Sales'} ,
                {title: 'Appointment'},
                {title: 'Inventory'},
                {title: 'Most Valuable Clients'},
                {title: 'Expenses'}
            ],
            previousDate: "",
            currentDate: "",
            startDate: "",
            endDate: "",
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
        }
    },
    mounted() {
        var date = new Date();
        date = date.setDate(date.getDate() - 1);
        this.previousDate = this.getDayName(date);
        this.currentDate = this.getDayName(new Date());
    },
    methods: {
        getDayName: function(date) {
            let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            let dayName = days[new Date(date).getDay()];
            return dayName;
        },
        setTitle: function(reportName){
            this.reportName = reportName;
        },
        customReport: function() {
            if(!this.startDate) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select start date",
                });
                return;
            }
            if (!this.endDate) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select end date",
                });
                return;
            }
            document.getElementById("closeAdd").click();
            this.$router.push({
                name:'ReportDetails',
                params: {name: this.reportName},
                query: {reportType: 'custom', startDate: this.startDate, endDate: this.endDate}
              });
              
        }
    }
}
