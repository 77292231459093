import Loading from "vue-loading-overlay";
import CircleProgress from "vue3-circle-progress";
import { authHeader } from "../../../auth";
export default {
    name: "Analytics",
    components: {
        Loading,
        CircleProgress
    },
    data () {
        return{
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            dashboardData: [],
            topServices: [['Income', 'Income per Services']],
            options2: {
                width: 50,
                height: 20,
                backgroundColor: 'transparent',
                borderRadius: 10,
                curveType: 'function',
                chartArea:{left:0,top:0,width:'100%',height:'100%'},
                animation: {duration: 4,easing:'linear'},
                colors: ['#7E0000'],
                legend: 'none',
                vAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}},
                hAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}}

            },
            collectionData: [
                ["Day", "The Avengers"],
                [1, 24],
                [2,  17],
                [3,  45],
                [4,  35],
                [5,  45],
            ],
            optionsPie: {
                width:250,
                height:300,
                backgroundColor: 'transparent',
                chartArea: { left: 10, top: 40, right: 20, bottom: 50, width: '100%', height: '100%' },
                colors: ['#7E0000', '#949D7F', '#F3E2E2', '#3E3E3E'],
                pieHole: 0.5,
                tooltip: { trigger: 'selection' },
                legend: { position: 'bottom', maxLines: 2, textStyle: { color: 'black', fontSize: 12 } },
                pieSliceText: '',
                pieSliceBorderColor: 'none',
                title: 'Top Services',
            },
            chartData: [
                ['Task', 'Hours per Day'],
                ['This Month Sales', 410],
                ['Avg.Ticket', 1423],
                [' Sales',    405],
                ['  Reports',    90]
            ],
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
        }
    },
    async mounted() {
        await this.getDashboardData();
    },
    methods: {
        getDashboardData: async function() {
            let config = {
              method: "GET",
              url: this.$serverURL + this.$api.dashboard.analyticalDashboardAPI,
              headers: authHeader()
          };
          await this.$axios(config).then((response) => {
              if (response.status == 200) {
                  this.dashboardData = response.data.data;
                  response.data.data.topServices.map(el =>{
                    this.topServices.push(el);
                  });
              }
          }).catch(error => {
              console.log(error);
          });
          }
    }
}
