<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Expenses</h3>
        </div>
      </div>
      <div class="mt-3">
        <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link checkout_nav_link active mx-0" id="pills-checkout-tab" data-bs-toggle="pill"
              data-bs-target="#pills-checkout" type="button" role="tab" aria-controls="pills-checkout"
              aria-selected="true">Add New Expense</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link checkout_nav_link mx-0" id="pills-past-tab" data-bs-toggle="pill"
              data-bs-target="#pills-past" type="button" role="tab" aria-controls="pills-past"
              aria-selected="false">View & track all expense</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-checkout" role="tabpanel"
            aria-labelledby="pills-checkout-tab" tabindex="0">
            <div class="d-flex justify-content-center align-items-center min-height-400">
              <div class="text-center">
                <p class="fs-18 text-site-dark">Expenses</p>
                <p class="fs-18 text-site-dark">
                  Please add a new expense with the below button.
                </p>
                <button class="btn bg-site-darkRed text-white border-site-darkRed" data-bs-target="#addExpensesModal"
                  data-bs-toggle="modal">Add New Expense</button>
              </div>
            </div>

          </div>
          <div class="tab-pane fade" id="pills-past" role="tabpanel" aria-labelledby="pills-past-tab" tabindex="0">
            <div class="card p-3 shadow border-0 round-17">
              <div class="modal-header border-0">
                <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Expense List
                </h5>
                <div>
          <button class="btn btn-warning text-white" @click="exportData">EXPORT</button>
        </div>
              </div>
              
              <div class="table-container">
                <div class="table-section">
                  <div class="table-responsive">
                    <table class="custom_table custom_table_header_white">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Category</th>
                          <th>Date of Expense</th>
                          <th>Amount</th>
                          <th>Vendor</th>
                          <th>Receipt File</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="expenseList.length > 0">
                        <tr v-for="(row, index) in expenseList" :key="index">
                          <td data-label="Sl :">{{index + 1}}</td>
                          <td data-label="Category :">
                            {{ row.categoryName }}
                          </td>
                          <td data-label="Date of Expense :">{{ $filters.formatDate(row.expenseDate)}}</td>
                          <td data-label="Amount :">
                            <a class="bg-site-darkSeaGreen round-10 px-2 text-white">$ {{ row.amount }}</a>
                          </td>
                          <td data-label="Vendor :">{{ row.vendor == "null" ? "N/A" : row.vendor }}</td>
                          <td data-label="Receipt File :">
                            <!-- <img :src="$imageURL + 'expense/' + row.file" height="100" width="150"> -->
                            <a title="reciept" class="btn btn-sm btn-warning text-white action_btn" @click="downloadFile(row.file)">
                              <i class="far fa-file-pdf"></i></a>
                          </td>
                          <td data-label="Action :">
                            <!-- <a @click="imageShow(row.file)" data-bs-toggle="modal" data-bs-target="#imageModal"
                              title="details" class="btn btn-sm btn-info text-white action_btn">
                              <i class="fas fa-file-alt"></i></a> -->
                            <!-- <button data-bs-toggle="modal" data-bs-target="#editModal" title="edit"
                              class="btn btn-sm btn-success action_btn">
                              <i class="fas fa-edit"></i>
                            </button> -->
                            <button title="Delete" @click="deleteExpense(row.id)"
                              class="btn btn-sm btn-danger action_btn">
                              <i class="fas fa-trash"></i></button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                    <tr>
                      <td colspan="7">
                        <p class="mb-0 text-center">No Data Found !</p>
                      </td>
                    </tr>
                  </tbody>
                    </table>
                  </div>

                </div>


              </div>
            </div>
            <nav
            class="page-navigation justify-content-center d-flex mt-3"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="expenseList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
    <div class="modal fade" id="addExpensesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addExpensesModalLabel">Add Expense

            </h5>
            <button type="button" id="buttonClose" class="btn-close text-site-dark" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Category</label>
                <select v-model="selectedCategory" class="form-select mb-3">
                  <option v-for="(row, index) in expenseCategory" :key="index" :value="{ id: row.id, name: row.name }">
                    {{ row.name }}
                  </option>
                </select>

              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Date of Expense</label>
                <input type="date" v-model="expenseDate" class="form-control rounded-0" placeholder="">
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label text-site-dark ff-poppins400 fs-17">Expense Details </label>
              <textarea v-model="expenseDetails" class="form-control rounded-2" rows="4"
                placeholder="Enter expense details"></textarea>
            </div>
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Amount</label>
                <input type="text" v-model="expenseAmount" class="form-control rounded-0"
                  placeholder="Enter expense amount">
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Vendor(Optional)</label>
                <input type="text" v-model="vendor" class="form-control rounded-0" placeholder="Enter vendor name">
              </div>
            </div>
            <div class="mb-4">
              <label for="formFile" class="btn w-100 bg-site-papayaWhip border-site-papayaWhip fs-18 text-site-dark">
                <i class="bi bi-link-45deg me-2 fs-18"></i>
                Upload Documents</label>
              <input class="d-none" type="file" ref="file" @change="onFileChange($event)" id="formFile">

              <img v-if="previewFile" :src="previewFile" height="100" width="120" style="margin-top: 10px;" />
            </div>
            <!-- <div class="form-control">
              <div class="form-switch d-flex justify-content-between gap-4 ps-0">
                <label class="" for="flexSwitchCheckDefault">Default switch checkbox input</label>
                <input class="form-check-input add_expenses_form-check-input" type="checkbox" role="switch"
                  id="flexSwitchCheckDefault">
              </div>
            </div> -->
          </div>
          <div class="text-center p-3">
            <button type="button" @click="addExpense"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="imageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="imageModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400">Expense File</h5>
            <button type="button" id="buttonClose" class="btn-close text-site-dark" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-12" style="text-align: center;">
                <img :src="$imageURL + 'expense/' + showFile" height="300" width="400">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script src="../js/expenses.js">
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0;
    vertical-align: middle!important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.custom_table.custom_table_header_grayish tbody tr {
    border-bottom: 3px solid #b3b0b0 !important;
    background-color: #f7f6f6!important;
  }
  table.custom_table.custom_table_header_white tbody tr td {
    text-align: right!important;
    background-color: #f7f6f6!important;
  }
  table tr {
    border-bottom: 3px solid #ddd!important;
    display: block;
    margin-bottom: .725em;
    background-color: #b3b0b0!important;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right!important;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
  @import "../css/expenses.css";
  @import "../css/add_expenses.css";
  @import "../css/ViewAllExpenses.css";
  @import url("../../../assets/css/pagination.css");
</style>
