import Loading from "vue-loading-overlay";
import { store } from "../../../store";
import { authHeader } from "../../../auth";
import QRCodeVue3 from "qrcode-vue3"
export default {
  name: "home",
  components: {
    Loading,
    QRCodeVue3
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      name: localStorage.getItem('name'),
      dashboardData: [],
      topProducts: [['Income', 'Income per Products']],
      topServices: [['Income', 'Income per Services']],
      store,
      options1: {
        width: 100,
        height: 50,
        backgroundColor: 'transparent',
        borderRadius: 10,
        bar: { groupWidth: '25%' },
        chartArea:{left:0,top:0,width:'100%',height:'100%'},
        animation: {duration: 4,easing:'linear'},
        colors: ['#EA6EA4', '#E2136E'],
        legend: { position: 'right', maxLines: 2, textStyle: { color: 'black', fontSize: 14 } },
         vAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}},

      },
      options2: {
        width: 80,
        height: 50,
        backgroundColor: 'transparent',
        borderRadius: 10,
        curveType: 'function',
        chartArea:{left:0,top:0,width:'100%',height:'100%'},
        animation: {duration: 4,easing:'linear'},
        colors: ['#7E0000'],
        legend: 'none',
         vAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}},
        hAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}}

      },
      options3: {
        width: 100,
        height: 50,
        backgroundColor: 'transparent',
        borderRadius: 10,
        curveType: 'function',
        chartArea:{left:0,top:0,width:'100%',height:'100%'},
        animation: {duration: 4,easing:'linear'},
        colors: ['#ffffff'],
        legend: 'none',
         vAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}},
        hAxis: {baselineColor: 'transparent', baseline: null,gridlines:{color: 'transparent'}}

      },
      optionsServices: {
        width: '100%',
        height: 300,
        backgroundColor: 'transparent',
        borderRadius: 10,
        chartArea: { left: 20, top: 40, right: 20, bottom: 100, width: '90%', height: '90%' },
        colors: ['#610000', '#ECEEF2', '#F3E2E2'],
        pieHole: 0.7,
        legend: { position: 'bottom', maxLines: 2, textStyle: { color: 'black', fontSize: 14 } },
        pieSliceText: 'none',
        pieSliceBorderColor: 'none',
        title: 'Top Services',
      },
      optionsProducts: {
        width: '100%',
        height: 300,
        backgroundColor: 'transparent',
        borderRadius: 10,
        chartArea: { left: 20, top: 40, right: 20, bottom: 100, width: '90%', height: '90%' },
        colors: ['#610000', '#ECEEF2', '#F3E2E2'],
        pieHole: 0.7,
        legend: { position: 'bottom', maxLines: 2, textStyle: { color: 'black', fontSize: 14 } },
        pieSliceText: 'none',
        pieSliceBorderColor: 'none',
        title: 'Top Products',
      },
      myData: [
        ['Data', 'value'],
        ['New request', 1],
        ['Sandbox', 2],
        ['Live', 2]
      ],
      collectionData: [
        ["Day", "The Avengers"],
        [1, 24],
        [2,  17],
        [3,  45],
        [4,  35],
      ],
      chartData: [
        ['Task', 'Hours per Day'],
        ['Work',     11],
        ['Watch TV', 2],
        ['Sleep',    7]
      ],
      value: this.$clientURL + localStorage.getItem('email'),
      isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
    }
  },
  async mounted() {
    await this.getDashboardData();
  },
  methods: {
    getDashboardData: async function() {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.dashboard.dashboardAPI,
        headers: authHeader()
    };
    await this.$axios(config).then((response) => {
        if (response.status == 200) {
            this.dashboardData = response.data.data;
            response.data.data.topProducts.map(el =>{
              this.topProducts.push(el);
            });
            response.data.data.topServices.map(el =>{
              this.topServices.push(el);
            });
        }
    }).catch(error => {
        console.log(error);
    });
    },
    downloadQr: function(){
     let img = document.getElementsByClassName("img-qr")[0];
     console.log(img.src);
     var a = document.createElement("a"); //Create <a>
    a.href =  img.src; //Image Base64 Goes here
    a.download = "qrCode.png"; //File name Here
    a.click();
    }
  },
};
