import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "ProductDetails",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: "bars",
            id: this.$route.params.id,
            data: {}
        }
    },
    async created() {
       await this.getProductDetails();
    },
    methods: {
        async getProductDetails() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.requestURL + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.data = response.data.data[0];
                }
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    }
}
