import { createRouter, createWebHashHistory } from 'vue-router';
import guard from "../guard";
import Signup from "../components/auth/template/Signup";
import ActiveAccount from "../components/auth/template/ActiveAccount";
import Signin from "../components/auth/template/Signin";
import ForgotPassword from "../components/auth/template/ForgotPassword";
import ResetPassword from "../components/auth/template/ResetPassword";
import Home from "../components/dashboard/template/Home";
import More from "../components/more/template/More";
import UpdateService from "../components/Service/updateService/template/UpdateService";
import AddService from "../components/Service/addService/template/AddService";
import ServiceList from "../components/Service/ServiceList/template/ServiceList";
// import ServiceCategory from "../components/Service/serviceCategory/template/ServiceCategory";
import ServiceColor from "../components/Service/serviceColor/template/ServiceColor";
import Analytics from "../components/analytics/template/Analytics";
import Report from "../components/report/template/Report";
import ReportDetails from "../components/report/template/ReportDetails";
import Checkout from "../components/checkout/template/Checkout";
import Appointment from "../components/checkout/template/Appointment";
import CheckoutList from "../components/checkout/template/CheckoutList";
import AppointmentList from "../components/checkout/template/AppointmentList";
import CheckoutDetails from "../components/checkout/template/CheckoutDetails";
import Client from "../components/client/template/Client";
import Admin from "../components/admin/template/Admin";
import ClientDetails from "../components/client/template/ClientDetails";
import Expenses from "../components/expenses/template/Expenses";
import ExpenseCategory from "../components/expenses/template/ExpenseCategory";
import Products from "../components/Products/template/Products";
import AddProducts from "../components/Products/template/AddProducts";
import ProductDetails from "../components/Products/template/ProductDetails";
import ProductBrand from "../components/Products/template/ProductBrand";
import ProductCategory from "../components/Products/template/ProductCategory";
import Calender from "../components/calender/template/Calender";
import EditProduct from "../components/Products/template/EditProduct";
import PaymentInformation from "../components/paymentInformation/template/PaymentInformation";
// import PlatformPayment from "../components/platformPayment/template/PlatformPayment";
import PaymentLog from "../components/paymentLog/template/PaymentLog";
import Profile from "../components/profile/template/Profile";
import Portfolio from "../components/portfolio/template/Portfolio";
import NotFound from "../components/notFound/template/NotFound";
import TermsAndCondition from "../components/terms-and-condition/template/TermsAndCondition";
//import guard from "../guard";

let routes = [
  { path: "/signup", name: "signUp", component: Signup },
  { path: "/signin", name: "signIn", component: Signin },
  { path: "/active-account", name: "ActiveAccount", component: ActiveAccount },
  { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword },
  { path: "/reset-password/:token", name: "ResetPassword", component: ResetPassword },
  { path: "/terms-and-conditions", name: "TermsAndCondition", component: TermsAndCondition },
  { path: "/", redirect: '/dashboard', beforeEnter: guard  },
  { path: "/dashboard", name: "Home", component: Home,beforeEnter: guard  },
  { path: "/more", name: "More", component: More, beforeEnter: guard  },
  { path: "/add-service", name: "AddService", component: AddService , beforeEnter: guard },
  { path: "/update-service/:id", name: "UpdateService", component: UpdateService, beforeEnter: guard  },
  { path: "/service-list", name: "ServiceList", component: ServiceList, beforeEnter: guard  },
  // { path: "/service-category", name: "ServiceCategory", component: ServiceCategory, beforeEnter: guard  },
  { path: "/service-color", name: "ServiceColor", component: ServiceColor,beforeEnter: guard  },
  { path: "/analytics", name: "Analytics", component: Analytics, beforeEnter: guard  },
  { path: "/report", name: "Report", component: Report, beforeEnter: guard  },
  { path: "/report-details/:name", name: "ReportDetails", component: ReportDetails,beforeEnter: guard  },
  { path: "/checkout", name: "Checkout", component: Checkout, beforeEnter: guard  },
  { path: "/checkout-list", name: "CheckoutList", component: CheckoutList, beforeEnter: guard  },
  { path: "/appointment", name: "Appointment", component: Appointment, beforeEnter: guard  },
  { path: "/appointment-list", name: "AppointmentList", component: AppointmentList, beforeEnter: guard  },
  { path: "/checkout-details/:id", name: "CheckoutDetails", component: CheckoutDetails, beforeEnter: guard  },
  { path: "/clients", name: "Client", component: Client, beforeEnter: guard  },
  { path: "/admin", name: "Admin", component: Admin, beforeEnter: guard  },
  { path: "/clients-details", name: "ClientDetails", component: ClientDetails, beforeEnter: guard  },
  { path: "/expenses", name: "Expenses", component: Expenses, beforeEnter: guard  },
  { path: "/expense-category", name: "ExpenseCategory", component: ExpenseCategory, beforeEnter: guard  },
  { path: "/products", name: "Products", component: Products, beforeEnter: guard  },
  { path: "/add-products", name: "AddProducts", component: AddProducts, beforeEnter: guard  },
  { path: "/update-product/:id", name: "EditProduct", component: EditProduct, beforeEnter: guard  },
  { path: "/product-details/:id", name: "ProductDetails", component: ProductDetails, beforeEnter: guard  },
  { path: "/product-brand", name: "ProductBrand", component: ProductBrand, beforeEnter: guard  },
  { path: "/product-category", name: "ProductCategory", component: ProductCategory, beforeEnter: guard  },
  { path: "/calendar", name: "Calendar", component: Calender, beforeEnter: guard  },
  { path: "/payment-info", name: "PaymentInformation", component: PaymentInformation, beforeEnter: guard  },
  // { path: "/platform-payment", name: "PlatformPayment", component: PlatformPayment, beforeEnter: guard  },
  { path: "/payment-log", name: "PaymentLog", component: PaymentLog, beforeEnter: guard  },
  { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard  },
  { path: "/portfolio", name: "Portfolio", component: Portfolio, beforeEnter: guard  },
  { path: "/404-not-found", name: "NotFound", component: NotFound },
  { path: '/:pathMatch(.*)*', redirect: { name: 'NotFound' } },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router;
