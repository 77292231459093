import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "AddProducts",
    components: {
        Loading
    },
    data() {
        return{
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            productCategory: "",
            productBrand: "",
            selectedCategory: {},
            selectedBrand: {},
            productImage: null,
            previewImage: null,
            name: "",
            clientPrice: "",
            information: "",
            inventoryCost: "",
            inventoryQuantity: "",
            lowQuantity: ""
        }
    },
    async created() {
        document.title = "Cosmo Salon - Add Product";
        await this.getProductCategory();
        await this.getProductBrand();
    },
    methods: {
        checkValidation: function() {
            if(!this.productImage) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload product image!"
                })
                return false;
            }
            if(!this.selectedCategory.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select category!"
                })
                return false;
            }
            if(!this.selectedBrand.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select brand!"
                })
                return false;
            }
            if(!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter product name!"
                })
                return false;
            }
            if(!this.clientPrice) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter client price!"
                })
                return false;
            }
            if(!this.information) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter product information!"
                })
                return false;
            }
            if(!this.inventoryCost) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter inventory cost!"
                })
                return false;
            }
            if(!this.inventoryQuantity) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter inventory quantity!"
                })
                return false;
            }
            if(!this.lowQuantity) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter low quantity!"
                })
                return false;
            }
            return true;
        },
        getProductCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productCategory = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getProductBrand: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.brand.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productBrand = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onImageChange(event) {
            this.productImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        addProduct: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("name", this.name);
                formData.append("brandId", this.selectedBrand.id);
                formData.append("brandName", this.selectedBrand.name);
                formData.append("categoryId", this.selectedCategory.id);
                formData.append("categoryName", this.selectedCategory.name);
                formData.append("image", this.productImage);
                formData.append("information", this.information);
                formData.append("clientPrice", this.clientPrice);
                formData.append("inventoryCost", this.inventoryCost);
                formData.append("inventoryQuantity", this.inventoryQuantity);
                formData.append("lowQuantity", this.lowQuantity);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', this.$swal.stopTimer)
                              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.$refs.image.value = null;
                    this.isLoading = false;
                    this.$router.push('/products');
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}
