import Loading from "vue-loading-overlay";
import AddCategory from "../template/AddCategory";
import { authHeader } from "../../../../auth";
export default {
    name: "AddService",
    components: {
        Loading,
        AddCategory
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            serviceName: "",
            totalDuration: "",
            price: "",
            serviceDetails: "",
            isStartingPrice: false,
            isPricehidden: false,
            color: "",
            category: "",
            isShownWebsite: false,
            image: '',
            categroyList: [],
            colorList: [],
            previewFeaturedImage: "",
            subCategoryList: [],
            subCategory: {}
        }
    },
    async mounted() {
        await this.getCategoryList();
        await this.getColorList();
    },
    methods: {
        getCategoryList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.data.map(el => {
                        return { value: { id: el.id, name: el.name }, label: el.name }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        getSubCategories: async function (option) {
            this.subCategoryList = [];
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.subCategory.requestURL + '/' + option.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    response.data.data.map(el => {
                        return this.subCategoryList.push({ value: el, label: el.subCategoryName });
                    })
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        getColorList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.color.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.colorList = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkValidation: function () {
            if (!this.serviceName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter service name"
                })
                return false;
            }
            if (!this.totalDuration) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter total duration"
                })
                return false;
            }
            if (!this.price) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter price"
                })
                return false;
            }
            if (!this.serviceDetails) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter service details"
                })
                return false;
            }
            if (!this.color.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a color"
                })
                return false;
            }
            if (!this.category.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a category"
                })
                return false;
            }
            if (Object.values(this.subCategory).length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a sub category"
                })
                return false;
            }
            // if (!this.image) {
            //     this.$swal.fire({
            //         icon: "info",
            //         text: "Please upload an image"
            //     })
            //     return false;
            // }
            return true;
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.serviceImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addService: async function () {
            if (this.checkValidation()) {
                console.log(this.subCategory.subCategoryImage)
                let formData = new FormData();
                formData.append("name", this.serviceName);
                formData.append("colorId", this.color.code);
                formData.append("colorName", this.color.name);
                formData.append("categoryId", this.category.id);
                formData.append("categoryName", this.category.name);
                formData.append("image", this.image);
                formData.append("subCategoryImage", !this.image ? this.subCategory?.subCategoryImage : '');
                formData.append("price", this.price);
                formData.append("duration", this.totalDuration);
                formData.append("details", this.serviceDetails);
                formData.append("isStartingPrice", this.isStartingPrice);
                formData.append("isPriceHidden", this.isPricehidden);
                formData.append("isShownWebsite", this.isShownWebsite);
                formData.append("subCategoryId", this.subCategory.id);
                formData.append("subCategoryName", this.subCategory.subCategoryName);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.service.requestURL,
                    data: formData,
                    headers: authHeader()
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.name = "";
                    }
                    this.$router.push('/service-list');
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}
