<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <p class="mb-1">Hi {{name}},</p>
          <h3>Welcome to Cosmo Professional!</h3>
        </div>
        
      </div>
      <div class="row gy-4">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" @click="$router.push('/report-details/Sales?reportType=today')">
          <div class="dashboard_card shadow-sm">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="w-100">
                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Booked Today</p>
                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.todayTotalServiceValue}}</h4>
              </div>
              <div class="w-100 text-center">
                <GChart
                    type="ColumnChart"
                    :data="myData"
                    :options="options1"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" @click="$router.push('/report-details/Sales?reportType=today')">
          <div class="dashboard_card shadow-sm">
            <div class="d-flex justify-content-between align-items-center w-100 gap-2">
              <div class="">
                <img src="../../../assets/images/eye.svg">
              </div>
              <div class="w-100">
                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Product Ordered Today</p>
                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.todayTotalProductValue}}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" @click="$router.push('/report-details/Appointment?reportType=all')">
          <div class="dashboard_card shadow-sm">
            <div class="d-flex gap-2 align-items-center">
              <div class="">
                <img src="../../../assets/images/berchart.svg">
              </div>
              <div class="w-100 align-items-center">
                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Booked Total</p>
                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.totalServiceValue}}</h4>
              </div>
              <div class="text-end">
                <GChart
                    type="LineChart"
                    :options="options2"
                    :data="collectionData"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" @click="$router.push('/report-details/Sales?reportType=all')">
          <div class="dashboard_card shadow-sm">
            <div class="d-flex gap-2 align-items-center">
              <div class="">
                <img src="../../../assets/images/berchart.svg">
              </div>
              <div class="w-100 align-items-center">
                <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">All Products Ordered</p>
                <h4 class="mb-0 text-site-darkRed fs-23 fw-700 ff-poppins400">${{dashboardData.totalProductValue}}</h4>
              </div>
              <div class="text-end">
                <GChart
                    type="LineChart"
                    :options="options2"
                    :data="collectionData"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" @click="$router.push('/checkout-list')">
          <div class="dashboard_card shadow-sm bg-site-maroon">
            <div class="d-flex gap-5 align-items-center">
              <div class="w-100 align-items-center">
                <p class="mb-1 text-white fs-14 ff-poppins400 fw-500">All Orders</p>
                <h4 class="mb-0 text-white fs-23 fw-700 ff-poppins400">${{dashboardData.totalInvoice}}</h4>
              </div>
              <div class="w-100 text-center">
                <GChart
                    type="LineChart"
                    :options="options3"
                    :data="collectionData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row gy-4 mt-4">
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <div class="dashboard_chart_card shadow-sm d-plex justify-content-center">
            <GChart type="PieChart" :options="optionsServices" :data="topServices" />
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <div class="dashboard_chart_card shadow-sm">
            <GChart type="PieChart" :options="optionsProducts" :data="topProducts" />
          </div>
        </div>
        
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
          <QRCodeVue3 :value="value" :width="300" :height="300" :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :backgroundOptions="{ color: '#ffffff' }" :dotsOptions="{
            type: 'dots',
            color: '#610000',
            // gradient: {
            //   type: 'linear',
            //   rotation: 0,
            //   colorStops: [
            //     { offset: 0, color: '#26249a' },
            //     { offset: 1, color: '#26249a' },
            //   ],
            // },
          }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png"
          myclass="my-qur"
          imgclass="img-qr" />
          <div class="mt-2 " style="text-align:center;" >
            <button type="button" class="
                  btn
                  text-uppercase
                  bg-site-darkRed
                  text-white
                  border-site-darkRed
                  rounded_5
                  py-3
                  px-5
                  me-4
                " @click="downloadQr">
              Download
            </button>
          </div>
        </div>
      </div>


    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
  </div>
</template>

<script src="../js/home.js"></script>

<style scoped>
@import "../css/home.css";
</style>


