import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import $ from 'jquery';
export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            orderList: [],
            orderStatus: "",
            paymentStatus:'',
            paymentAmount:'',
            page: 1,
            limit: 10,
            totalPage: 0,
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false,
            tabStatus: 'product'
        }
    },
    async mounted() {
        document.title = "Cosmo Salon - Order";
        let params = '?page=' + this.page + '&limit=' + this.limit + '&tabStatus=' + this.tabStatus;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.order.orderURL + params,
            headers: authHeader()
        };
        await this.getOrderList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit + '&tabStatus=' + this.tabStatus;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.orderURL + params,
                headers: authHeader()
            };
            await this.getOrderList(config);
        },
        async getOrderList(config) {
            this.orderList = [];
            try {
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;
                        this.totalPage = await response.data.data.pageCount;
                    }
                   
                }).catch(error => {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                   
                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el.firstName + el.lastName, label: el.firstName + el.lastName }
                    });
                }
                else {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async updateStatus(param) {

            try {
                let data = {
                    status: param.status,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.order.statusUpdate + param.id,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.updateHandler();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Order updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                   
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text:  error.response.data.messsage
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async updatePayementStatus(param) {

            try {
                let data = {
                    paymentStatus: param.paymentStatus,
                    paymentAmount:param.paymentAmount
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.payment.updatePayementStatus+ param.id,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.updateHandler();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Order updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                   
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text:  error.response.data.messsage
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async approval(status, id) {
            let buttonText = status == 'Approved' ? 'approve' : 'deny';
            let data = {
                isApproved: status
            };
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, ' + buttonText + ' it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.order.approveURL + id,
                            data: data,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.status == 200) {
                                this.updateHandler();
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                           
                        }).catch(error => {
                           
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        async deleteOrder(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.order.orderURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.deleted == true) {
                                this.updateHandler();
                                this.$swal.fire({
                                    icon: "warning",
                                    text: "Order deleted Successfully."
                                });
                            }
                           
                        }).catch(error => {
                           
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    }

}