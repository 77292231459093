import Loading from "vue-loading-overlay";
import * as XLSX from 'xlsx';
import { authHeader } from "../../../auth";
export default {
    name: "ReportDetails",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            headers: [],
            isFilter: false,
            startDate: '',
            endDate: '',
            productList: [],

            filter: {
                type: "",
                startDate: "",
                endDate: "",
            },
            reportName: "",
            sheets: [],
            reportList: [],
            dataList: [],
            serviceList: [],
            productId: '',
            serviceId: '',
            itemId:''
        }
    },
    async mounted() {
        this.reportName = this.$route.params.name;
        if (this.$route.query.reportType) {
            this.filter.type = this.$route.query.reportType;
        }
        if (this.$route.query.startDate) {
            this.filter.startDate = this.$route.query.startDate;
        }
        if (this.$route.query.endDate) {
            this.filter.endDate = this.$route.query.endDate;
        }
        await this.getReportList();
        if(this.reportName == 'Sales') {
            await this.getProductList();
        }
        if(this.reportName == 'Appointment') {
            await this.getServicesList();
        }
    },
    methods: {
        getServicesList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.data.map((el) => {
                        return { value: el, label: el.name };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getProductList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productList = response.data.data.map((el) => {
                        return { value: el, label: el.name };
                    });
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getReportList: async function () {
            this.dataList = [];
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.report.requestURL + "?reportName=" + this.reportName + "&type=" + this.filter.type + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + '&itemId=' + this.itemId,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.reportList = response.data;
                    if (response.data.length > 0) {
                        this.headers = Object.keys(this.reportList[0]);
                        for (let key in Object.values(this.reportList)) {
                            let data = Object.values(this.reportList[key]);
                            this.dataList.push(data);
                        }
                    }

                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        cancelFilter() {
            this.startDate = '';
            this.endDate = '';
            this.isFilter = false
        },
        filterDate: async function () {
            this.filter.startDate = this.startDate;
            this.filter.endDate = this.endDate;
            if(this.productId){
                this.itemId = this.productId.id
            }
            if(this.serviceId) {
                this.itemId = this.serviceId.id
            }
            await this.getReportList();
        },
        exportData: function () {
            this.sheets = this.reportList;
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, this.reportName + '(' + date + ').xlsx');
            this.sheets = [];
        }
    }
}
