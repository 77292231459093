<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="change_password" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content radius-15 shadow-primary border-0">
          <div class="modal-header modal-header-primary">
            <h4 class="m-0 text-white">Change Password</h4>
            <button type="button" class="btn-close text-site-dark" id="closeBtn" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row g-3">
              <div class="col-12">
                <label class="form-label">Old Password</label>
                <input type="password" v-model="oldPassword" class="form-control" placeholder="Input your old password"
                  required />
              </div>
              <div class="col-12">
                <label class="form-label">New Password</label>
                <input type="password" v-model="newPassword" class="form-control" placeholder="Input your new password"
                  required />
              </div>
              <div class="col-12">
                <label class="form-label">Retype New Password</label>
                <input type="password" v-model="conPassword" class="form-control" placeholder="Retype new passwor"
                  required />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" @click="updatePassword()" class="btn btn_primary pull-left" data-bs-dismiss="modal">
              <i class="bx bx-save"></i> Save Password
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- Modal -->
  </div>
</template>

<script>
import {
  authHeader
} from "../../../auth";
export default {
  name: "Profile",
  components: {},
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      conPassword: "",
      isEdit: false,
    };
  },
  mounted() { },
  methods: {
    updatePassword: async function () {
      if (this.oldPassword == "") {

        this.$swal.fire({
          icon: "error",
          text: "Please enter your current password!",
        });
      } else if (this.newPassword == "") {

        this.$swal.fire({
          icon: "error",
          text: "Please enter your new password!",
        });
      }
      else if (this.conPassword == "") {

        this.$swal.fire({
          icon: "error",
          text: "Please enter your confirm password!",
        });
      } else if (this.newPassword != this.conPassword) {

        this.$swal.fire({
          icon: "error",
          text: "Password & Confirm Password does not match!",
        });
      } else {
        if (this.newPassword.length < 6) {

          this.$swal.fire({
            icon: "error",
            text: "You have to enter at least 6 digit!",
          });
        } else {
          let data = {
            currentPassword: this.oldPassword,
            newPassword: this.newPassword,
          };
          let config = {
            method: "POST",
            url: this.$serverURL + this.$api.auth.changePassword,
            data: data,
            headers: authHeader(),
          };
          await this.$axios(config)
            .then((response) => {
              if (response.status == 200) {
                this.$swal.fire({
                  toast: true,
                  position: 'top-end',
                  text: response.data.message,
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                  animation: false,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                  }
                });
                document.getElementById("closeBtn").click();
                this.$router.push("/");
              } else {

                this.$swal.fire({
                  icon: "error",
                  text: "Failed to change your password!",
                });
              }
            })
            .catch((error) => {
              let msg = 'Failed to update!'
              if (error.response.data.statusCode == 404) {
                msg = 'Old password does not match'
              }
              this.$swal.fire({
                icon: "error",
                text: msg,
              });
            });

          this.isEdit = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.radius-15 {
  border-radius: 17px 17px 15px 15px !important;
}

.shadow-primary {
  box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%) !important;
}

.modal-header-primary {
  color: #fff;
  background-color: #610000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.btn_primary {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
}

.btn_primary:hover {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
}

.btn_primary:focus {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
  box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%);
}
</style>