import { store } from "@/store";
export default {
    components: {},
    name: "Header",
    data() {
        return {
            store,
        }
    },
    created() {

    },
    methods: {
        openSideDrawer: function () {
            this.store.active = true;
        },
    },

};
