<template>
  <div></div>
  <!--header section start-->
  <!--section class="header fixed-top py-3">
    <div class="custom-container-fluid">
      <div class="d-flex gap-3 align-items-center">
      <div class="text-start w-100">
        <a class="navbar-brand text-site-dark fw-bolder fs-2"
        :class="[store.active ? 'text-site-ghostWhite' : '']">
          COSMO SALON
        </a>
      </div>
      <div class="text-center w-100">
        <a class="text-site-dark" :class="[store.active ? 'text-site-ghostWhite' : '']" href="" target="_blank">Get the app</a>
      </div>
      <div class="text-end w-100">
        <a @click="store.active =! store.active" class="btn menu shadow-none p-0 border-0" :class="[store.active ? 'nav-active' : '']">
          <span class="menu_icon" :class="[store.active ? 'bg-site-ghostWhite' : '']"></span>
          <span class="menu_icon"></span>
          <span class="menu_icon" :class="[store.active ? 'bg-site-ghostWhite' : '']"></span>
        </a>
      </div>
    </div>
    </div>
  </section-->
  <!--header section end-->
</template>

<script src="../js/header.js"></script>

<style scoped>
@import "../css/header.css";
</style>
