import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";

export default {
    name: "EditProduct",
    components: {
        Loading
    },
    data() {
        return{
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            productCategory: "",
            productBrand: "",
            id: this.$route.params.id,
            productDetails: {},
            selectedCategory: {},
            selectedBrand: {},
            previewImage: null,
            productImage: null
        }
    },
    async created() {
        document.title = "Cosmo Salon - Edit Product";
        await this.getProductDetails();
        await this.getProductCategory();
        await this.getProductBrand();
    },
    methods: {
        getProductDetails: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.requestURL + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.productDetails = response.data.data[0];
                    this.selectedCategory = {
                        id: this.productDetails.categoryId,
                        name: this.productDetails.categoryName 
                    };
                    this.selectedBrand = {
                        id: this.productDetails.brandId,
                        name: this.productDetails.brandName
                    };
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        onImageChange(event) {
            this.productImage = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        checkValidation: function() {
            if(!this.selectedCategory.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select category!"
                })
                return false;
            }
            if(!this.selectedBrand.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select brand!"
                })
                return false;
            }
            if(!this.productDetails.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter product name!"
                })
                return false;
            }
            if(!this.productDetails.clientPrice) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter client price!"
                })
                return false;
            }
            if(!this.productDetails.information) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter product information!"
                })
                return false;
            }
            if(!this.productDetails.inventoryCost) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter inventory cost!"
                })
                return false;
            }
            if(!this.productDetails.inventoryQuantity) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter inventory quantity!"
                })
                return false;
            }
            if(!this.productDetails.lowQuantity) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter low quantity!"
                })
                return false;
            }
            return true;
        },
        getProductCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productCategory = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getProductBrand: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.brand.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.productBrand = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        updateProduct: async function() {
            if(this.checkValidation()){
                let formData = new FormData();
                formData.append("name", this.productDetails.name);
                formData.append("brandId", this.selectedBrand.id);
                formData.append("brandName", this.selectedBrand.name);
                formData.append("categoryId", this.selectedCategory.id);
                formData.append("categoryName", this.selectedCategory.name);
                formData.append("image", this.productImage? this.productImage : this.productDetails.image);
                formData.append("information", this.productDetails.information);
                formData.append("clientPrice", this.productDetails.clientPrice);
                formData.append("inventoryCost", this.productDetails.inventoryCost);
                formData.append("inventoryQuantity", this.productDetails.inventoryQuantity);
                formData.append("lowQuantity", this.productDetails.lowQuantity);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.product.requestURL + "/" + this.id,
                    data: formData,
                    headers: authHeader()
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if(response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', this.$swal.stopTimer)
                              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push('/products');
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}
