<template>
    <div class="custom-container-fluid">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="m-auto">
                <h3 class="fw-600 logo_section_title">Portfolio</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9 m-auto">
                <div class="card overflow-hidden radius-10">
                    <div class="profile-cover bg-dark position-relative">
                        <div class="d-flex gap-2 mt-5">
                            <div class="w-50 ms-3">
                                <label>Image: [Preferred Image Size: 500X700, Max Upload Limit: 1MB]</label>
                                <input class="form-control" ref="image" @change="onImageChange($event)" type="file" id="formFile">
                                <img class="mt-3" v-if="previewImage" :src="previewImage" height="100" width="150"/>
                                <img class="mt-3" v-else src="../../../assets/images/400.png" height="100" width="150"/>
                            </div>
                            <div>
                                <button type="button" @click="save()" class="btn add_service_btn mt-4">Upload Image</button>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="card-body mt-3">
                        <div class="row gy-3">
                            <div v-for="(data, index) in dataList" :key="index" class="col-12 col-lg-4">
                                <div class="card image_card">
                                    <div class="card-body" style="vertical-align: middle; !important">
                                        <i class="fa-solid fa-circle-xmark close-button d-flex justify-content-end mb-2 text-danger" @click="removeImage(data.id)"></i>
                                        <img :src="$imageURL + 'portfolio/' + data.image" class="card-img-top">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script src="../js/portfolio.js"></script>

<style scoped>
.radius-10 {
    border-radius: 10px !important;
}
.image_card{
    height: 100% !important;
}

.remove-btn {
    position: absolute;
    right: 0;
    bottom: -35px;
    font-size: 22px !important;
    padding: 0 !important;
    border: none !important;
}

.schedule-time-section {
    height: 268px;
    overflow-x: hidden;
    overflow-y: auto;
}

.schedule-time-section-checkbox {
    border: none !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-check:checked+.schedule-time-section-checkbox {
    color: #ffffff !important;
    background-color: var(--site-maroon) !important;
    border-color: var(--site-maroon) !important;
}

.profile-cover {
    /* background-image: url(../../../assets/images/dark-red.png); */
    
    background-color: white !important;
    background-size: cover;
    height: 15rem;
    background-position: center;
}

.user-profile-avatar {
    background-color: #ffffff;
    width: 160px;
    height: 160px;
    padding: 5px;
    border-radius: 50%;
    margin-left: 6.5rem;
}

.user-profile-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.btn_primary {
    color: #fff;
    background-color: #610000;
    border-color: #610000;
}

.btn_primary:hover {
    color: #fff;
    background-color: #610000;
    border-color: #610000;
}

.btn_primary:focus {
    color: #fff;
    background-color: #610000;
    border-color: #610000;
    box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%);
}

.btn_outline_primary {
    color: #923eb9;
    border-color: #610000;
}

.btn_outline_primary:hover {
    color: #fff;
    background-color: #610000;
    border-color: #610000;
}

.btn_outline_primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(84 55 145 / 50%);
}

.user-change-photo {
    background-color: #ffffff;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    padding: 5px;
}

.user-change-photo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>
