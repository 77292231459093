import Loading from "vue-loading-overlay";
export default {
    name: "More",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
        }
    },
    methods: {
        goRoute() {
            this.$router.push('/add-service')
        }
    }
}
