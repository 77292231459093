<template>
    <div>
        <div class="container">
            <div class="border rounded p-4 mt-3">
                <div class="mt-2">
                    <h2 class="text-center fw-bold fs-1">{{ data.title }}</h2>
                </div>
                <div class="mt-3">
                    <p class="" v-html="data.description"></p>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script src="../js/terms-and-condition.js"></script>
    