<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid">
      <div class="card p-3 shadow border-0 round-17" v-if="reportList.length > 0">
        <div class="table-container">
          <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-3">
            <!-- <div class="w-100 w-xs-100">
          <div class="search_box">
            <i class="bi bi-search search_icon"></i>
            <input type="search" class="form-control rounded-pill" placeholder="Search" id="table_search">
          </div>
        </div> -->
            <div class="w-100">
              <div class="text-end">
                <button class="btn bg-site-darkRed text-white border-site-darkRed px-4 text-uppercase me-2"
                  @click="isFilter =! isFilter " v-if="this.filter.type == 'all'">Filter</button>
                <!-- <button class="btn bg-site-darkRed text-white border-site-darkRed px-4 text-uppercase me-2">Send</button> -->

                <div class="btn-group">
                  <button type="button"
                    class="btn bg-site-darkRed border-site-darkRed text-white dropdown-toggle btn_focus text-uppercase"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Export
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end w-100 p-2 shadow-sm border-0 text-center">
                    <!-- <li><button class="dropdown-item" type="button">PDF</button></li> -->
                    <li><button class="dropdown-item" type="button" @click="exportData">Excel</button></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <div v-if="isFilter" class="card p-3 mt-3">
            <div class="row g-4" v-if="reportName==='Sales'">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Start Date</label>
                <input type="date" v-model="startDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">End Date</label>
                <input type="date" v-model="endDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label for="inputProductTitle" class="form-label">Select Product</label>
                <Multiselect v-model="productId" id="inputVendor" :options="productList" :searchable="true" />
              </div>
              <div class="col-12 col-sm-6 col-md-3 align-self-end text-end">
                <button class="btn bg-site-darkRed border-site-darkRed text-white me-3"
                  @click="filterDate">Filtering</button>
                <button @click="cancelFilter" class="btn bg-site-darkRed border-site-darkRed text-white">Cancel</button>
              </div>
            </div>
            <div class="row g-4" v-else-if="reportName==='Appointment'">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Start Date</label>
                <input type="date" v-model="startDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">End Date</label>
                <input type="date" v-model="endDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label for="inputProductTitle" class="form-label">Select Service</label>
                <Multiselect v-model="serviceId" id="inputVendor" :options="serviceList" :searchable="true" />
              </div>
              <div class="col-12 col-sm-6 col-md-3 align-self-end text-end">
                <button class="btn bg-site-darkRed border-site-darkRed text-white me-3"
                  @click="filterDate">Filtering</button>
                <button @click="cancelFilter" class="btn bg-site-darkRed border-site-darkRed text-white">Cancel</button>
              </div>
            </div>
            <div class="row g-4" v-else>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Start Date</label>
                <input type="date" v-model="startDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">End Date</label>
                <input type="date" v-model="endDate" class="form-control">
              </div>
              <div class="col-12 col-sm-6 col-md-4 align-self-end text-end">
                <button class="btn bg-site-darkRed border-site-darkRed text-white me-3"
                  @click="filterDate">Filtering</button>
                <button @click="cancelFilter" class="btn bg-site-darkRed border-site-darkRed text-white">Cancel</button>
              </div>
            </div>
            
          </div>
          <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">{{reportName}} Report
                        </h5>
                    </div>
          <div class="table-section">
            <div class="table-responsive">
              <table class="custom_table custom_table_header_white">
                <thead>
                  <tr>
                    <th class="sorting">Sl No.</th>
                    <th class="sorting" v-for="(data,index) in headers" :key=index>{{data}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataList" :key="index">
                    <td>
                      {{index + 1}}
                    </td>
                    <td v-for="(row, key) in data" :key="key">{{row}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>


        </div>
      </div>
      <div class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;" v-else>
        <p style="text-align:center;">No Data Found !</p>
      </div>
    </div>
  </div>
</template>

<script src="../js/report_details.js">
</script>
<style scoped>
  @import "../css/report_details.css";
</style>