<template>
<div>
<div class="custom-container-fluid">
  <div class="card mb-3">
    <div class="row g-0">
      <div class="col-md-4">
        <img :src="$imageURL + 'product/' + data.image" class="w-100" alt="...">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h6 class="card-title">{{ data.categoryName }}</h6>
          <h2 class="card-title">{{ data.name }}</h2>
          <h4>$ {{ data.clientPrice }} <span class="">(In stock)</span></h4>
          <p class="card-text">
            {{ data.information }}
          </p>
        <span class="bg-site-gray rounded-pill p-2">{{ data.brandName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script src="../js/product-details.js"></script>
