<template>
  <div class="custom-container-fluid">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="m-auto">
        <h3 class="fw-600 logo_section_title">Profile</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-lg-9 m-auto">
        <div class="card overflow-hidden radius-10">
          <div class="profile-cover bg-dark position-relative mb-4">
            <div
              class="user-profile-avatar shadow position-absolute top-50 start-0 translate-middle-x mb-2"
            >
              <img v-if="previewImage" :src="previewImage" alt="..." />
              <img
                v-else-if="userDetails.image"
                :src="$imageURL + 'profile-image/' + userDetails.image"
                alt="..."
              />
              <img
                v-else
                src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg"
                alt="..."
              />
            </div>
          </div>
          <div class="card-body mt-3">
            <div
              class="mt-5 d-flex flex-column flex-sm-row align-items-start justify-content-between gap-3 gap-sm-0"
            >
              <div class="mt-2">
                <label
                  for="image"
                  style="cursor:pointer"
                  class="text-uppercase bg-site-darkRed text-white border-site-darkRed rounded_5 py-2 px-4"
                >
                  Upload Photo
                  <i class="fa-solid fa-plus"></i>
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  ref="addImage"
                  style="display: none;"
                  accept="image/*"
                  @change="uploadFeaturedImage"
                />
                <h3 class="mb-2 mt-2">{{userDetails.fullName}}</h3>
                <p class="mb-1">
                  <strong>Email:</strong>
                  {{ email }}
                </p>
              </div>
              <div class>
                <a
                  href="javascript:;"
                  class="btn btn_primary"
                  data-bs-toggle="modal"
                  data-bs-target="#change_password"
                >
                  <i class="bx bxs-key"></i>
                  Change Password
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="card radius-10 mt-3">
          <div class="card-body">
            <form>
              <h5 class="mb-3">Edit Profile</h5>
              <h5 class="mb-0 mt-4">User Information</h5>
              <hr />
              <div class="row g-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.fullName"
                    placeholder="N/A"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">Business Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.businessName"
                    placeholder="N/A"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="userDetails.email"
                    disabled
                    placeholder="N/A"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.phoneNo"
                    placeholder="N/A"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">Full Address</label>
                  <!-- <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.streetAddress"
                      placeholder="N/A"
                  />-->
                  <GMapAutocomplete
                    @place_changed="setPlace"
                    class="form-control form-control-lg fs-18"
                    :value="userDetails.streetAddress"
                  ></GMapAutocomplete>
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">City</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.city"
                    placeholder="N/A"
                  />
                </div>

                <div class="col-12 col-sm-6">
                  <label class="form-label">Postal Code</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.zipCode"
                    placeholder="N/A"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label">State</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userDetails.state"
                    placeholder="N/A"
                  />
                </div>
              </div>
              <!--Work Information-->
              <div class="my-3">
                <h3 class="ff-poppins500 fs-20">Work Information</h3>
                <div class="row gy-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label text-uppercase mt-2">Services</label>
                    <!-- <Multiselect
                          v-model="services"
                          mode="tags"
                          :close-on-select="false"
                          :searchable="true"
                          :create-option="false"
                          :options="serviceList"
                    />-->
                    <v-select
                      :options="serviceList"
                      multiple
                      v-model="services"
                      @option:selected="loadSubServices()"
                      @option:deselected="deselectSubServices"
                    ></v-select>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label text-uppercase mt-2">Sub Services:</label>
                    <v-select
                      style="background: #fff;"
                      multiple
                      :options="subServiceList"
                      v-model="subServices"
                    ></v-select>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label text-uppercase mt-2">Studio</label>
                    <!-- <Multiselect
                          v-model="studio"
                          :close-on-select="true"
                          :searchable="true"
                          :create-option="false"
                          :options="studioList"
                    />-->
                    <v-select :options="studioList" v-model="studio"></v-select>
                  </div>
                </div>
                <div class="mt-3">
                  <label class="form-label text-uppercase mt-2">schedule</label>
                  <div
                    v-for="(data, index) in schedule"
                    :key="index"
                    class="d-flex flex-column flex-md-row gap-3 mb-5 position-relative"
                  >
                    <div class="w_50 w-sm-100">
                      <DatePicker
                        v-model="selectedValue[index].date"
                        @update:modelValue="assignDate(index, selectedValue[index].date)"
                        :minDate="new Date()"
                        is-expanded
                      />
                    </div>
                    <div class="w_50 w-sm-100">
                      <div class="schedule-time-section">
                        <div class="row gy-3">
                          <div
                            v-for="(time, row) in data.times"
                            :key="row"
                            class="col-4 col-lg-6 col-xl-4"
                          >
                            <input
                              type="checkbox"
                              v-model="selectedValue[index].times[row]"
                              :value="time"
                              class="btn-check"
                              @change="selectTime(selectedValue[index].date,time, index, row, selectedValue[index].times[row])"
                              :id="'check'+index+'box'+row"
                            />
                            <label
                              class="btn shadow schedule-time-section-checkbox"
                              :for="'check'+index+'box'+row"
                            >{{time}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      class="btn remove-btn ff-poppins700 text-site-maroon cp"
                      @click="removeSchedule(index)"
                      v-if="(getIndex !== index && index != schedule.length-1)"
                    >
                      <i class="bi bi-x-square-fill"></i>
                    </a>
                  </div>
                  <button
                    type="button"
                    class="btn bg-site-maroon text-white float-end text-capitalize mb-3"
                    @click="addSchedule"
                  >add schedule</button>
                </div>
                <div class="mt-3">
                  <label class="form-label text-uppercase">About Me</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="aboutMe"
                    placeholder="Write about you"
                  ></textarea>
                </div>
              </div>
              <div class="text-start mt-3">
                <button @click="save" type="button" class="btn btn_primary px-4">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ChangePassword />
</template>
    
<script src="../js/profile.js">
</script>
<style scoped>
.radius-10 {
  border-radius: 10px !important;
}
.remove-btn {
  position: absolute;
  right: 0;
  bottom: -35px;
  font-size: 22px !important;
  padding: 0 !important;
  border: none !important;
}
.schedule-time-section {
  height: 268px;
  overflow-x: hidden;
  overflow-y: auto;
}
.schedule-time-section-checkbox {
  border: none !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-check:checked + .schedule-time-section-checkbox {
  color: #ffffff !important;
  background-color: var(--site-maroon) !important;
  border-color: var(--site-maroon) !important;
}
.profile-cover {
  background-image: url(../../../assets/images/dark-red.png);
  background-size: cover;
  height: 10rem;
  background-position: center;
}
.user-profile-avatar {
  background-color: #ffffff;
  width: 160px;
  height: 160px;
  padding: 5px;
  border-radius: 50%;
  margin-left: 6.5rem;
}
.user-profile-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.btn_primary {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
}
.btn_primary:hover {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
}
.btn_primary:focus {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
  box-shadow: 0 0 0 0.25rem rgb(132 54 168 / 50%);
}
.btn_outline_primary {
  color: #923eb9;
  border-color: #610000;
}
.btn_outline_primary:hover {
  color: #fff;
  background-color: #610000;
  border-color: #610000;
}
.btn_outline_primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(84 55 145 / 50%);
}
.user-change-photo {
  background-color: #ffffff;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  padding: 5px;
}
.user-change-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>
