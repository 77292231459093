import { authHeader } from "./auth";
export default {
    methods: {
        checkAdminVerified: async function () {
            let isAdminVerified = localStorage.getItem('isAdminVerified');
            if (isAdminVerified == 'false') {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.auth.getProfile,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data.data.data.isAdminVerified == true) {
                            localStorage.setItem("isAdminVerified", response.data.data.data.isAdminVerified);
                        }
                    }
                }).catch(error => {
                    console.log(error);
                });

            }
        }
    }
}