import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import Toaster from '@meforma/vue-toaster';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import VueGoogleCharts from "vue3-googl-chart";
import vue3StarRatings from "vue3-star-ratings";
import 'v-calendar/dist/style.css';
import VCalendar, { Calendar, DatePicker, SetupCalendar } from 'v-calendar';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import mixin from './mixin';
const app = createApp(App);
app.component('Multiselect', Multiselect);
app.component("v-select", vSelect);
app.component('Datepicker', Datepicker);
app.mixin(mixin);
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$active = false;
app.config.globalProperties.$tokenKey = 'token';
app.config.globalProperties.$path = '';
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$filters = {
    formatDateTime(date) {
        return moment(String(date)).format('MM/DD/YYYY hh:mm:ss A');
    },
    formatDate(date) {
        return moment(String(date)).format('MM/DD/YYYY');
    },
    formatDateBookedTime(date) {
        return moment(String(date)).format('M/DD/YYYY');
    },
    workingDate(date) {
        return moment(String(date)).format('MMM D, YYYY');
    },
    eventDate(date) {
        return moment(String(date)).format('YYYY-MM-DD');
    }
};
const server = require('../config/server.json');


if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$hostURL = server.staging.HOST;
    app.config.globalProperties.$serverURL = server.staging.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.staging.IMAGE_PATH;
    app.config.globalProperties.$clientURL = server.CLIENT.CLIENT_LOGIN_URL;
    app.config.globalProperties.$adminText = server.adminVerificationText.text;
}
else {
    app.config.globalProperties.$hostURL = server.server.HOST;
    app.config.globalProperties.$serverURL = server.server.SERVER_HOST;
    app.config.globalProperties.$imageURL = server.server.IMAGE_PATH;
    app.config.globalProperties.$clientURL = server.CLIENT.CLIENT_LOGIN_URL;
    app.config.globalProperties.$adminText = server.adminVerificationText.text;
}




app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(Toaster, {
    position: 'top',
});
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCQuFaWDHVt8s9KwasbHJl7epPt_MEygvE',
        // language: 'de',
        libraries: "places"
    },
});
app.use(VCalendar, { SetupCalendar, Calendar, DatePicker })
app.component("vue3-star-ratings", vue3StarRatings);
app.use(VueGoogleCharts);
app.use(router).mount('#app');
