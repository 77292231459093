<template>
    <div class="body_section ms-0 px-3 overflow-y-auto d-flex align-items-center justify-content-center">
        <div class="activeAccount_page">
            <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
            <div class="activeAccount_page_form_section">
                <div class="card activeAccount_form_card">
                    <div class="activeAccount_form_card_title">
                        <h3>Active Account</h3>
                    </div>
                    <form v-on:submit.prevent="activeAccount">
                        <div class="mb-4">
                            <label for="InputUsername" class="form-label activeAccount_form_card_label">Email
                                Address</label>
                            <div class="position-relative">
                                <input type="email" v-model="email" class="form-control custom_input bg-light"
                                    id="InputUsername" placeholder="" disabled>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="InputPassword" class="form-label login_form_card_label">New Password</label>
                            <div class="position-relative">
                                <input id="InputPassword" :type="[showPassword ? 'text' : 'password']"
                                    class="form-control custom_input" v-model="password"
                                    placeholder="input your password in here" required>
                                <span class="icon" @click="toggleShow">
                                    <i class="fas"
                                        :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                </span>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="InputRepeatPassword" class="form-label login_form_card_label">Retype New
                                Password</label>
                            <div class="position-relative">
                                <input id="InputRepeatPassword" :type="[showRepeatPassword ? 'text' : 'password']"
                                    class="form-control custom_input" v-model="repeatPassword"
                                    placeholder="input your password in here" required>
                                <span class="icon" @click="toggleShowRepeat">
                                    <i class="fas"
                                        :class="{ 'fa-eye': showRepeatPassword, 'fa-eye-slash': !showRepeatPassword }"></i>
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="submit" class="activeAccount_form_card_activeAccount_btn primary_btn"
                                value="Active Account" @click="$router.push('/signin')" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="../js/ActiveAccount.js"></script>

<style scoped>
@import "../css/activeAccount.css";
</style>