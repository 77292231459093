import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";
import * as XLSX from 'xlsx';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "Expenses",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            expenseCategory: "",
            selectedCategory: {},
            expenseFile: null,
            previewFile: null,
            expenseDate: null,
            expenseAmount: null,
            vendor: null,
            expenseList: [],
            showFile: {},
            expenseDetails: null,
            sheets: [],
            page: 1,
            limit: 10,
            totalPage: 0,
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
        }
    },
    async created() {
        document.title = "Cosmo Salon - Expense";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.expense.requestURL + params,
            headers: authHeader()
        };
        await this.getExpenseCategory();
        await this.getExpenseList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.expense.requestURL + params,
                headers: authHeader()
            };
            await this.getExpenseList(config);
        },
        getExpenseList: async function (config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.expenseList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        checkValidation: function() {
            if(!this.selectedCategory.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select expense category!"
                })
                return false;
            }
            if(!this.expenseDate) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select expense date!"
                })
                return false;
            }
            if(!this.expenseDetails) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter expense details!"
                })
                return false;
            }
            if(!this.expenseAmount) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter expense amount!"
                })
                return false;
            }
            if(!this.expenseFile) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload expense image!"
                })
                return false;
            }
            return true;
        },
        getExpenseCategory: async function() {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.expense.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.expenseCategory = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        onFileChange(event) {
            this.expenseFile = event.target.files[0];
            let input = this.$refs.file;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewFile = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        imageShow(file) {
            this.showFile = file;
        },
        downloadFile: function(url) {
            let new_url = this.$imageURL + 'expense/' + url;
            let new_label = url;
            this.$axios.get(new_url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = new_label
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        addExpense: async function() {
            if(this.checkValidation() == true) {
                this.isLoading = true;
                let token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append("expenseDate", this.expenseDate);
                formData.append("amount", this.expenseAmount);
                formData.append("vendor", this.vendor);
                formData.append("expenseDetails", this.expenseDetails);
                formData.append("categoryId", this.selectedCategory.id);
                formData.append("categoryName", this.selectedCategory.name);
                formData.append("file", this.expenseFile);
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.expense.requestURL,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token,
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', this.$swal.stopTimer)
                              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.$refs.file.value = null;
                    this.previewFile = "";
                    this.expenseAmount = "";
                    this.vendor = "";
                    this.vendor = "";
                    this.expenseDetails = "";
                    this.selectedCategory = {};
                    this.expenseDate = "";
                    this.isLoading = false;
                    this.updateHandler();
                    document.getElementById("buttonClose").click();
                    document.getElementById("pills-past-tab").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        exportData: function () {
            this.expenseList.map((el) => {
                this.sheets.push({
                    "Category Name": el.categoryName,
                    "Date of Expense": el.expenseDate,
                    "Expense Amount": el.amount,
                    "Vendor Name": el.vendor == "null" ? "N/A" : el.vendor,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'expense_report(' + date + ').xlsx')
            this.sheets = [];
        },
        deleteExpense: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.expense.requestURL + '/' + id,
                            headers: authHeader()
                        };
                        this.$axios(config).then((response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }
                            this.isLoading = false;
                            this.updateHandler();
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        }
    }
}
