<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Clients</h3>
        </div>
        <div>
          <button class="btn add_service_btn" data-bs-target="#addClientModal" data-bs-toggle="modal">add
            Client</button>
        </div>
      </div>
      <div style="margin-top: 5%;">
        <div class="card p-3 shadow border-0 round-17">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Client List
            </h5>
          </div>
          <div class="table-container">
            <div class="table-section">
              <div class="table-responsive">
                <table class="custom_table custom_table_header_white">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th style="min-width: 100px">Client Name</th>
                      <th style="min-width: 100px">Email</th>
                      <th style="min-width: 100px">Phone no</th>
                      <th style="min-width: 100px">Created At</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody v-if="clientList.length > 0">
                    <tr v-for="(data, index) in clientList" :key="index">
                      <td class="fw-900" data-label="Sl :">{{index + 1}}</td>
                      <td data-label="Client Name :">
                        {{data.clientName}}
                      </td>
                      <td data-label="Email :">
                        {{data.email}}
                      </td>
                      <td data-label="Phone no :">
                        {{data.phoneNo}}
                      </td>
                      <td data-label="Created At :">{{$filters.formatDate(data.createdAt)}}</td>
                      <td data-label="Options :">
                        <!-- <button data-bs-target="#updateClientModal" @click="setId(index)" data-bs-toggle="modal" title="edit"
                          class="btn btn-sm btn-success action_btn">
                          <i class="fas fa-edit"></i>
                        </button> -->
                        <button title="delete" @click="deleteClient(data.id)" class="btn btn-sm btn-danger action_btn">
                          <i class="fas fa-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6">
                        <p class="mb-0 text-center">No Data Found !</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>


          </div>
          <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
            <v-pagination v-if="clientList.length > 0" v-model="page" @update:modelValue="updateHandle"
              :pages="totalPage" :range-size="1" active-color="#FF8400"></v-pagination>
          </nav>
        </div>

      </div>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
    <div class="modal fade" id="addClientModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addClientModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addClientModalLabel">Add a Client
              <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum  dolor sit amet </span> -->
            </h5>
            <button type="button" id="closeAdd" class="btn-close text-site-dark" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Name</label>
                <input v-model="name" type="text" class="form-control" placeholder="Enter name">
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Phone Number</label>
                <input v-model="phoneNo" type="text" class="form-control" placeholder="Enter phone no">
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label text-site-dark ff-poppins400 fs-17">Email Address</label>
              <input type="email" v-model="email" class="form-control" placeholder="Enter email address">
            </div>
          </div>
          <div class="text-center p-3">
            <button type="button"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 w-100 py-3"
              @click="addClient">Create client</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="updateClientModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addClientModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addClientModalLabel">Update Client
            </h5>
            <button type="button" id="closeUpdate" class="btn-close text-site-dark" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Name</label>
                <input v-model="singleClient.fullName" type="text" class="form-control" placeholder="Enter name">
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Phone Number</label>
                <input v-model="singleClient.phoneNo" type="text" class="form-control" placeholder="Enter phone no">
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label text-site-dark ff-poppins400 fs-17">Email Address</label>
              <input type="email" v-model="singleClient.email" class="form-control" placeholder="Enter email address">
            </div>
          </div>
          <div class="text-center p-3">
            <button type="button"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 w-100 py-3"
              @click="updateClient">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/client.js">
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0;
    vertical-align: middle!important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.custom_table.custom_table_header_grayish tbody tr {
    border-bottom: 3px solid #b3b0b0 !important;
    background-color: #f7f6f6!important;
  }
  table.custom_table.custom_table_header_white tbody tr td {
    text-align: right!important;
    background-color: #f7f6f6!important;
  }
  table tr {
    border-bottom: 3px solid #ddd!important;
    display: block;
    margin-bottom: .725em;
    background-color: #b3b0b0!important;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right!important;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
  @import "../css/add_client.css";
</style>
