<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <div>
          <h3 class="fw-600 mb-0">Products</h3>
        </div>
        <div>
          <button @click="$router.push('/add-products')" class="btn add_service_btn" data-bs-target="#addProducts"
            data-bs-toggle="modal">Add New Products</button>
        </div>
      </div>
      <div class="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4" v-if="productList.length > 0">
        <div class="col" v-for="(row, index) in productList" :key="index">
          <div class="card round-10 p-2 h-100 justify-content-center align-items-center">
            <div>
              <img :src="$imageURL + 'product/' + row.image" class="card-img-top" alt="..." height="200"
                @click="$router.push('/product-details/' + row.id)">
              <div class="card-body px-0 pb-0">
                <h5 class="card-title fs-15 ff-poppins700 text-site-dark mb-0">{{ row.name }}</h5>
                <p class="card-text fs-13 ff-poppins500 text-site-maroon mb-0">{{ row.categoryName }}</p>
                <p class="card-text fs-12 ff-poppins400 text-site-dark">
                  {{ row.information }}
                </p>
                <a class="btn bg-site-darkRed border-site-darkRed text-white px-5 ff-poppins700">$
                  {{ row.clientPrice }}</a>
                <div class="float-end">
                  <button @click="$router.push('/update-product/' + row.id)"
                    class="btn btn-info text-white ms-1">Edit</button>
                  <button @click="deleteProduct(row.id)" class="btn btn-danger ms-1">Delete</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;" v-else>
        <p style="text-align:center;">No Data Found !</p>
      </div>
      <nav
            class="page-navigation justify-content-center d-flex"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="productList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
    </div>
  </div>
</template>

<script src="../js/products.js">
</script>

<style scoped>
  @import "../css/products.css";
  @import url("../../../assets/css/pagination.css");
</style>