import { store } from "../../../store";
import { vMaska } from "maska"
export default {
    components: {},
    name: "Signup",
    directives: { maska: vMaska },
    data() {
        return {
            store,
            name: "",
            businessName: "",
            email: "",
            phone: "",
            password: "",
            address: "",
            stateCode: "",
            postCode: "",
            city: "",
            stepOne: true,
            stepTwo: false,
            stepThree: false,
            services: [],
            subServices: [],
            serviceList: [],
            subServiceList: [],
            image: "",
            previewImage: "",
            studio: "",
            studioList: [],
            termsAndCondition: '',
            binded: {
                masked: "",
                unmasked: "",
                completed: false
            }
        };
    },
    async created() {
        await this.getServiceList();
        await this.getStudioList();
    },
    methods: {
        loadSubServices: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.subCategory.requestURL + '/' + this.services[this.services.length - 1].value.id,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    response.data.data.map(el => {
                        return this.subServiceList.push({ value: el, label: el.subCategoryName });
                    })
                }

            }).catch(error => {
                console.log(error);
            });

        },
        deselectSubServices: function (option) {
            let newSubServiceList = [];
            let newSubServices = [];
            for (let i = 0; i < this.subServiceList.length; i++) {
                if (this.subServiceList[i].value.categoryName == option.label) {
                    continue;
                } else {
                    newSubServiceList.push(this.subServiceList[i]);
                }
            }
            this.subServiceList = newSubServiceList;

            for (let i = 0; i < this.subServices.length; i++) {
                if (this.subServices[i].value.categoryName == option.label) {
                    continue;
                } else {
                    newSubServices.push(this.subServices[i]);
                }
            }
            this.subServices = newSubServices;

        },
        validatePhoneNumber: function (input_str) {
            var re = /^\d+$/;
            return re.test(input_str);
        },
        checkFirstPage: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter name!"
                });
                return false;
            }
            if (!this.businessName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter business name!"
                });
                return false;
            }
            if (!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter email!"
                });
                return false;
            }
            if (!this.phone) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter a phone number!"
                });
                return false;
            }
            // if (!this.validatePhoneNumber(this.phone)) {
            //   this.$swal.fire({
            //     icon: "info",
            //     text: "Please enter a valid phone number!"
            //   });
            //   return false;
            // }
            if (!this.password) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter password!"
                });
                return false;
            }
            if (!this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter confirm password!"
                });
                return false;
            }
            if (this.password.length < 6) {
                this.$swal.fire({
                    icon: "info",
                    text: "Password length must be greater than or equal 6 characters!"
                });
                return false;
            }
            if (this.password != this.confirmPassword) {
                this.$swal.fire({
                    icon: "info",
                    text: "Password didn't match!"
                });
                return false;
            }
            if (!this.termsAndCondition) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please accept terms & conditions!"
                });
                return false;
            }
            return true;
        },
        setPlace(locationData) {
            let location = locationData.formatted_address;
            console.log(location);
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.address = locationData.name;

            this.city = "";
            this.postCode = "";
            this.stateCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.postCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.stateCode = addressComponent[i].long_name;
                }
            }
        },
        checkThirdPage: function () {
            if (!this.image) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please upload your profile image"
                });
                return false;
            }
            if (this.services.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select at least one service"
                });
                return false;
            }
            if (!this.studio) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a studio"
                });
                return false;
            }
            return true;
        },
        goSecondPage: async function () {
            if (this.checkFirstPage()) {
                this.stepOne = false;
                this.stepTwo = true;
            }
        },
        goThirdPage: async function () {
            this.stepTwo = false;
            this.stepThree = true;

        },
        goBackFirstPage: async function () {
            this.stepOne = true;
            this.stepTwo = false;
        },
        goBacSecondPage: async function () {
            this.stepTwo = true;
            this.stepThree = false;
        },
        getServiceList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.categoryList,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.map(el => {
                        return {
                            value: el, label: el.name
                        }
                    })
                }

            }).catch(error => {
                console.log(error);
            });
        },
        getStudioList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.studio.requestURL,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.studioList = response.data.studio.map(el => {
                        return {
                            value: el, label: el.studioName
                        }
                    })
                }

            }).catch(error => {
                console.log(error);
            });
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        signUp: async function () {
            if (this.checkThirdPage()) {
                let formData = new FormData();
                let service = [];
                let subServices = [];
                let subCat = [];
                let hasElement = false
                this.services.map(el => {
                    hasElement = false;
                    for (let i = 0; i < this.subServices.length; i++) {
                        if (this.subServices[i].value.categoryName != el.value.name) {
                            continue;
                        } else if (this.subServices[i].value.categoryName == el.value.name) {
                            subCat.push({ catId: this.subServices[i].value.categoryId, catName: this.subServices[i].value.categoryName, subId: this.subServices[i].value.id, subName: this.subServices[i].value.subCategoryName });
                            hasElement = true;
                        }
                    }
                    if (hasElement == false) {
                        subCat.push({ catId: el.value.id, catName: el.value.name, subId: "", subName: "" });
                    }
                });
                subCat.map(item => {
                    service.push({
                        id: item.catId,
                        name: item.catName,
                        subId: item.subId ? item.subId : "",
                        subName: item.subName ? item.subName : "",
                    })
                })
                // this.subServices.map(el => {
                //     subServices.push({
                //         catId: el.value.categoryId,
                //         catName: el.value.categoryName,
                //         id: el.value.id,
                //         name: el.value.subCategoryName
                //     })
                // });
                let studio = {
                    id: this.studio.id,
                    name: this.studio.studioName
                }
                formData.append('fullName', this.name);
                formData.append('businessName', this.businessName);
                formData.append('phoneNo', this.phone);
                formData.append('email', this.email);
                formData.append('password', this.password);
                formData.append('streetAddress', this.address);
                formData.append('city', this.city);
                formData.append('stateCode', this.stateCode);
                formData.append('zipCode', this.postCode);
                formData.append('image', this.image);
                service.map(el => {
                    formData.append('service', JSON.stringify(el))
                });
                subServices.map((el) => {
                    formData.append('subService', JSON.stringify(el))
                })
                formData.append('studio', JSON.stringify(studio));
                formData.append('userType', 'professional');
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.auth.professionalReg,
                    data: formData,
                };
                this.$axios(config)
                    .then((response) => {
                        if (response.status == 201) {
                            this.$swal.fire({
                                toast: true,
                                position: "top-end",
                                text: "Succesfully registered",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                                },
                            });
                            localStorage.setItem("token", response.data.data.token);
                            localStorage.setItem("id", response.data.data.user.id);
                            localStorage.setItem("name", response.data.data.user.fullName);
                            localStorage.setItem("email", response.data.data.user.email);
                            localStorage.setItem("role", response.data.data.user.role);
                            localStorage.setItem("isAdminVerified", response.data.data.user.isAdminVerified);
                            this.$router.push("/profile");
                            // this.store.token = response.data.data.token;
                            // localStorage.setItem("token", response.data.data.token);
                            // localStorage.setItem("id", response.data.data.user.id);
                            // localStorage.setItem("firstName", response.data.data.user.firstName);
                            // localStorage.setItem("lastName", response.data.data.user.lastName);
                            // localStorage.setItem("email", response.data.data.user.email);
                            // localStorage.setItem("role", response.data.data.user.role);
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                text: "Please try again!",
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message,
                        });
                    });
            }
        },
        async googleSignIn() {
            try {
                const googleUser = await this.$gAuth.signIn();
                if (googleUser) {
                    this.isLogin = this.$gAuth.isAuthorized;
                    const idToken = googleUser.getId();

                    await this.processGoogleLogin(idToken, googleUser);
                }
            } catch (error) {
                console.error(error);
            }
        },
        processGoogleLogin: async function (idToken, googleUser) {
            let loggedData = googleUser.getBasicProfile();
            let email = loggedData.getEmail();
            let fullName = loggedData.getName();
            let data = {
                token: idToken,
                email: email,
                name: fullName,
            };
            let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.auth.googleLogIn,
            };

            await this.$axios(config)
                .then((response) => {
                    if (response.status == 201) {
                        this.store.token = response.data.token;
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("id", response.data.user.id);
                        localStorage.setItem("name", fullName);
                        localStorage.setItem("firstname", response.data.user.firstName);
                        localStorage.setItem("lastname", response.data.user.lastName);
                        localStorage.setItem("phone", response.data.user.phone);
                        this.$swal.fire({
                            icon: "success",
                            text: "Successfuly logged in!!",
                        });
                        window.location.replace("/user/dashboard");
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Google Login Failed!",
                        });
                    }
                })
                .catch((error) => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message,
                    });
                });
        },
    },
};
