<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid">
      <div class="add_service_card py-3 px-2 px-sm-3 px-md-4">
        <form @submit.prevent="addService()">
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Service Name</label>
              <input v-model="serviceName" class="form-control text-site-gray-dark rounded-0"
                placeholder="Enter service name" />
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Total duration</label>
              <select class="form-select" v-model="totalDuration" aria-label="Default select example">
                <option style="display:none" value selected>Select Duration</option>
                <option value="15">15 min</option>
                <option value="30">30 min</option>
                <option value="45">45 min</option>
                <option value="60">60 min</option>
                <option value="75">75 min</option>
                <option value="90">90 min</option>
                <option value="105">105 min</option>
                <option value="120">120 min</option>
              </select>
              <!-- <input
                v-model="totalDuration"
                class="form-control text-site-gray-dark rounded-0"
                placeholder="Enter total duration"
              />-->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Price</label>
              <input v-model="price" class="form-control text-site-gray-dark rounded-0" placeholder="Enter price" />
            </div>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Category</label>
              <Multiselect v-model="category" id="inputVendor" :options="categoryList" autocomplete="off"
                :searchable="true" @select="getSubCategories" />
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Sub Category</label>
              <Multiselect v-model="subCategory" id="inputVendor" :options="subCategoryList" autocomplete="off"
                :searchable="true" />
            </div>
          </div>
          <div class="mb-4">
            <label class="form-label text-site-dark">Service Details</label>
            <textarea v-model="serviceDetails" class="form-control text-site-gray-dark" rows="4"
              placeholder="Enter service details"></textarea>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6">
              <div class="card p-3 justify-content-center min-height-150">
                <div class="d-flex justify-content-between gap-4 align-items-center">
                  <div>
                    <h4 class="fs-18 ff-poppins400 mb-2 text-site-dark">Starting at Price</h4>
                    <p class="text-site-gray-dark m-0">Your online booking site will indicate this is a staring price
                    </p>
                  </div>
                  <div>
                    <div class="form-check form-switch">
                      <input v-model="isStartingPrice" class="form-check-input shadow-none" type="checkbox"
                        role="switch" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="card p-3 min-height-150 justify-content-center">
                <div class="d-flex justify-content-between gap-4 align-items-center">
                  <div>
                    <h4 class="fs-18 ff-poppins400 mb-2 text-site-dark">Price Hidden</h4>
                    <p class="text-site-gray-dark m-0">
                      Your online booking site will not show the dollar price of this
                      service. It will just show “Price Varies”.
                    </p>
                  </div>
                  <div>
                    <div class="form-check form-switch">
                      <input v-model="isPricehidden"
                        class="add_service_card_form-check-input form-check-input shadow-none" type="checkbox"
                        role="switch" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-6">
              <label class="form-label text-site-dark">
                Colors
                <span class="text-site-dark d-block fs-12">Choose a color display in calender</span>
              </label>
              <div class="card p-3 justify-content-center">
                <div class>
                  <div class="d-flex gap-2 flex-wrap">
                    <input class="form-check-input add_service_card_checkbox"
                      :style="'background-color:' + data.code + '!important;border-color: ' + data.code + '!important;'"
                      v-model="color" type="radio" :id="index" :value="{ name: data.name, code: data.code }"
                      v-for="(data, index) in colorList" :key="index" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-6">
              <label class="form-label text-site-dark">
                Website
                <span class="text-site-dark d-block fs-12">&nbsp;</span>
              </label>
              <div class="card p-3 justify-content-center min-height-150">
                <h4 class="ff-poppins400 fs-18 text-site-dark">Show on Website</h4>
                <p class="text-site-gray-dark">
                  Your online booking site will
                  indicate this will show you in website
                </p>
                <div class="form-check form-switch">
                  <input v-model="isShownWebsite" class="add_service_card_form-check-input form-check-input shadow-none"
                    type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                </div>
              </div>
            </div>
          </div>
          <div class="mb-1">
            <label class="form-label text-site-dark">
              Image
              <!-- <span class="text-site-dark d-block fs-12">&nbsp;</span> -->
            </label>
            <p class="text-site-gray-dark">
              [Add Photo or Default Image will be set]
            </p>
          </div>
          <div class="mb-2">
            <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="113" width="200" />
            <img v-else src="../../../../assets/images/no-image.jpg" height="113" width="200" />
          </div>
          <div>
            <label :style="{'margin':'20px 0px'}" for="file-upload" class="me-2 btn addAttachment_btn">Add Photo</label>
          </div>
          <div class="text-center">
            <!-- <label for="file-upload" class="me-2 btn addAttachment_btn">Add Photo</label> -->
            <input id="file-upload" type="file" style="display: none" ref="serviceImage" accept="image/*"
              @change="uploadFeaturedImage" />
            <button type="submit" class="ms-2 btn submit_btn">Submit</button>
          </div>
        </form>
      </div>
    </div>
    <AddCategory />
  </div>
</template>

<script src="../js/add_service.js">
</script>

<style scoped>
@import "../css/add_service.css";
</style>
