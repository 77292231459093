<template>
  <div
    class="body_section ms-0 px-3 overflow-y-auto d-flex align-items-center justify-content-center"
  >
    <div class="signup_page py-2">
      <div class="signup_page_form_section">
        <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
        <div class="text-center mb-4">
          <h5 class="ff-poppins600 text-black fs-23 mb-0">LET'S GET STARTED.</h5>
        </div>

        <section v-if="stepOne">
          <div class="text-center">
            <h3>Basic Information</h3>
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">Name</label>
            <input
              type="text"
              v-model="name"
              class="form-control form-control-lg fs-18"
              placeholder="Enter your full name"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">Business Name</label>
            <input
              type="text"
              v-model="businessName"
              class="form-control form-control-lg fs-18"
              placeholder="Enter your business name"
              required
            />
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6">
              <label class="form-label text-uppercase">Email</label>
              <input
                type="email"
                v-model="email"
                class="form-control form-control-lg fs-18"
                placeholder="Enter your email address"
                required
              />
            </div>
            <div class="col-12 col-sm-6">
              <label class="form-label text-uppercase">Phone</label>
              <!-- <input type="text" v-model="phone" class="form-control form-control-lg fs-18"
              placeholder="Enter your contact no." required />-->
              <input
                class="form-control form-control-lg fs-18"
                v-model="phone"
                v-maska
                data-maska="+1 (###) ###-####"
                placeholder="Enter your contact no."
                required
              />
            </div>
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">Password</label>
            <input
              type="password"
              v-model="password"
              class="form-control form-control-lg fs-18"
              placeholder="Enter your password"
              required
            />
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">Confirm Password</label>
            <input
              type="password"
              v-model="confirmPassword"
              class="form-control form-control-lg fs-18"
              placeholder="Confirm Password"
              required
            />
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase"
              @click="goSecondPage"
            >Next</button>
          </div>
          <div class="d-flex justify-content-center">
            <div class="d-flex gap-2">
              <input type="checkbox" v-model="termsAndCondition" />
              <p class="text-site-darkRed ff-poppins700 text-uppercase mt-3">
                Accept
                <a
                  class="btn border-0 p-0 text-site-darkRed text-decoration-underline"
                  href="/terms-and-conditions"
                  target="_blank"
                >Terms and conditions</a>
              </p>
            </div>
          </div>
          <div class="text-center">
            <p class="text-site-darkRed ff-poppins700 fs-13 text-uppercase mt-3">
              <a
                class="btn border-0 p-0 text-site-darkRed text-decoration-underline"
                @click="$router.push('/signin')"
              >Already have account</a>
            </p>
          </div>
        </section>
        <section v-if="stepTwo">
          <div class="text-center">
            <h3>Address Information</h3>
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">Street Address</label>
            <!-- <input type="text" v-model="address" class="form-control form-control-lg fs-18"
            placeholder="Enter your street address" required />-->
            <GMapAutocomplete @place_changed="setPlace" :value="address" class="form-control form-control-lg fs-18"></GMapAutocomplete>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6">
              <label class="form-label text-uppercase">State</label>
              <input
                type="text"
                v-model="stateCode"
                class="form-control form-control-lg fs-18"
                placeholder="Enter your state"
                required
              />
            </div>
            <div class="col-12 col-sm-6">
              <label class="form-label text-uppercase">Zip Code</label>
              <input
                type="text"
                v-model="postCode"
                class="form-control form-control-lg fs-18"
                placeholder="Enter your zip code"
                required
              />
            </div>
          </div>
          <div class="mb-4">
            <label class="form-label text-uppercase">City</label>
            <input
              type="text"
              v-model="city"
              class="form-control form-control-lg fs-18"
              placeholder="Enter your city"
              required
            />
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase mx-2"
              @click="goThirdPage"
            >Skip</button>
            <button
              type="button"
              class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase"
              @click="goThirdPage"
            >Next</button>
          </div>
        </section>
        <section v-if="stepThree">
          <div class="text-center">
            <h3>Work Information</h3>
          </div>
          <div>
            <label for="photo-upload" class="custom-file-upload fas mx-auto">
              <div class="img-wrap img-upload">
                <img v-if="previewImage" :src="previewImage" class="w-100 h-100" for="photo-upload" />
                <img
                  v-else
                  class="w-100 h-100"
                  for="photo-upload"
                  src="../../../assets/images/defaultprofile.jpg"
                />
              </div>
            </label>
          </div>
          <div class="text-center">
            <label
              for="photo-upload"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded_5 py-2 px-4"
            >
              Upload Profile Image
              <i class="fa-solid fa-plus"></i>
            </label>

            <input
              type="file"
              name="image"
              id="photo-upload"
              ref="addImage"
              class="d-none"
              accept="image/*"
              @change="uploadFeaturedImage"
            />
          </div>
          <label class="form-label text-uppercase mt-2">Services</label>
          <v-select
            multiple
            style="background: #fff;"
            :options="serviceList"
            @option:selected="loadSubServices()"
            @option:deselected="deselectSubServices"
            v-model="services"
          ></v-select>
          <!-- <Multiselect v-model="services" mode="tags" :close-on-select="false" :searchable="true" @select="loadSubServices()" @deselect="deselectSubServices"
          :create-option="false" :options="serviceList" />-->
          <label class="form-label text-uppercase mt-2">Sub Services:</label>
          <v-select
            style="background: #fff;"
            multiple
            :options="subServiceList"
            v-model="subServices"
          ></v-select>
          <!-- <Multiselect v-model="subServices" mode="tags" :close-on-select="false" :searchable="true"
          :create-option="false" :options="subServiceList" />-->
          <label class="form-label text-uppercase mt-2">Studio</label>
          <Multiselect
            v-model="studio"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="studioList"
          />
          <div class="text-center mt-3">
            <button
              type="button"
              class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase mx-2"
              @click="goBacSecondPage"
            >Back</button>
            <button
              type="button"
              class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase"
              @click="signUp()"
            >Register</button>
          </div>
        </section>
        <!-- <div class="mb-4">
      <div class="form-check">
        <input class="form-check-input signup_page_checkbox" type="checkbox" value="" id="flexCheckDefault">
        <label class="form-check-label ps-3" for="flexCheckDefault">
          By clicking the Start button below, you are confirming that you have read and agree to our
          <a class="btn border-0 p-0 text-site-dark fs-17 ff-poppins400 text-uppercase" @click="$router.push('/')">Terms
            & Conditions and Privacy Policy</a>
        </label>
      </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script src="../js/signup.js"></script>

<style scoped>
@import "../../../assets/css/sign_up.scss";
.vs__selected {
  background-color: #610000 !important;
  color: white;
}
</style>
