import Loading from "vue-loading-overlay";
import AddCategory from "../template/AddCategory";
import { authHeader } from "../../../../auth";
export default {
    name: "AddService",
    components: {
        Loading,
        AddCategory
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            serviceName: "",
            totalDuration: "",
            price: "",
            serviceDetails: {},
            isStartingPrice: false,
            isPricehidden: false,
            color: "",
            category: "",
            isShownWebsite: false,
            image: '',
            categroyList: [],
            subCategoryList: [],
            subCategory: {},
            colorList: [],
            previewFeaturedImage: "",
            id: this.$route.params.id
        }
    },
    async mounted() {
        await this.getServiceDetails();
        await this.getCategoryList();
        await this.getColorList();
    },
    methods: {
        getServiceDetails: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.requestURL + '/' + this.id,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceDetails = response.data.data[0];
                    this.category = {
                        value: {
                            id: this.serviceDetails.categoryId,
                            name: this.serviceDetails.categoryName,
                        },
                        label: this.serviceDetails.categoryName
                    };
                    this.getSubCategories();

                    if (this.serviceDetails.subCategoryId != null && this.serviceDetails.subCategoryName != null) {
                        this.subCategory = {
                            value: {
                                id: this.serviceDetails.subCategoryId,
                                name: this.serviceDetails.subCategoryName,
                            },
                            label: this.serviceDetails.subCategoryName
                        }
                    } else {
                        this.subCategory = {};
                    }

                    this.color = {
                        code: this.serviceDetails.colorId,
                        name: this.serviceDetails.colorName,
                    };
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getCategoryList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.category.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data.data.map(el => {
                        return { value: { id: el.id, name: el.name }, label: el.name }
                    });
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getSubCategories: async function (option) {
            //console.log(option);
            this.subCategoryList = [];
            this.subCategory = {}
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.subCategory.requestURL + '/' + (option?.value?.id ? option.value.id : this.serviceDetails.categoryId),
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    response.data.data.map(el => {
                        return this.subCategoryList.push({
                            value: {
                                id: el.id,
                                name: el.subCategoryName,
                            }, label: el.subCategoryName
                        });
                    })
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        getColorList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.color.requestURL,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.colorList = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkValidation: function () {
            if (!this.serviceDetails.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter service name"
                })
                return false;
            }
            if (!this.serviceDetails.duration) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter total duration"
                })
                return false;
            }
            if (!this.serviceDetails.price) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter price"
                })
                return false;
            }
            if (!this.serviceDetails.details) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter service details"
                })
                return false;
            }
            if (!this.color.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a color"
                })
                return false;
            }
            if (!this.category.value.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a category"
                })
                return false;
            }
            return true;
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.serviceImage
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateService: async function () {
            if (this.checkValidation()) {
                let formData = new FormData();
                formData.append("name", this.serviceDetails.name);
                formData.append("colorId", this.color.code);
                formData.append("colorName", this.color.name);
                formData.append("categoryId", this.category.value.id);
                formData.append("categoryName", this.category.value.name);
                formData.append("image", this.image ? this.image : (this.serviceDetails.image && this.serviceDetails.image != 'null' ? this.serviceDetails.image : ''));
                formData.append("subCategoryImage", (!this.image && !this.serviceDetails.image) ? this.serviceDetails?.subCategoryImage : '');
                formData.append("duration", this.serviceDetails.duration);
                formData.append("price", this.serviceDetails.price);
                formData.append("details", this.serviceDetails.details);
                formData.append("isStartingPrice", this.serviceDetails.isStartingPrice);
                formData.append("isPriceHidden", this.serviceDetails.isPriceHidden);
                formData.append("isShownWebsite", this.serviceDetails.isShownWebsite);
                formData.append("subCategoryId", this.subCategory.value.id);
                formData.append("subCategoryName", this.subCategory.value.name);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.service.requestURL + "/" + this.id,
                    data: formData,
                    headers: authHeader()
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.name = "";
                    }
                    this.$router.push('/service-list');
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}
