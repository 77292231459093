import Loading from "vue-loading-overlay";
export default {
    name: "ResetPassword",
    components:{
        Loading
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            showPassword: false,
            showRepeatPassword: false,
            password: '',
            repeatPassword: '',
            resetFlag: false,
            passToken: this.$route.params.token,

        }
    },
   async mounted(){
        document.title = "Cosmo Salon - Reset Password";
        await this.verifyPassToken(this.passToken);
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShowRepeat() {
            this.showRepeatPassword = !this.showRepeatPassword;
        },
        resetPassword(){
            console.log(this.password)
        },
        async verifyPassToken(hashPass) {
            try {
              let data = {
                hashPass: hashPass,
              };
              let config = {
                method: "POST",
                data: data,
                url: this.$serverURL + this.$api.auth.verifyToken,
              };
              await this.$axios(config)
                .then((response) => {
                  if (response.status == 201 && response.data.data) {
                    this.resetFlag = true;
                  }
                  else
                  {
                      this.$swal.fire({
                          icon: "error",
                          text: "Invalid reset password link.",
                      });
                      this.$router.push('/account');
                  }
                })
                .catch((error) => {
                  this.$swal.fire({
                      icon: "error",
                      text: error.response.data.message
                  });
                  this.$router.push('/account');
                });
            } catch (error) {
              console.log(error);
              this.$swal.fire({
                icon: "error",
                text: "Something went wrong, please try agian later."
            });
            }
        },
        updatePassword: async function() {
            
            if (this.password == "") {
              this.$swal.fire({
                icon: "error",
                text: "Please enter your new password!",
              });
            } else if (this.repeatPassword == "") {
              
              this.$swal.fire({
                icon: "error",
                text: "Please enter your confirm new password!",
              });
            } else if (this.password != this.repeatPassword) {
                this.$swal.fire({
                    icon: "error",
                    text: "New Password & Confirm New Password does not match!"
                });
            } else {
              if (this.password.length < 6) {
                
                this.$swal.fire({
                  icon: "error",
                  text: "You have to enter at least 6 digit!",
                });
              } else {
                
                let data = {
                  passToken: this.passToken,
                  password: this.password,
                };
                try {
                  let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.auth.updatePasswordVerified,
                  };
                  this.$axios(config)
                    .then((response) => {
                      if (response.status == 201 && response.data.data) {
      
                        this.$swal.fire({
                            icon: "success",
                            text: "Your password updated successfully",
                        });
                        this.$router.push('/account');
                      }
                    })
                    .catch((error) => {
                      this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                      });
                    });
                } catch (error) {
                  this.$swal.fire({
                    icon: "error",
                    text: "Failed!! Please try again",
                  });
                }
              }
            }
        },
    }
}