<template>
    <div>
      <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
      <div class="custom-container-fluid">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h3 class="fw-600">Payment Log Information</h3>
          </div>
          <div>
        </div>
        </div>
        <div style="margin-top: 5%;">
          <div class="card p-3 shadow border-0 round-17">
            <div class="modal-header border-0 d-flex justify-content-between align-items-center">
              <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Payment Log
              </h5>
              <button class="btn btn-warning text-white" @click="exportData">EXPORT</button>
            </div>
            <div class="table-container">
              <div class="table-section">
                <div class="table-responsive">
                  <table class="custom_table custom_table_header_white">
                    <thead>
                      <tr>
                        <th >Sl</th>
                        <th >Invoice Id</th>
                        <th >Client Name</th>
                        <th >Payment Amount</th>
                        <th >Payment Date</th>
                      </tr>
                    </thead>
                    <tbody v-if="dataList.length > 0">
                      <tr v-for="(data, index) in dataList" :key="index">
                        <td class="fw-900" data-label="Sl :">{{index + 1}}</td>
                        <td data-label="Invoice Id :">
                          {{data.invoiceID}}
                        </td>
                        <td data-label="Client Name :">{{data.customerName}}</td>
                        <td data-label="Payment Amount :">{{data.paymentAmount}}</td>
                        <td data-label="Payment Date :">{{$filters.formatDate(data.paymentDate)}}</td>
                        
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="5">
                          <p class="mb-0 text-center">No Data Found !</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
  
              </div>
  
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src="../js/payment-log.js">
  </script>
  
  <style scoped>
  @media screen and (max-width: 600px) {
    table {
      border: 0;
      vertical-align: middle!important;
    }
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table.custom_table.custom_table_header_grayish tbody tr {
      border-bottom: 3px solid #b3b0b0 !important;
      background-color: #f7f6f6!important;
    }
    table.custom_table.custom_table_header_white tbody tr td {
      text-align: right!important;
      background-color: #f7f6f6!important;
    }
    table tr {
      border-bottom: 3px solid #ddd!important;
      display: block;
      margin-bottom: .725em;
      background-color: #b3b0b0!important;
    }
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right!important;
    }
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
    }
    table td:last-child {
      border-bottom: 0;
    }
  }

  </style>
