import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";

export default {
    name: "PaymentCard",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            name: "",
            cardList: [],
            singleCard: [],
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: ""
            },
            paidAmount: "",
            expireDate: ""
        }
    },
    async created() {
        document.title = "Cosmo Salon - Payment Card";
        await this.getCardList();
    },
    methods: {
        getCardList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.card.cardInfo,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.cardList = response.data.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        formatCCnum: function () {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        editCCnum: function () {

            if (this.singleCard.ccNum.length == 4) {

                this.singleCard.ccNum += " ";

            }

            if (this.singleCard.ccNum.length == 9) {

                this.singleCard.ccNum += " ";

            }

            if (this.singleCard.ccNum.length == 14) {

                this.singleCard.ccNum += " ";

            }

        },
        addCard: async function () {
            if (this.paymentValidation()) {
                this.isLoading = true;
                let data = {
                    name: this.paymentInfo.name,
                    ccNum: this.paymentInfo.cardNumber ? this.paymentInfo.cardNumber.split(' ').join('') : '',
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.card.cardInfo,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Card information added successfully",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.expireDate = "";
                        this.paymentInfo = {};
                    }
                    this.isLoading = false;
                    this.getCardList();
                    document.getElementById("closeAdd").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }

        },
        deleteCard: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    let config = {
                        method: "DELETE",
                        url: this.$serverURL + this.$api.card.cardDetails + id,
                        headers: authHeader()
                    };
                    this.$axios(config).then((response) => {
                        if (response.status == 200) {
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: "Card information deleted successfully",
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            });
                        }
                        this.isLoading = false;
                        this.getCardList();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    });
                }
            })
        },
        setId: function (index) {
            this.singleCard = this.cardList[index];
            this.expireDate = this.singleCard.ccExp ? this.singleCard.ccExp.slice(0, 2) + '/' + this.singleCard.ccExp.slice(2) : 'N/A';
            this.singleCard.ccNum = this.cardList[index].ccNum.slice(0, 4) + " " + this.cardList[index].ccNum.slice(4, 8) + " " + this.cardList[index].ccNum.slice(8, 12) + " " + this.cardList[index].ccNum.slice(12);
        },
        updateCard: function () {
            this.isLoading = true;
            let data = {
                name: this.singleCard.name,
                ccNum: this.singleCard.ccNum ? this.singleCard.ccNum.split(' ').join('') : '',
                ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                cvv: this.singleCard.securityCode
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.card.cardDetails + this.singleCard.id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: "Card information updated successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.expireDate = "",
                this.isLoading = false;
                this.getCardList();
                document.getElementById("closeUpdate").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        defaultCard: function (id) {
            this.isLoading = true;
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.card.cardDefault + id,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.isLoading = false;
                this.getCardList();
                document.getElementById("closeUpdate").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
    },
    watch: {
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        }
    }
}
