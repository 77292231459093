import ChangePassword from "../template/ChangePassword";
import { authHeader } from "../../../auth";
import { Calendar, DatePicker } from 'v-calendar';
export default {
    name: "Profile",
    components: {
        ChangePassword, Calendar, DatePicker
    },
    data() {
        return {
            userDetails: {},
            id: '',
            email: '',
            isEdit: false,
            country: "",
            image: "",
            previewImage: "",
            services: [],
            serviceList: [],
            studio: "",
            studioList: [],
            times: [],
            selectedValue: [{ date: new Date(), times: [] }],
            selectedData: [{ date: "", times: [] }],
            schedule: [
                {
                    date: new Date(),
                    times: [
                        "8:00 am-8:15 am", "8:15 am-8:30 am", "8:30 am-8:45 am", "8:45 am-9:00 am",
                        "9:00 am-9:15 am", "9:15 am-9:30 am", "9:30 am-9:45 am", "9:45 am-10:00 am",
                        "10:00 am-10:15 am", "10:15 am-10:30 am", "10:30 am-10:45 am", "10:45 am-11:00 am",
                        "11:00 am-11:15 am", "11:15 am-11:30 am", "11:30 am-11:45 am", "11:45 am-12:00 pm",
                        "12:00 pm-12:15 pm", "12:15 pm-12:30 pm", "12:30 pm-12:45 pm", "12:45 pm-1:00 pm",
                        "1:00 pm-01.15 pm", "1:15 pm-01.30 pm", "1:30 pm-01.45 pm", "1:45 pm-2:00 pm",
                        "2:00 pm-2:15 pm", "2:15 pm-2:30 pm", "2:30 pm-2:45 pm", "2:45 pm-3:00 pm",
                        "3:00 pm-3:15 pm", "3:15 pm-3:30 pm", "3:30 pm-3:45 pm", "3:45 pm-4:00 pm",
                        "4:00 pm-4:15 pm", "4:15 pm-4:30 pm", "4:30 pm-4:45 pm", "4:45 pm-5:00 pm",
                        "5:00 pm-5:15 pm", "5:15 pm-5:30 pm", "5:30 pm-5:45 pm", "5:45 pm-6:00 pm",
                        "6:00 pm-6:15 pm", "6:15 pm-6:30 pm", "6:30 pm-6:15 pm", "6:45 pm-7:00 pm",
                        "7:00 pm-7:15 pm", "7:15 pm-7:30 pm", "7:30 pm-7:45 pm", "7:45 pm-8:00 pm",
                        "8:00 pm-8:15 pm", "8:15 pm-8:30 pm", "8:30 pm-8:45 pm", "8:45 pm-9:00 pm",
                        "9:00 pm-9:15 pm", "9:15 pm-9:30 pm", "9:30 pm-9:45 pm", "9:45 pm-10:00 pm",
                        "10:00 pm-10:15 pm", "10:15 pm-10:30 pm", "10:30 pm-10:45 pm", "10:45 pm-11:00 pm",
                        "11:00 pm-11:15 pm", "11:15 pm-11:30 pm", "11:30 pm-11:45 pm", "11:45 pm-00:00 am",
                        "00:00 am-00:15 am", "00:15 am-00:30 am", "00:30 am-00:45 am", "00:45 am-1:00 am",
                        "1:00 am-1:15 am", "1:15 am-1:30 am", "1:30 am-1:45 am", "1:45 am-2:00 am",
                        "2:00 am-2:15 am", "2:15 am-2:30 am", "2:30 am-2:45 am", "2:45 am-3:00 am",
                        "3:00 am-3:15 am", "3:15 am-3:30 am", "3:30 am-3:45 am", "3:45 am-4:00 am",
                        "4:00 am-4:15 am", "4:15 am-4:30 am", "4:30 am-4:45 am", "4:45 am-5:00 am",
                        "5:00 am-5:15 am", "5:15 am-5:30 am", "5:30 am-5:45 am", "5:45 am-6:00 am",
                        "6:00 am-6:15 am", "6:15 am-6:30 am", "6:30 am-6:45 am", "6:45 am-7:00 am",
                        "7:00 am-7:15 am", "7:15 am-7:30 am", "7:30 am-7:45 am", "7:45 am-8:00 am"
                    ]
                },
            ],
            getIndex: 0,
            aboutMe: "",
            dataLoaded: false,
            subServiceList: [],
            subServices: [],
        }
    },
    async mounted() {
        document.title = "Cosmo Salon - Profile";
        this.id = localStorage.getItem("id");
        this.email = localStorage.getItem("email");
        await this.getUserInfo();
        await this.getServiceList();
        await this.getStudioList();
        this.dataLoaded = true;
    },
    methods: {
        loadSubServices: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.subCategory.requestURL + '/' + this.services[this.services.length - 1].value.id,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    response.data.data.map(el => {
                        return this.subServiceList.push({ value: { categoryId: el.categoryId, categoryName: el.categoryName, id: el.id, subCategoryName: el.subCategoryName }, label: el.subCategoryName });
                    })
                }

            }).catch(error => {
                console.log(error);
            });

        },
        deselectSubServices: function (option) {
            let newSubServiceList = [];
            let newSubServices = [];
            for (let i = 0; i < this.subServiceList.length; i++) {
                if (this.subServiceList[i].value.categoryName == option.label) {
                    continue;
                } else {
                    newSubServiceList.push(this.subServiceList[i]);
                }
            }
            this.subServiceList = newSubServiceList;

            for (let i = 0; i < this.subServices.length; i++) {
                if (this.subServices[i].value.categoryName == option.label) {
                    continue;
                } else {
                    newSubServices.push(this.subServices[i]);
                }
            }
            this.subServices = newSubServices;
        },
        selectTime: function (date, time, index, row, flag) {
            if (flag == true) {
                this.selectedData[index].date = date;
                this.selectedData[index].times.push(time);
            }
            else {
                let key = this.selectedData[index].times.indexOf(time);
                if (key !== -1) {
                    this.selectedData[index].times.splice(key, 1);
                }
                let valueKey = this.selectedValue[index].times.indexOf(time);
                if (valueKey !== -1) {
                    this.selectedValue[index].times[row] = false;
                }
            }
        },
        assignDate: function(index, date){
            if(date){
                console.log("in if")
                this.selectedData[index].date = date;
            }
        },
        getUserInfo: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.auth.getProfile,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.data.statusCode == 200) {
                    this.userDetails = await response.data.data.data;
                    this.aboutMe = await response.data.data.data.aboutMe;
                    if (response.data.data.service.length > 0) {
                        response.data.data.service.map(el => {
                            this.services.push({ value: el, label: el.name });
                            this.loadSubServices();
                        })
                    }
                    if (response.data.data.subService.length > 0) {
                        response.data.data.subService.map(el => {
                            this.subServices.push({ value: el, label: el.subCategoryName });
                        })
                    }
                    if (response.data.data.studio.length > 0) {
                        this.studio = {
                            value: { id: response.data.data.studio[0].id, name: response.data.data.studio[0].name }, label: response.data.data.studio[0].name
                        }
                    }
                    if (response.data.data.schedule.length > 0) {
                        this.selectedData = await response.data.data.schedule;
                        this.schedule = [];
                        this.selectedValue = [];
                        for (let i = 0; i < response.data.data.schedule.length; i++) {
                            let times = []
                            for (let i = 0; i < 48; i++) {
                                times[i] = false;
                            }
                            this.schedule.push(
                                {
                                    date: new Date(response.data.data.schedule[i].date),
                                    times: [
                                        "8:00 am-8:15 am", "8:15 am-8:30 am", "8:30 am-8:45 am", "8:45 am-9:00 am",
                                        "9:00 am-9:15 am", "9:15 am-9:30 am", "9:30 am-9:45 am", "9:45 am-10:00 am",
                                        "10:00 am-10:15 am", "10:15 am-10:30 am", "10:30 am-10:45 am", "10:45 am-11:00 am",
                                        "11:00 am-11:15 am", "11:15 am-11:30 am", "11:30 am-11:45 am", "11:45 am-12:00 pm",
                                        "12:00 pm-12:15 pm", "12:15 pm-12:30 pm", "12:30 pm-12:45 pm", "12:45 pm-1:00 pm",
                                        "1:00 pm-01.15 pm", "1:15 pm-01.30 pm", "1:30 pm-01.45 pm", "1:45 pm-2:00 pm",
                                        "2:00 pm-2:15 pm", "2:15 pm-2:30 pm", "2:30 pm-2:45 pm", "2:45 pm-3:00 pm",
                                        "3:00 pm-3:15 pm", "3:15 pm-3:30 pm", "3:30 pm-3:45 pm", "3:45 pm-4:00 pm",
                                        "4:00 pm-4:15 pm", "4:15 pm-4:30 pm", "4:30 pm-4:45 pm", "4:45 pm-5:00 pm",
                                        "5:00 pm-5:15 pm", "5:15 pm-5:30 pm", "5:30 pm-5:45 pm", "5:45 pm-6:00 pm",
                                        "6:00 pm-6:15 pm", "6:15 pm-6:30 pm", "6:30 pm-6:15 pm", "6:45 pm-7:00 pm",
                                        "7:00 pm-7:15 pm", "7:15 pm-7:30 pm", "7:30 pm-7:45 pm", "7:45 pm-8:00 pm",
                                        "8:00 pm-8:15 pm", "8:15 pm-8:30 pm", "8:30 pm-8:45 pm", "8:45 pm-9:00 pm",
                                        "9:00 pm-9:15 pm", "9:15 pm-9:30 pm", "9:30 pm-9:45 pm", "9:45 pm-10:00 pm",
                                        "10:00 pm-10:15 pm", "10:15 pm-10:30 pm", "10:30 pm-10:45 pm", "10:45 pm-11:00 pm",
                                        "11:00 pm-11:15 pm", "11:15 pm-11:30 pm", "11:30 pm-11:45 pm", "11:45 pm-00:00 am",
                                        "00:00 am-00:15 am", "00:15 am-00:30 am", "00:30 am-00:45 am", "00:45 am-1:00 am",
                                        "1:00 am-1:15 am", "1:15 am-1:30 am", "1:30 am-1:45 am", "1:45 am-2:00 am",
                                        "2:00 am-2:15 am", "2:15 am-2:30 am", "2:30 am-2:45 am", "2:45 am-3:00 am",
                                        "3:00 am-3:15 am", "3:15 am-3:30 am", "3:30 am-3:45 am", "3:45 am-4:00 am",
                                        "4:00 am-4:15 am", "4:15 am-4:30 am", "4:30 am-4:45 am", "4:45 am-5:00 am",
                                        "5:00 am-5:15 am", "5:15 am-5:30 am", "5:30 am-5:45 am", "5:45 am-6:00 am",
                                        "6:00 am-6:15 am", "6:15 am-6:30 am", "6:30 am-6:45 am", "6:45 am-7:00 am",
                                        "7:00 am-7:15 am", "7:15 am-7:30 am", "7:30 am-7:45 am", "7:45 am-8:00 am"
                                    ]
                                    // times: ["8:00 am-8:30 am", "8:30 am-9:00 am", "9:00 am-9:30 am", "9:30 am-10:00 am", "10:00 am-10:30 am", "10:30 am-11:00 am", "11:00 am-11:30 am",
                                    //     "11:30 am-00:00 pm", "12:00 pm-12:30 pm", "12:30 pm-1:00 pm", "1:00 pm-01.30 pm", "1:30 pm-2:00 pm", "2:00 pm-2:30 pm", "2:30 pm-3:00 pm", "3:00 pm-3:30 pm",
                                    //     "3:30 pm-4:00 pm", "4:00 pm-4:30 pm", "4:30 pm-5:00 pm", "5:00 pm-5:30 pm", "5:30 pm-6:00 pm", "6:00 pm-6:30 pm", "6:30 pm-7:00 pm", "7:00 pm-7:30 pm",
                                    //     "7:30 pm-8:00 pm", "8:00 pm-8:30 pm", "8:30 pm-9:00 pm", "9:00 pm-9:30 pm", "9:30 pm-10:00 pm", "10:00 pm-10:30 pm", "10:30 pm-11:00 pm", "11:00 pm-11:30 pm", "11:30 pm-00:00 am",
                                    //     "00:00 am-00:30 am", "00:30 am-1:00 am", "1:00 am-1:30 am", "1:30 am-2:00 am", "2:00 am-2:30 am", "2:30 am-3:00 am", "3:00 am-3:30 am", "3:30 am-4:00 am", "4:00 am-4:30 am", "4:30 am-5:00 am",
                                    //     "5:00 am-5:30 am", "5:30 am-6:00 am", "6:00 am-6:30 am", "6:30 am-7:00 am", "7:00 am-7:30 am", "7:30 am-8:00 am"
                                    // ]
                                },
                            );
                            this.selectedValue.push({
                                date: new Date(response.data.data.schedule[i].date),
                                times: times
                            });
                            for (let j = 0; j < response.data.data.schedule[i].times.length; j++) {
                                let key = this.schedule[i].times.indexOf(response.data.data.schedule[i].times[j]);
                                if (key !== -1) {
                                    this.selectedValue[i].times[key] = true;
                                }
                            }
                            if (i > 0) {
                                this.getIndex++;
                            }
                        }
                    }

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getServiceList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.categoryList,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.serviceList = response.data.map(el => {
                        return {
                            value: { id: el.id, name: el.name }, label: el.name
                        }
                    })
                }

            }).catch(error => {
                console.log(error);
            });
        },
        getStudioList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.studio.requestURL,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.studioList = response.data.studio.map(el => {
                        return {
                            value: { id: el.id, name: el.studioName }, label: el.studioName
                        }
                    })
                }

            }).catch(error => {
                console.log(error);
            });
        },
        addSchedule: function () {
            this.schedule.push(
                {
                    //date: new Date(),
                    date: "",
                    times: [
                        "8:00 am-8:15 am", "8:15 am-8:30 am", "8:30 am-8:45 am", "8:45 am-9:00 am",
                        "9:00 am-9:15 am", "9:15 am-9:30 am", "9:30 am-9:45 am", "9:45 am-10:00 am",
                        "10:00 am-10:15 am", "10:15 am-10:30 am", "10:30 am-10:45 am", "10:45 am-11:00 am",
                        "11:00 am-11:15 am", "11:15 am-11:30 am", "11:30 am-11:45 am", "11:45 am-12:00 pm",
                        "12:00 pm-12:15 pm", "12:15 pm-12:30 pm", "12:30 pm-12:45 pm", "12:45 pm-1:00 pm",
                        "1:00 pm-01.15 pm", "1:15 pm-01.30 pm", "1:30 pm-01.45 pm", "1:45 pm-2:00 pm",
                        "2:00 pm-2:15 pm", "2:15 pm-2:30 pm", "2:30 pm-2:45 pm", "2:45 pm-3:00 pm",
                        "3:00 pm-3:15 pm", "3:15 pm-3:30 pm", "3:30 pm-3:45 pm", "3:45 pm-4:00 pm",
                        "4:00 pm-4:15 pm", "4:15 pm-4:30 pm", "4:30 pm-4:45 pm", "4:45 pm-5:00 pm",
                        "5:00 pm-5:15 pm", "5:15 pm-5:30 pm", "5:30 pm-5:45 pm", "5:45 pm-6:00 pm",
                        "6:00 pm-6:15 pm", "6:15 pm-6:30 pm", "6:30 pm-6:15 pm", "6:45 pm-7:00 pm",
                        "7:00 pm-7:15 pm", "7:15 pm-7:30 pm", "7:30 pm-7:45 pm", "7:45 pm-8:00 pm",
                        "8:00 pm-8:15 pm", "8:15 pm-8:30 pm", "8:30 pm-8:45 pm", "8:45 pm-9:00 pm",
                        "9:00 pm-9:15 pm", "9:15 pm-9:30 pm", "9:30 pm-9:45 pm", "9:45 pm-10:00 pm",
                        "10:00 pm-10:15 pm", "10:15 pm-10:30 pm", "10:30 pm-10:45 pm", "10:45 pm-11:00 pm",
                        "11:00 pm-11:15 pm", "11:15 pm-11:30 pm", "11:30 pm-11:45 pm", "11:45 pm-00:00 am",
                        "00:00 am-00:15 am", "00:15 am-00:30 am", "00:30 am-00:45 am", "00:45 am-1:00 am",
                        "1:00 am-1:15 am", "1:15 am-1:30 am", "1:30 am-1:45 am", "1:45 am-2:00 am",
                        "2:00 am-2:15 am", "2:15 am-2:30 am", "2:30 am-2:45 am", "2:45 am-3:00 am",
                        "3:00 am-3:15 am", "3:15 am-3:30 am", "3:30 am-3:45 am", "3:45 am-4:00 am",
                        "4:00 am-4:15 am", "4:15 am-4:30 am", "4:30 am-4:45 am", "4:45 am-5:00 am",
                        "5:00 am-5:15 am", "5:15 am-5:30 am", "5:30 am-5:45 am", "5:45 am-6:00 am",
                        "6:00 am-6:15 am", "6:15 am-6:30 am", "6:30 am-6:45 am", "6:45 am-7:00 am",
                        "7:00 am-7:15 am", "7:15 am-7:30 am", "7:30 am-7:45 am", "7:45 am-8:00 am"
                    ]
                },
            );
            this.getIndex++;
            const tomorrow = new Date(this.selectedValue[this.getIndex - 1].date);
            tomorrow.setDate(tomorrow.getDate() + 1);
            let times = []
            for (let i = 0; i < 48; i++) {
                times[i] = false;
            }
            for (let i = 0; i < this.selectedValue[this.getIndex - 1].times.length; i++) {
                if (this.selectedValue[this.getIndex - 1].times[i]) {
                    times[i] = true;
                }
            }
            let timeData = [];
            for (let i = 0; i < this.selectedData[this.getIndex - 1].times.length; i++) {
                timeData.push(this.selectedData[this.getIndex - 1].times[i]);
            }
            this.selectedValue.push({ date: tomorrow, times: times });
            this.selectedData.push({ date: tomorrow, times: timeData });
        },
        removeSchedule: function (index) {
            this.schedule.splice(index + 1, 1);
            this.selectedValue.splice(index + 1, 1);
            this.selectedData.splice(index + 1, 1);
            this.getIndex--;
        },
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        setPlace(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.userDetails.streetAddress = locationData.name;

            this.userDetails.city = "";
            this.userDetails.zipCode = "";
            this.userDetails.state = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.userDetails.city = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.userDetails.zipCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.userDetails.state = addressComponent[i].long_name;
                }
            }
        },
        save: async function () {
            for (let i = 0; i < this.selectedData.length; i++) {
                if (this.selectedData[i].times.length == 0) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Time slot missing !!"
                    })
                    return;
                }
            }
            let formData = new FormData();
            let scheduleData = [];
            scheduleData = this.selectedData.map(el => {
                return {
                    date: el.date != "" ? new Date(el.date).toLocaleDateString() : "",
                    times: el.times
                }
            })

            let service = [];
            let subCat = [];
            let hasElement = false
            this.services.map(el => {
                hasElement = false;
                for (let i = 0; i < this.subServices.length; i++) {
                    if (this.subServices[i].value.categoryName != el.value.name) {
                        continue;
                    } else if (this.subServices[i].value.categoryName == el.value.name) {
                        subCat.push({ catId: this.subServices[i].value.categoryId, catName: this.subServices[i].value.categoryName, subId: this.subServices[i].value.id, subName: this.subServices[i].value.subCategoryName });
                        hasElement = true;
                    }
                }
                if (hasElement == false) {
                    subCat.push({ catId: el.value.id, catName: el.value.name, subId: "", subName: "" });
                }
            });
            subCat.map(item => {
                service.push({
                    id: item.catId,
                    name: item.catName,
                    subId: item.subId ? item.subId : "",
                    subName: item.subName ? item.subName : "",
                })
            })

            // let service = []
            // this.services.map(el => {
            //     return {
            //         id: el.value.id,
            //         name: el.value.name
            //     }
            // });

            let studio = {};
            if (this.studio?.value?.id) {
                studio = {
                    id: this.studio.value.id,
                    name: this.studio.value.name
                }
            }

            formData.append('fullName', this.userDetails.fullName);
            formData.append('businessName', this.userDetails.businessName);
            formData.append('city', this.userDetails.city);
            formData.append('zipCode', this.userDetails.zipCode);
            formData.append('state', this.userDetails.state);
            formData.append('phoneNo', this.userDetails.phoneNo);
            formData.append('streetAddress', this.userDetails.streetAddress);
            formData.append('image', this.image ? this.image : this.userDetails.image);
            formData.append('aboutMe', this.aboutMe ? this.aboutMe : "N/A");
            formData.append('studio', JSON.stringify(studio));
            scheduleData.map(el => {
                formData.append('schedule', JSON.stringify(el))
            });
            service.map(el => {
                formData.append('service', JSON.stringify(el))
            });
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.auth.updateProfile,
                data: formData,
                headers: authHeader()
            };
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    localStorage.setItem("name", this.userDetails.fullName);
                    localStorage.setItem("email", this.userDetails.email);
                    localStorage.setItem("mobile", this.userDetails.phoneNo);
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.selectedValue = [];
                    this.selectedData = [];
                    this.schedule = [];
                    this.subServiceList = [],
                        this.subServices = [],
                        this.services = [],
                        this.getIndex = 0;
                    await this.getUserInfo();
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed to update!"
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed to update!" + error
                });
            });

            this.isEdit = false;
        }
    }
}
