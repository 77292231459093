export default {
    name: "ForgotPassword",
    data() {
        return {
            email: ""
        }
    },
    mounted(){
        document.title = "Cosmo Salon - Forgot Password"
    },
    methods: {
        forgotPassword: function () {
            if (!this.email) {
      
              this.$swal.fire({
                icon: "error",
                text: "Please enter e-mail!"
              });
            } else {
              let data = {
                email: this.email,
              };
              let config = {
                method: "POST",
                url: this.$serverURL + this.$api.auth.forgotPassword,
                data: data
              };
              this.$axios(config).then((response) => {
                if (response.status == 201) {
      
                  this.$swal.fire({
                    icon: "success",
                    text: "Your reset password link has been send to your email"
                  });
                } else {
      
                  this.$swal.fire({
                    icon: "error",
                    text: "Incorrect e-mail and password combination!"
                  });
                }
              }).catch(error => {
                this.$swal.fire({
                  icon: "error",
                  text: error.response.data.message
                });
              })
            }
        }
    }
}