<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div class="custom-container-fluid" v-if="isAdminVerified">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3>Report</h3>
      </div>
    </div>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li v-for="(report, index) in reports" :key="index" class="nav-item border-bottom-2" role="presentation">
        <button class="nav-link report-nav-link" :class="index === 0 ? 'active' : ''" :id="'pills-'+index+'tab'" data-bs-toggle="pill" :data-bs-target="'#pills-'+index" type="button" role="tab" :aria-controls="'#pills-'+index" aria-selected="true">
          {{report.title}}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div v-for="(report, index) in reports" :key="index" class="tab-pane fade" :class="index === 0 ? 'show active' : ''" :id="'pills-'+index" role="tabpanel" :aria-labelledby="'pills-'+index+'-tab'" tabindex="0">
        <div>
          <h4>Select Date</h4>
          <p>Click the date Section </p>
          <div class="row g-4 row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5">
            <div class="col">
              <div class="report_card shadow" @click="$router.push({
                name:'ReportDetails',
                params: {name: report.title},
                query: {reportType: 'yesterday'}
              })">
                <h3 class="report_card_title">Yes’Day</h3>
                <p class="report_card_text">{{previousDate}}</p>
              </div>
            </div>
            <div class="col">
              <div class="report_card shadow" @click="$router.push({
                name:'ReportDetails',
                params: {name: report.title},
                query: {reportType: 'today'}
              })">
                <h3 class="report_card_title">Today</h3>
                <p class="report_card_text">{{currentDate}}</p>
              </div>
            </div>
            <div class="col">
              <div class="report_card shadow" @click="$router.push({
                name:'ReportDetails',
                params: {name: report.title},
                query: {reportType: 'all'}
              })">
                <h3 class="report_card_title">All</h3>
                <p class="report_card_text">Total Records</p>
              </div>
            </div>
            <div class="col">
              <div class="report_card shadow" data-bs-target="#addAdminModal" data-bs-toggle="modal" @click="setTitle(report.title)">
                <h3 class="report_card_title">Custom</h3>
                <p class="report_card_text">Start Date Range</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
  <div class="modal fade" id="addAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="addAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addAdminModalLabel">Select Date Range
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" id="closeAdd" class="btn-close text-site-dark" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-4 g-4">
                            <div class="col-12 col-sm-6">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Start Date<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="date" v-model="startDate" class="form-control rounded-0" placeholder="Enter start date">
                            </div>
                            <div class="col-12 col-sm-6">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">End Date<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="date" v-model="endDate"  class="form-control rounded-0" placeholder="Enter end date">
                            </div>
                        </div>

                    </div>
                    <div class="text-center p-3">
                        <button type="button"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2" @click="customReport">Search</button>
                    </div>
                </div>
            </div>
        </div>
</div>
</template>

<script src="../js/report.js">
</script>

<style scoped>
@import "../css/report.css";
</style>
