<template>
    <div class="body_section ms-0 px-3 overflow-y-auto d-flex align-items-center justify-content-center">
        <div class="reset_page">
            <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
            <div class="reset_page_form_section">
                <div class="card reset_form_card">
                    <div class="reset_form_card_title">
                        <h3>Reset Password</h3>
                    </div>
                    <form class="ps-4 pe-4" v-on:submit.prevent="updatePassword">

                        <div class="mb-4" v-if="resetFlag">
                            <label for="InputPassword" class="form-label login_form_card_label">New Password</label>
                            <div class="position-relative">
                                <input id="InputPassword" :type="[showPassword ? 'text' : 'password']"
                                    class="form-control custom_input" v-model="password"
                                    placeholder="input your password in here" required>
                                <span class="icon" @click="toggleShow">
                                    <i class="fas"
                                        :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                                </span>
                            </div>
                        </div>
                        <div class="mb-4" v-if="resetFlag">
                            <label for="InputRepeatPassword" class="form-label login_form_card_label">Retype New
                                Password</label>
                            <div class="position-relative">
                                <input id="InputRepeatPassword" :type="[showRepeatPassword ? 'text' : 'password']"
                                    class="form-control custom_input" v-model="repeatPassword"
                                    placeholder="input your password in here" required>
                                <span class="icon" @click="toggleShowRepeat">
                                    <i class="fas"
                                        :class="{ 'fa-eye': showRepeatPassword, 'fa-eye-slash': !showRepeatPassword }"></i>
                                </span>
                            </div>
                        </div>
                        <div class="form-group" v-if="resetFlag">
                            <input type="submit" class="login_form_card_login_btn primary_btn" value="Active Account" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="../js/ResetPassword.js"></script>
<style scoped>
@import "../../../assets/css/reset_password.scss";
</style>