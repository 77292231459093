import Loading from "vue-loading-overlay";
import {authHeader} from "../../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import LvColorpicker from 'lightvue/color-picker';
export default {
    name: "ServiceColor",
    components: {
        Loading,
        VPagination,
        LvColorpicker
    },
    data () {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            name: "",
            code: "",
            colorList: [],
            singleColor: [],
            page: 1,
            limit: 10,
            totalPage: 0,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "Cosmo Salon - Service Color";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.service.color.requestURL + params,
            headers: authHeader()
        };
        await this.getColorList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.service.color.requestURL + params,
                headers: authHeader()
            };
            await this.getColorList(config);
        },
        getColorList: async function(config) {
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.colorList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        addColor: async function() {
            this.isLoading = true;
            if(!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert color name!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.code) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert color code!"
                });
                this.isLoading = false;
                return false;
            }
            let data = {
                name: this.name,
                code: this.code
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.service.color.requestURL,
                data: data,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if(response.status == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                      });
                    this.name = "";
                    this.code = "";
                }
                this.isLoading = false;
                this.updateHandler();
                document.getElementById("closeAdd").click();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteColor: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.service.color.requestURL + '/' + id,
                            headers: authHeader()
                        };
                        this.$axios(config).then((response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                  });
                            }
                            this.isLoading = false;
                            this.updateHandler();
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        },
        setId: function(index) {
            this.singleColor = this.colorList[index];
        },
        updateColor: function() {
            this.isLoading = true;
            if(!this.singleColor.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert color name!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.singleColor.code) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert color code!"
                });
                this.isLoading = false;
                return false;
            }
            let data = {
                name: this.singleColor.name,
                code: this.singleColor.code
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.service.color.requestURL + '/' + this.singleColor.id,
                data: data,
                headers: authHeader()
            };
            this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.isLoading = false;
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', this.$swal.stopTimer)
                          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                      });
                }
                document.getElementById("closeUpdate").click();
                this.updateHandler();
                
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        }
    }
}
