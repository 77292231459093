import Loading from "vue-loading-overlay";
export default {
    name: "Past",
    props: [
        'serviceList',
        'productList'
    ],
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            serviceList: [],
            productList: [],
            filters :{
                product: "",
                service: ""
            }
        }
    },
}
