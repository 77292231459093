import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import html2pdf from 'html2pdf.js'
export default {
    components: {
        Loading,
    },
    data() {
        return {
            id: this.$route.params.id,
            orderDetails: {},
            billingAddress: {},
            shippingAddress: {},
            professionalDetails: {},
            pageCMSData: [],
            productList: [],
            serviceList: [],
            itemList: [],
            paymentInfo: {
                name: "",
                cardNumber: "",
                securityCode: "",
                amount: ""
            },
            paidAmount: "",
            expireDate: ""
        }
    },
    async created() {
        await this.getOrderDetails();
    },
    methods: {
        async getOrderDetails() {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderDetails + this.id,
                    headers: authHeader(),
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.orderDetails = response.data.data.invoice[0];
                        this.professionalDetails = response.data.data.professionalDetails;
                        this.productList = response.data.data.products;
                        this.serviceList = response.data.data.services;
                        this.billingAddress = this.orderDetails?.billingAddress ? JSON.parse(this.orderDetails.billingAddress) : null;
                        this.paymentInfo.amount = this.orderDetails.total - parseFloat(this.orderDetails.paymentAmount);
                        this.paidAmount = this.orderDetails.total - parseFloat(this.orderDetails.paymentAmount);
                        this.productList.map(el => {
                            let data = {
                                name: el.name,
                                type: "Product",
                                quantity: el.quantity,
                                price: el.itemPrice,
                                amount: parseFloat(el.itemPrice) * parseFloat(el.quantity)
                            }
                            this.itemList.push(data);
                        });
                        this.serviceList.map(el => {
                            let data = {
                                name: el.name,
                                type: "Service",
                                quantity: 1,
                                price: el.itemPrice,
                                amount: parseFloat(el.itemPrice) * 1
                            }
                            this.itemList.push(data);
                        })
                    }

                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." 
                });
            }
        },
        paymentValidation: function () {
            if (!this.paymentInfo.name) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card holder name."
                });
                return false;
            } else if (!this.paymentInfo.cardNumber) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card number."
                });
                return false;
            } else if (this.paymentInfo.cardNumber.length <= 14) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Card number must be at least 14 digit"
                });
                return false;
            } else if (!this.expireDate) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card expire date."
                });
                return false;
            } else if (!this.paymentInfo.securityCode) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please provide card security code."
                });
                return false;
            } else if (!this.paymentInfo.amount) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please enter payment amount."
                });
                return false;
            } else if(this.paidAmount != this.paymentInfo.amount) {
                this.$swal.fire({
                    icon: 'info',
                    text: "Please pay total due amount"
                });
                return false;
            }
             else {
                if (!this.expireDate.includes('/')) {
                    this.$swal.fire({
                        icon: 'info',
                        text: "Expire date format should be 02/22."
                    });
                    return false;
                }
                return true;
            }
        },
        completePayment: async function () {
            if (this.paymentValidation()) {
                let data = {
                    ccNum: this.paymentInfo.cardNumber ? this.paymentInfo.cardNumber.split(' ').join('') : '',
                    ccExp: this.expireDate ? this.expireDate.replace('/', '') : '',
                    cvv: this.paymentInfo.securityCode,
                    amount: this.paidAmount,
                    invoiceId: this.orderDetails.invoiceId
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.order.orderUpdateURL,
                    data: data,
                    headers: authHeader()
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message,
                        });
                        this.orderDetails = {};
                        this.getOrderDetails();
                        document.getElementById('buttonClose').click();
                    } else if (response.data.statusCode == 400) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        }).then(result => {
                            console.log(result);
                            this.isLoading = false;
                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                text: response.data.errors.responsetext,
                                icon: "error",
                                showConfirmButton: false,
                                timer: 3000,
                                animation: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                }
                            })
                        });
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                })
            }

        },
        formatCCnum: function () {

            if (this.paymentInfo.cardNumber.length == 4) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 9) {

                this.paymentInfo.cardNumber += " ";

            }

            if (this.paymentInfo.cardNumber.length == 14) {

                this.paymentInfo.cardNumber += " ";

            }

        },
        payFromClient: async function () {
            let data = {
                invoiceId: this.orderDetails.invoiceId
            };
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.payment.payFromClient,
                data: data,
                headers: authHeader()
            };
            this.isLoading = true;
            await this.$axios(config).then((response) => {
                this.isLoading = false;
                if (response.status == 201 || response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                    this.method = "";
                }
                this.getOrderDetails();
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        generatePDF: function () {
            const doc = document.getElementById('doc');
            // var opt = {
            //     filename: this.orderDetails.orderId + '.pdf',
            //   };
            var opt = {
            filename: this.orderDetails.invoiceId + '.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: 'avoid-all', before: '#page2el' }
            };
            html2pdf().from(doc).set(opt).save();
        },

    },
    watch: {
        expireDate: function () {
            if (this.expireDate.length == 2) {
                this.expireDate += '/';
            }
        }
    }
    
}