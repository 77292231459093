<template>
  <!-- Modal -->
  <div class="modal fade" id="addCategoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addCategoryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content round-20">
        <div class="modal-header border-0">
          <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addCategoryModalLabel">Add Category
            <p class="m-0 text-site-dark fs-13 fw-400 ff-poppins400">Lorem ipsum  dolor sit amet </p>
          </h5>
          <button type="button" class="btn-close text-site-dark" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-4">
            <label class="form-label text-site-dark ff-poppins400 fs-17">Category Name</label>
            <div class="d-flex gap-3">
              <input type="text" class="form-control" placeholder="Placeholder">
              <button class="btn bg-site-darkRed text-white border-0">Save</button>
            </div>
          </div>
          <div class="bg-site-papayaWhip px-4 py-3 round-13">
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center">
              <p class="mb-0">Hair Cut</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center py-2">
              <p class="mb-0">Nair Polish</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center py-2">
              <p class="mb-0">Makeup</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center py-2">
              <p class="mb-0">Hair Color</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center py-2">
              <p class="mb-0">Body Message</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>
            <div class="list-group-item d-flex gap-3 justify-content-between align-items-center py-2">
              <p class="mb-0">Party Makeup</p>
              <div class="">
                <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Action</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="text-center py-3">
          <button type="button" class="btn text-uppercase border-site-darkRed text-site-darkRed me-2" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn text-uppercase bg-site-darkRed text-white ms-2 border-site-darkRed">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add_category.js">
</script>

<style scoped>
@import "../css/add_category.css";
</style>
