<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div class="custom-container-fluid" v-if="isAdminVerified">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3>Analytics</h3>
      </div>
      
    </div>
    <div class="row g-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 mt-4">
      <div class="col">
        <div class="analytics_card d-flex justify-content-between gap-3 align-items-center">
          <div>
            <circle-progress :size="80" :border-bg-width="2" :border-width="6" :percent="parseFloat(dashboardData.totalServicePricePercentage)" :viewport="true" :show-percent="true" empty-color="#384455" :fill-color="'#895BF1'"/>
          </div>
          <div>
            <p class="analytics_card_title">Today Booked Amount</p>
            <h4 class="analytics_card_dollar">$ {{dashboardData.todayTotalServiceValue}}</h4>
            <p class="analytics_card_percent" v-if="parseFloat(dashboardData.servicePricePercentage) >= 0">{{dashboardData.servicePricePercentage}} %</p>
            <p class="decrease" v-else>{{dashboardData.servicePricePercentage}} %</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="analytics_card d-flex justify-content-between gap-3 align-items-center">
          <div>
            <circle-progress :size="80" :border-bg-width="2" :border-width="6" :percent="parseFloat(dashboardData.totalProductPricePercentage)" :viewport="true" :show-percent="true" empty-color="#384455" :fill-color="'#217EFD'"/>
          </div>
          <div>
            <p class="analytics_card_title">Today Ordered Amount</p>
            <h4 class="analytics_card_dollar">$ {{dashboardData.todayTotalProductValue}}</h4>
            <p class="analytics_card_percent" v-if="parseFloat(dashboardData.productPricePercentage) >= 0">{{dashboardData.productPricePercentage}} %</p>
            <p class="decrease" v-else>{{dashboardData.productPricePercentage}} %</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="analytics_card d-flex justify-content-between gap-3 align-items-center">
          <div>
            <circle-progress :size="80" :border-bg-width="2" :border-width="6" :percent="parseFloat(dashboardData.totalProductPercentage)" :viewport="true" :show-percent="true" empty-color="#384455" :fill-color="'#FFB536'"/>
          </div>
          <div>
            <p class="analytics_card_title">Today Product Ordered</p>
            <h4 class="analytics_card_dollar">{{dashboardData.todayTotalProductCount}}</h4>
            <p class="analytics_card_percent" v-if="parseFloat(dashboardData.productPercentage) >= 0">{{dashboardData.productPercentage}} %</p>
            <p class="decrease" v-else>{{dashboardData.productPercentage}} %</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="analytics_card d-flex justify-content-between gap-3 align-items-center">
          <div>
            <circle-progress :size="80" :border-bg-width="2" :border-width="6" :percent="parseFloat(dashboardData.totalServicePercentage)" :viewport="true" :show-percent="true" empty-color="#384455" :fill-color="'#10ad47'"/>
          </div>
          <div>
            <p class="analytics_card_title">Today Service Booked</p>
            <h4 class="analytics_card_dollar">{{dashboardData.todayTotalServiceCount}}</h4>
            <p class="analytics_card_percent" v-if="parseFloat(dashboardData.servicePercentage) >= 0">{{dashboardData.servicePercentage}} %</p>
            <p class="decrease" v-else>{{dashboardData.servicePercentage}} %</p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="fs-23 ff-poppins400 fw-600 mt-4 mb-3">Earning</h2>
    <div class="row g-4">
      <div class="col-12 col-md-7 col-lg-8 col-xl-9">
        <div class="row gy-4">
          <div class="col-12 col-sm-6  col-xl-3">
            <div class="analytics_card">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Service Booked</p>
                  <h4 class="mb-0 text-site-darkRed fs-20 fw-700 ff-poppins400">${{dashboardData.totalServiceValue}}</h4>
                </div>
                <div class="text-center">
                  <img src="../../../assets/images/3user.svg" width="25" height="25">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <div class="analytics_card">
              <div class="d-flex gap-2 align-items-center">
                <div class="">
                  <img src="../../../assets/images/AvgTicket.svg" width="25" height="25">
                </div>
                <div class="w-100 align-items-center">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Product Ordered</p>
                  <h4 class="mb-0 text-site-darkRed fs-20 fw-700 ff-poppins400 d-flex justify-content-between gap-2">${{dashboardData.totalProductValue}}
                    <GChart
                        type="LineChart"
                        :options="options2"
                        :data="collectionData"
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <div class="analytics_card">
              <div class="d-flex gap-2 align-items-center">
                <div class="">
                  <img src="../../../assets/images/AvgServiePerVisit.svg" width="25" height="25">
                </div>
                <div class="w-100 align-items-center">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Avg. Service Per Day</p>
                  <h4 class="mb-0 text-site-darkRed fs-20 fw-700 ff-poppins400 d-flex justify-content-between gap-2">{{dashboardData.averageServiceSales}}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <div class="analytics_card">
              <div class="d-flex gap-2 align-items-center">
                <div class="">
                  <img src="../../../assets/images/AvgRetailsVisit.svg" width="25" height="25">
                </div>
                <div class="w-100 align-items-center">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Avg. Products Per Day</p>
                  <h4 class="mb-0 text-site-darkRed fs-20 fw-700 ff-poppins400 d-flex justify-content-between gap-2">{{dashboardData.averageProductSales}}
                    <GChart
                        type="LineChart"
                        :options="options2"
                        :data="collectionData"
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 class="fs-23 ff-poppins400 fw-600 my-3">Appointment</h2>
        <div class="row gy-4">
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="analytics_card min-height-100">
              <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                <div class="text-center">
                  <img src="../../../assets/images/TotalAppointment.svg" width="30" height="30">
                </div>
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Appointment</p>
                  <h4 class="mb-0 text-site-dark fs-20 fw-500 ff-poppins400">{{dashboardData.totalServiceCount}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="analytics_card min-height-100">
              <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                <div class="text-center">
                  <img src="../../../assets/images/TotalAppointment.svg" width="30" height="30">
                </div>
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Total Product Order</p>
                  <h4 class="mb-0 text-site-dark fs-20 fw-500 ff-poppins400">{{dashboardData.totalProductCount}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-4">
            <div class="analytics_card min-height-100">
              <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                <div class="text-center">
                  <img src="../../../assets/images/RepeatClients.svg" width="30" height="30">
                </div>
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Repeat Clients</p>
                  <h4 class="mb-0 text-site-dark fs-20 fw-500 ff-poppins400">{{dashboardData.repeatedUser}}</h4>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-sm-6 col-xl-3">
            <div class="analytics_card min-height-100">
              <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                <div class="text-center">
                  <img src="../../../assets/images/NoShow.svg" width="30" height="30">
                </div>
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">No Show</p>
                  <h4 class="mb-0 text-site-dark fs-20 fw-500 ff-poppins400">00.00</h4>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-12 col-sm-6 col-xl-3">
            <div class="analytics_card min-height-100">
              <div class="d-flex justify-content-between align-items-center w-100 gap-2">
                <div class="w-100">
                  <p class="mb-1 text-site-dark fs-14 ff-poppins400 fw-500">Cancelled Appoint</p>
                  <h4 class="mb-0 text-site-dark fs-20 fw-500 ff-poppins400">50</h4>
                </div>
                <div>
                  <circle-progress :size="60" :border-bg-width="2" :border-width="4" :percent="52" :viewport="true" :show-percent="true" empty-color="#384455" :fill-color="'#FFB536'"/>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-4 col-xl-3">
        <div class="analytics_card justify-content-center">
          <GChart type="PieChart" :options="optionsPie" :data="topServices" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
</div>
</template>

<script src="../js/analytics.js">
</script>

<style scoped>
@import "../css/analytics.css";
</style>
