import Loading from "vue-loading-overlay";
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import ClientDetailsModal from "../template/ClientDetailsModal";
export default {
    name: "ClientDetails",
    components: {
        Loading,
        Carousel,
        Slide,
        ClientDetailsModal
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true
            },
            breakpoints: {
                // 700px and up
                575: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3.3,
                    snapAlign: 'start',
                },
            },
        }
    }
}
