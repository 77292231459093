import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    name: "PaymentCard",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            name: "",
            dataList: [],
            sheets: []
        }
    },
    async created() {
        document.title = "Cosmo Salon - Payment Log";
        await this.getLogList();
    },
    methods: {
        getLogList: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.payment.paymentLog,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.dataList = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    "Invoice ID": el.invoiceID,
                    "Customer Name": el.customerName,
                    "Payment Amount": el.paymentAmount,
                    "Payment Date": el.paymentDate,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'payment_log_report(' + date + ').xlsx')
            this.sheets = [];
        },
    },
}
