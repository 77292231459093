<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Services</h3>
        </div>
        <div>
          <button class="btn add_service_btn" @click="$router.push('/add-service')">add
            Services</button>
        </div>
      </div>
      <div style="margin-top: 5%;">
        <div class="card p-3 shadow border-0 round-17">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Service List
            </h5>
          </div>
          <div class="table-container">
            <div class="table-section">
              <div class="table-responsive">
                <table class="custom_table custom_table_header_white">
                  <thead>
                    <tr>
                      <th >Sl</th>
                      <th >Name</th>
                      <th >Category</th>
                      <th >Amount</th>
                      <th >Created At</th>
                      <th >Image</th>
                      <th >Options</th>
                    </tr>
                  </thead>
                  <tbody v-if="serviceList.length > 0">
                    <tr v-for="(data, index) in serviceList" :key="index">
                      <td data-label="Sl :">{{index + 1}}</td>
                      <td data-label="Name :">
                        {{data.name}}
                      </td>
                      <td data-label="Category :">
                        {{data.categoryName}}
                      </td>
                      <td data-label="Amount :">
                        <a class="bg-site-darkSeaGreen round-10 px-2 text-white">${{data.price}}</a>
                      </td>
                      <td data-label="Created At :">{{$filters.formatDate(data.createdAt)}}</td>
                      <td data-label="Image :">
                        <img :src="$imageURL + 'service/' + data.image" v-if="data.image && data.image != 'null'" height="50" width="50">
                        <img :src="$imageURL + 'service-sub-category/' + data.subCategoryImage" v-else-if="data.subCategoryImage" height="50" width="50">
                        <img src="../../../../assets/images/no-image.jpg" v-else height="50" width="50">
                      </td>
                      <td data-label="Options :">
                        <button @click="$router.push('/update-service/' + data.id)" title="edit"
                          class="btn btn-sm btn-success action_btn">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button title="delete" @click="deleteService(data.id)" class="btn btn-sm btn-danger action_btn">
                          <i class="fas fa-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7">
                        <p class="mb-0 text-center">No Data Found !</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>


          </div>
        </div>
      </div>
      <nav
            class="page-navigation justify-content-center d-flex mt-3"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="serviceList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
    <div class="modal fade" id="addExpensesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addExpensesModalLabel">Add Service
              <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span>
            </h5>
            <button type="button" class="btn-close text-site-dark" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Category</label>
                <input type="text" class="form-control rounded-0" placeholder="Placeholder">
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Date of expenses</label>
                <select class="form-select rounded-0">
                  <option>Placeholder</option>
                </select>
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label text-site-dark ff-poppins400 fs-17">Category </label>
              <textarea class="form-control rounded-2" rows="4"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi "></textarea>
            </div>
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Amount</label>
                <input type="text" class="form-control rounded-0" placeholder="Placeholder">
              </div>
              <div class="col-12 col-sm-6">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Vendor</label>
                <input type="text" class="form-control rounded-0" placeholder="Placeholder">
              </div>
            </div>
            <div class="mb-4">
              <label for="formFile" class="btn w-100 bg-site-papayaWhip border-site-papayaWhip fs-18 text-site-dark">
                <i class="bi bi-link-45deg me-2 fs-18"></i>
                Upload Some file</label>
              <input class="d-none" type="file" id="formFile">
            </div>
            <div class="form-control">
              <div class="form-switch d-flex justify-content-between gap-4 ps-0">
                <label class="" for="flexSwitchCheckDefault">Default switch checkbox input</label>
                <input class="form-check-input add_expenses_form-check-input" type="checkbox" role="switch"
                  id="flexSwitchCheckDefault">
              </div>
            </div>
          </div>
          <div class="text-center p-3">
            <button type="button"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/service-list.js">
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0;
    vertical-align: middle!important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.custom_table.custom_table_header_grayish tbody tr {
    border-bottom: 3px solid #b3b0b0 !important;
    background-color: #f7f6f6!important;
  }
  table.custom_table.custom_table_header_white tbody tr td {
    text-align: right!important;
    background-color: #f7f6f6!important;
  }
  table tr {
    border-bottom: 3px solid #ddd!important;
    display: block;
    margin-bottom: .725em;
    background-color: #b3b0b0!important;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right!important;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
  @import "../css/service.css";
  @import "../css/add_service.css";
  @import url("../../../../assets/css/pagination.css");
</style>
