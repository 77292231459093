<template>
    <div>
        <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
        <div class="custom-container-fluid">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h3 class="fw-600">Product Brand</h3>
                </div>
                <div>
                    <button class="btn add_service_btn" data-bs-target="#addAdminModal" data-bs-toggle="modal">Add
                        Brand</button>
                </div>
            </div>
            <div style="margin-top: 5%;" >
                <div class="card p-3 shadow border-0 round-17">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Product Brand List
                        </h5>
                    </div>
                    <div class="table-container">
                        <div class="table-section">
                            <div class="table-responsive">
                                <table class="custom_table custom_table_header_white">
                                    <thead>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Name</th>
                                            <th>Created At</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="brandList.length > 0">
                                        <tr v-for="(row, index) in brandList" :key="index">
                                            <td> {{ index + 1 }} </td>
                                            <td> {{ row['name'] }} </td>
                                            <td> {{ $filters.formatDate(row['createdAt']) }} </td>
                                            <td> 
                                                <button title="edit" v-on:click="setId(index)" class="btn btn-sm btn-success action_btn" data-bs-target="#updateAdminModal" data-bs-toggle="modal">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button title="delete" v-on:click="deleteBrand(row['id'])" class="btn btn-sm btn-danger action_btn">
                                                    <i class="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="4">
                                                <p class="mb-0 text-center">No Data Found !</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav
            class="page-navigation justify-content-center d-flex mt-3"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="brandList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
            
        </div>
        <div class="modal fade" id="addAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="addAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="addAdminModalLabel">Add
                            Product Brand
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" id="closeButton" class="btn-close text-site-dark" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <div class="col-12 col-sm-12">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Name<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="text" v-model="name" class="form-control rounded-0" placeholder="Enter name">
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-3">
                        <button type="button" @click="addBrand"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Create</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Modal -->
        <div class="modal fade" id="updateAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="updateAdminModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content round-20">
                    <div class="modal-header border-0">
                        <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="updateAdminModalLabel">Update
                            Product Brand
                            <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum dolor sit amet </span> -->
                        </h5>
                        <button type="button" id="buttonClose" class="btn-close text-site-dark" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-4">
                            <div class="col-12 col-sm-12">
                                <label class="form-label text-site-dark ff-poppins400 fs-17">Name<span
                                        style="color: red; font-size: 18px">*</span></label>
                                <input type="text" v-model="singleBrand.name" class="form-control rounded-0" placeholder="Enter name">
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-3">
                        <button type="button" v-on:click="updateBrand(singleBrand.id)"
                            class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 px-4 py-2">Update</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Edit Modal -->
    </div>
</template>

<script src="../js/product-brand.js">
</script>

<style scoped>
    @import "../css/add_product_cat.css";
    @import url("../../../assets/css/pagination.css");
</style>