<template>
    <div class="body_section ms-0 px-3 overflow-y-auto d-flex align-items-center justify-content-center">
        <div class="forgot_page_form_section">
            <div class="card forgot_form_card">
                <div class="forgot_form_card_title text-site-darkRed">
                    <h3>Enter Your Email</h3>
                </div>
                <form class="" v-on:submit.prevent="forgotPassword">
                    <div class="mb-4">
                        <label for="email" class="form-label login_form_card_label">Email Address</label>
                        <div class="position-relative">
                            <input type="email" v-model="email" class="form-control forgot_page_custom_input" id="email"
                                placeholder="Enter your email address" required>
                            <i class="bi bi-envelope forgot_page_custom_input_icon"></i>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="submit"
                            class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase"
                            value="Send" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script src="../js/ForgotPassword.js"></script>

<style scoped>
@import "../../../assets/css/forgot_password.scss";
</style>
