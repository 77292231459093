<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Invoices</h3>
        </div>
      </div>
      <div style="margin-top: 5%;">
        <div class="card p-3 shadow border-0 round-17">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="">Invoice List
            </h5>
          </div>
          <div class="table-container">
            <div class="table-section">
              <div class="table-responsive">
                <table class="custom_table custom_table_header_white">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th style="min-width: 100px">Invoice</th>
                      <th style="min-width: 100px">Customer Name</th>
                      <th style="min-width: 100px">Total Amount</th>
                      <th style="min-width: 100px">Paid Amount</th>
                      <th style="min-width: 100px">Payment Status</th>
                      <th style="min-width: 134px;">Invoice Status</th>
                      <th style="min-width: 100px">Created Date</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody v-if="orderList.length > 0">
                    <tr v-for="(data, index) in orderList" :key="index">
                      <td data-label="SL:">{{index + 1}}</td>
                      <td data-label="Invoice:">
                        {{data.invoiceId}}
                      </td>
                      <td  data-label="Customer Name:">
                        {{data.customerName}}
                      </td>
                      <td  data-label="Total Amount:">
                        <a class="bg-site-darkSeaGreen round-10 px-2 text-white">${{data.total}}</a>
                      </td>
                      <td  data-label="Paid Amount:">
                        <a class="bg-site-darkSeaGreen round-10 px-2 text-white">${{data.paymentAmount}}</a>
                      </td>
                      <!-- <td  data-label="Payment Status:">{{data.paymentStatus}}</td> -->
                      <td  data-label="Payment Status:">
                        <select v-model="data.paymentStatus" class="form-select" :onchange="
                        () => {
                          updatePayementStatus(data);
                        }
                      ">
                          <option value="PENDING">PENDING</option>
                          <option value="PAID">PAID</option>
                        </select>
                      </td>
                      <td  data-label="Invoice Status:">
                        <select v-model="data.status" class="form-select" :onchange="
                        () => {
                          updateStatus(data);
                        }
                      ">
                          <option value="PENDING">PENDING</option>
                          <option value="COMPLETED">COMPLETED</option>
                        </select>
                      </td>
                      <td  data-label="Created Date:">{{$filters.formatDate(data.createdAt)}}</td>
                      <td  data-label="Options:">

                        <button title="view" @click="$router.push('/checkout-details/' + data.invoiceId)"
                          class="btn edit-button px-4">
                          <i class="bi bi-eye text-white"></i></button>
                        <!-- <button title="delete" @click="deleteOrder(data.id)" class="btn btn-sm btn-danger action_btn">
                            <i class="fas fa-trash"></i></button> -->
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <p class="mb-0 text-center">No Data Found !</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>


          </div>
          <nav class="page-navigation justify-content-center d-flex mt-3" aria-label="page-navigation">
            <v-pagination v-if="orderList.length > 0" v-model="page" @update:modelValue="updateHandler"
              :pages="totalPage" :range-size="1" active-color="#FF8400"></v-pagination>
          </nav>
        </div>
      </div>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
  </div>
</template>

<script src="../js/checkout-list.js">
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0;
    vertical-align: middle!important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.custom_table.custom_table_header_grayish tbody tr {
    border-bottom: 3px solid #b3b0b0 !important;
    background-color: #f7f6f6!important;
  }
  table.custom_table.custom_table_header_white tbody tr td {
    text-align: right!important;
    background-color: #f7f6f6!important;
  }
  table tr {
    border-bottom: 3px solid #ddd!important;
    display: block;
    margin-bottom: .725em;
    background-color: #b3b0b0!important;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right!important;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
  @import url("../../../assets/css/pagination.css");

  .edit-button {
    background-color: #49abab;
  }
</style>
