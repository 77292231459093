import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import $ from 'jquery';
export default {
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            orderList: [],
            orderStatus: "",
            singleAppointment: {},
            page: 1,
            limit: 10,
            totalPage: 0,
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false,
            tabStatus: 'service',
            times: [
                "8:00 am-8:15 am", "8:15 am-8:30 am", "8:30 am-8:45 am", "8:45 am-9:00 am",
                "9:00 am-9:15 am", "9:15 am-9:30 am", "9:30 am-9:45 am", "9:45 am-10:00 am",
                "10:00 am-10:15 am", "10:15 am-10:30 am", "10:30 am-10:45 am", "10:45 am-11:00 am",
                "11:00 am-11:15 am", "11:15 am-11:30 am", "11:30 am-11:45 am", "11:45 am-12:00 pm",
                "12:00 pm-12:15 pm", "12:15 pm-12:30 pm", "12:30 pm-12:45 pm", "12:45 pm-1:00 pm",
                "1:00 pm-01.15 pm", "1:15 pm-01.30 pm", "1:30 pm-01.45 pm", "1:45 pm-2:00 pm",
                "2:00 pm-2:15 pm", "2:15 pm-2:30 pm", "2:30 pm-2:45 pm", "2:45 pm-3:00 pm",
                "3:00 pm-3:15 pm", "3:15 pm-3:30 pm", "3:30 pm-3:45 pm", "3:45 pm-4:00 pm",
                "4:00 pm-4:15 pm", "4:15 pm-4:30 pm", "4:30 pm-4:45 pm", "4:45 pm-5:00 pm",
                "5:00 pm-5:15 pm", "5:15 pm-5:30 pm", "5:30 pm-5:45 pm", "5:45 pm-6:00 pm",
                "6:00 pm-6:15 pm", "6:15 pm-6:30 pm", "6:30 pm-6:15 pm", "6:45 pm-7:00 pm",
                "7:00 pm-7:15 pm", "7:15 pm-7:30 pm", "7:30 pm-7:45 pm", "7:45 pm-8:00 pm",
                "8:00 pm-8:15 pm", "8:15 pm-8:30 pm", "8:30 pm-8:45 pm", "8:45 pm-9:00 pm",
                "9:00 pm-9:15 pm", "9:15 pm-9:30 pm", "9:30 pm-9:45 pm", "9:45 pm-10:00 pm",
                "10:00 pm-10:15 pm", "10:15 pm-10:30 pm", "10:30 pm-10:45 pm", "10:45 pm-11:00 pm",
                "11:00 pm-11:15 pm", "11:15 pm-11:30 pm", "11:30 pm-11:45 pm", "11:45 pm-00:00 am",
                "00:00 am-00:15 am", "00:15 am-00:30 am", "00:30 am-00:45 am", "00:45 am-1:00 am",
                "1:00 am-1:15 am", "1:15 am-1:30 am", "1:30 am-1:45 am", "1:45 am-2:00 am",
                "2:00 am-2:15 am", "2:15 am-2:30 am", "2:30 am-2:45 am", "2:45 am-3:00 am",
                "3:00 am-3:15 am", "3:15 am-3:30 am", "3:30 am-3:45 am", "3:45 am-4:00 am",
                "4:00 am-4:15 am", "4:15 am-4:30 am", "4:30 am-4:45 am", "4:45 am-5:00 am",
                "5:00 am-5:15 am", "5:15 am-5:30 am", "5:30 am-5:45 am", "5:45 am-6:00 am",
                "6:00 am-6:15 am", "6:15 am-6:30 am", "6:30 am-6:45 am", "6:45 am-7:00 am",
                "7:00 am-7:15 am", "7:15 am-7:30 am", "7:30 am-7:45 am", "7:45 am-8:00 am"
            ],
            selectedTime: [],
            confirmedSelectedTimeList: [],
            reserved: [],
            bookedTimes: [],
            isBooked: false,
        }
    },
    async mounted() {
        document.title = "Cosmo Salon - Appointment";
        let params = '?page=' + this.page + '&limit=' + this.limit + '&tabStatus=' + this.tabStatus;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.order.orderURL + params,
            headers: authHeader()
        };
        await this.getOrderList(config);
    },
    methods: {
        updateHandler: async function () {
            let params = '?page=' + this.page + '&limit=' + this.limit + '&tabStatus=' + this.tabStatus;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.orderURL + params,
                headers: authHeader()
            };
            await this.getOrderList(config);
        },
        getBookedTime: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.order.bookedTime + '?date=' + this.$filters.formatDateBookedTime(this.singleAppointment.serviceRequestDate),
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.bookedTimes = response.data.data;
                    for (let i = 0; i < this.times.length; i++) {
                        this.reserved[i] = false;
                    }
                    // for (let i = 0; i < 48; i++) {
                    //     this.reserved[i] = false;
                    // }
                    for (let j = 0; j < this.bookedTimes.length; j++) {
                        let key = this.times.indexOf(this.bookedTimes[j]);
                        if (key !== -1) {
                            this.reserved[key] = true;
                        }
                    }

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        setData: async function (data) {
            this.confirmedSelectedTimeList = [];
            this.selectedTime = [];
            this.singleAppointment = data;
            await this.getBookedTime();
        },

        handleAssignValue: function (index, status, data) {
            if (status == true) {
                this.isBooked = false;
                this.confirmedSelectedTimeList = [];
                this.selectedTime = [];

                this.selectedTime[index] = true
                this.confirmedSelectedTimeList.push(data);
                let serviceDuration = parseInt(this.singleAppointment.serviceDuration);
                let requiredTimeSlot = serviceDuration / 15;

                for (let i = 1; i < requiredTimeSlot; i++) {
                    if (this.reserved[index + i] == true) {
                        this.isBooked = true;
                        this.confirmedSelectedTimeList = [];
                        this.selectedTime = [];
                        break;
                    } else {
                        this.isBooked = false;
                        let selectedNext = this.times[index + i];
                        this.confirmedSelectedTimeList.push(selectedNext);
                        this.selectedTime[index + i] = true
                    }
                }
                if (this.isBooked == true) {
                    this.$swal.fire({
                        icon: "info",
                        text: "This time slot is not available. Please select another time slot."
                    })
                    return;
                }

            } else if (status == false) {
                this.confirmedSelectedTimeList = [];
                this.selectedTime = [];
            }
        },

        bookGetInLine: async function () {
            if (this.confirmedSelectedTimeList.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select time slot"
                })
                return;
            }
            else {
                // console.log(this.confirmedSelectedTimeList);
                // let confirmedSelectedTimeList = this.confirmedSelectedTimeList.filter(item => !(item == 0));
                // console.log(confirmedSelectedTimeList);
                // console.log(serviceDuration);
                // console.log(requiredTimeSlot);
                // console.log('len',confirmedSelectedTimeList.length)
                // if (confirmedSelectedTimeList.length != requiredTimeSlot) {
                //     this.$swal.fire({
                //         icon: "info",
                //         text: `You Need to select exact ${requiredTimeSlot} time slot for this service.`
                //     })
                //     return;
                // }

                // for (let i = 0; i < confirmedSelectedTimeList.length; i++) {
                //     console.log(confirmedSelectedTimeList[i]);
                // }
                let data = {
                    timeSlot: JSON.stringify(this.confirmedSelectedTimeList),
                };
                let config = {
                    method: "PATCH",
                    data: data,
                    url: this.$serverURL + this.$api.order.getInLine + this.singleAppointment.invoiceId,
                    headers: authHeader()
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        document.getElementById('closeAdd').click();
                        this.updateHandler();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: "Appointment updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
        async getOrderList(config) {
            this.orderList = [];
            try {
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;
                        this.totalPage = await response.data.data.pageCount;
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el.firstName + el.lastName, label: el.firstName + el.lastName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async updateOrder(param) {

            try {
                let data = {
                    status: param.status,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.order.statusUpdate + param.id,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.updateHandler();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Appointment updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.messsage
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async updatePaymentStatus(param) {

            try {
                let data = {
                    paymentStatus: param.paymentStatus,
                    paymentAmount: param.total,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.order.paymentStatusUpdateURL + '/' + param.id,
                    data: data,
                    headers: authHeader(),
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.data.statusCode == 200) {
                        this.updateHandler();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Appointment updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }

                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.messsage
                    });
                });
            } catch (error) {
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async approval(status, id) {
            let buttonText = status == 'Approved' ? 'Approve' : 'Deny';
            let data = {
                isApproved: status
            };
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: buttonText
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'PATCH',
                            url: this.$serverURL + this.$api.order.approveURL + id,
                            data: data,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.status == 200) {
                                this.updateHandler();
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                            }

                        }).catch(error => {

                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        async deleteOrder(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.order.orderURL + '/' + id,
                            headers: authHeader()
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.deleted == true) {
                                this.updateHandler();
                                this.$swal.fire({
                                    icon: "warning",
                                    text: "Order deleted Successfully."
                                });
                            }

                        }).catch(error => {

                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    },

    watch: {
        $route(to, from) {
            console.log("ok",to,from)
        }
    }


}