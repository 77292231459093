<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div v-if="!isAppointmentList">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h3 class="fw-600">Calendar</h3>
          </div>
        </div>
        <!-- <v-calendar is-expanded :attributes="attrs" /> -->
        
        <div class="custom_calender">
          <FullCalendar :options="options" />
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h3 class="fw-600">Calendar</h3>
          </div>
        </div>
        <div>
          <span @click="handleToogle" style="cursor:pointer; font-weight:600">
            <i class="fa-solid fa-arrow-left me-2"></i>back to calendar
          </span>
        </div>
        <div style="margin-top: 5%;">
          <div class="card p-3 shadow border-0 round-17">
            <div class="modal-header border-0">
              <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id>Appointment List For: {{this.$filters.workingDate(selectedDate)}}</h5>
            </div>
            <div class="table-container">
              <div class="table-section">
                <div class="table-responsive">
                  <table class="custom_table custom_table_header_white">
                    <thead>
                      <tr>
                        <th>Sl</th>
                        <th style="min-width: 100px">Invoice</th>
                        <th style="min-width: 100px">Customer Name</th>
                        <th style="min-width: 100px">Total Amount</th>
                        <th style="min-width: 100px">Paid Amount</th>
                        <th style="min-width: 100px">Requested Date</th>
                        <th style="min-width: 100px">Start Time</th>
                        <th style="min-width: 100px">End TIme</th>
                        <th style="min-width: 100px">Payment Status</th>
                        <th style="min-width: 100px">Service Status</th>
                        <th style="min-width: 100px">Booking Status</th>
                        <th style="min-width: 100px">Appointment Status</th>
                        <!-- <th >Created Date</th> -->
                        <!-- <th style="min-width: 250px">Options</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="orderList.length > 0">
                      <tr v-for="(data, index) in orderList" :key="index">
                        <td class="fw-900" data-label="Sl :">{{index + 1}}</td>
                        <td data-label="Invoice :">{{data.invoiceId}}</td>
                        <td data-label="Customer Name :">{{data.customerName}}</td>
                        <td data-label="Total Amount :">
                          <a class="bg-site-darkSeaGreen round-10 px-2 text-white">${{data.total}}</a>
                        </td>
                        <td data-label="Paid Amount :">
                          <a
                            class="bg-site-darkSeaGreen round-10 px-2 text-white"
                          >${{data.paymentAmount}}</a>
                        </td>
                        <td
                          data-label="Requested Date :"
                        >{{$filters.formatDate(data.serviceRequestDate)}}</td>
                        <td data-label="Requested Time Slot :">{{data.startTime}}</td>
                        <td data-label="Requested Time Slot :">{{data.endTime}}</td>
                        <!-- <td data-label="Payment Status :">{{data.paymentStatus}}</td> -->
                        <td data-label="Invoice Status :">
                          <!-- <select
                            v-model="data.paymentStatus"
                            class="form-select"
                            :onchange="
                          () => {
                            updatePaymentStatus(data);
                          }
                        "
                          >
                            <option value="Paid">Paid</option>
                            <option :disabled="data.paymentStatus=='Paid'" value="Pending">Pending</option>
                          </select>-->
                          {{data.paymentStatus}}
                        </td>
                        <!-- <td
                          data-label="Service Status :"
                          v-if="data.serviceStatus=='In Line' && data.isApproved!='Denied'"
                        >
                          <button
                            @click="setData(data)"
                            type="button"
                            class="btn add_service_btn"
                            data-bs-target="#getInLine"
                            data-bs-toggle="modal"
                          >Get In Line</button>
                        </td>-->
                        <td data-label="Service Status :">{{data.serviceStatus}}</td>
                        <td data-label="Booking Status :">{{data.isApproved}}</td>
                        <td data-label="Invoice Status :">
                          <!-- <select
                            v-model="data.status"
                            class="form-select"
                            :onchange="
                          () => {
                            updateOrder(data);
                          }
                        "
                          >
                            <option value="PENDING">PENDING</option>
                            <option value="COMPLETED">COMPLETED</option>
                          </select>-->
                          {{data.status}}
                        </td>
                        <!-- <td>{{$filters.formatDate(data.createdAt)}}</td> -->
                        <!-- <td data-label="Options :">
                          <div class="d-flex justify-content-end flex-wrap gap-2">
                            <button
                              @click="approval('Approved', data.id)"
                              type="button"
                              class="btn btn-outline-primary px-3"
                              :disabled="data.isApproved != 'Not Approved'"
                            >{{ data.isApproved == 'Approved' ? 'Approved' : 'Approve' }}</button>
                            <button
                              @click="approval('Denied', data.id)"
                              type="button"
                              class="btn btn-outline-danger px-3"
                              :disabled="data.isApproved != 'Not Approved'"
                            >{{ data.isApproved == 'Denied' ? 'Denied' : 'Deny' }}</button>
                            <button
                              title="view"
                              @click="$router.push('/checkout-details/' + data.invoiceId)"
                              class="btn edit-button px-4"
                            >
                              <i class="bi bi-eye text-white"></i>
                            </button>
                          </div>
                        </td>-->
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="9">
                          <p class="mb-0 text-center">No Data Found !</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <nav
              class="page-navigation justify-content-center d-flex mt-3"
              aria-label="page-navigation"
            >
              <v-pagination
                v-if="orderList.length > 0"
                v-model="page"
                @update:modelValue="updateHandler"
                :pages="totalPage"
                :range-size="1"
                active-color="#FF8400"
              ></v-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
  </div>
</template>
  
  <script src="../js/calender.js">
</script>
  <style scoped>
@import "../css/calender.css";
@import "~@fullcalendar/list/main.min.css";
.fc-theme-standard {
  background-color: #fff;
}
</style>
  