<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div class="custom-container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3 class="fw-600">Details</h3>
      </div>
    </div>
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="slide in 10" :key="slide">
        <div class="carousel__item">
          <div class="card round-10 p-2">
            <div class="text-center dropdown-center">
              <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0 text-" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots" style="color: #99B2C6"></i>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Action</a></li>
              </ul>
            </div>
            <div class="card-img-section bg-site-dark">
              <img src="../../../assets/images/past_img.svg" class="w-100 h-100 rounded-circle" alt="...">
            </div>
            <div class="card-body px-0 pb-0">
              <h5 class="card-title text-center">Client Name</h5>
              <p class="card-text text-center">info@supportmail.com</p>
              <div class="row mb-3">
                <div class="col-3">
                  <div class="text-center">
                    <img src="../../../assets/images/Schedule.svg" width="25" height="25">
                    <p class="fs-12 mb-0 text-site-dark">Schedule</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="text-center">
                    <img src="../../../assets/images/favorite.svg" width="25" height="25">
                    <p class="fs-12 mb-0 text-site-dark">Text</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="text-center">
                    <img src="../../../assets/images/email.svg" width="25" height="25">
                    <p class="fs-12 mb-0 text-site-dark">Call</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="text-center">
                    <img src="../../../assets/images/email.svg" width="25" height="25">
                    <p class="fs-12 mb-0 text-site-dark">Email</p>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <a class="btn bg-site-darkRed border-site-darkRed text-white text-uppercase px-5 w-90" data-bs-target="#clientDetailsModal" data-bs-toggle="modal">Details</a>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
  <ClientDetailsModal/>
</div>
</template>

<script src="../js/client_details.js">
</script>

<style scoped>
@import "../css/client_details.css";
.carousel__slide {
  padding: 10px;
}
.carousel__item{
  width: 100%;
}
</style>
