<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div>
  <h3 class="mt-3 mb-4 d-flex justify-content-between align-items-center">
    <span class="text-site-dark fs-20 ff-poppins400 fw-400">July 18, 2022</span>
    <a href="" class="text-site-dark fs-20 ff-poppins400 fw-400">See all</a>
  </h3>
  <div class="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-5">
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-dSarkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>

  </div>
  </div>
  <div>
  <h3 class="mt-3 mb-4 d-flex justify-content-between align-items-center">
    <span class="text-site-dark fs-20 ff-poppins400 fw-400">July 18, 2022</span>
    <a href="" class="text-site-dark fs-20 ff-poppins400 fw-400">See all</a>
  </h3>
  <div class="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-5">
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card round-10 p-2">
        <img src="../../../assets/images/past_img.svg" class="card-img-top" alt="...">
        <div class="card-body px-0 pb-0">
          <h5 class="card-title">Professional Name</h5>
          <p class="card-text">Hair Cut - ( 1 Item )</p>
          <p class="card-text"><i class="bi bi-clock me-2"></i>
            Jun 18, 2022, 3:05 pm</p>
          <a class="btn bg-site-darkRed border-site-darkRed text-white px-5">$ 100</a>
        </div>
      </div>
    </div>
    
  </div>
  </div>
</div>
</template>

<script src="../js/past.js">
</script>

<style scoped>
@import "../css/past.css";
</style>
