<template>
  <div class="modal fade" id="clientDetailsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="clientDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content round-20">
        <div class="modal-header border-0">
          <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id="clientDetailsModalLabel">Client Name
            <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">TRANSACTION (CASH) DETAILS
                       July 18th 2022, 3:05 pm </span>
          </h5>
          <div class="text-center align-self-start">
            <button type="button" class="dropdown-toggle add_category_dropdown bg-transparent border-0 text-" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bi bi-three-dots" style="color: #99B2C6"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Action</a></li>
            </ul>
          </div>
        </div>
        <div class="modal-body">
         <div class="form-control px-5 mb-4">
           <div class="form-check d-flex gap-4 align-items-center">
             <input class="form-check-input client_details_form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1">
             <label class="form-check-label" for="flexRadioDefault1">
               <h3 class="mb-0 fs-16 fw-500 ff-poppins400 text-site-dark">Hair Cut</h3>
               <p class="mb-0 fs-16 fw-400 ff-poppins400 text-site-dark">$100</p>
             </label>
           </div>
         </div>
          <div class="mx-5 px-5 mb-4">
            <div class="form-check d-flex gap-4 align-items-center">
              <input class="form-check-input client_details_form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2">
              <label class="form-check-label" for="flexRadioDefault2">
                Send Request
              </label>
            </div>
          </div>
          <div class="mx-5 px-5 mb-4">
            <div class="form-check d-flex gap-4 align-items-center">
              <input class="form-check-input client_details_form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault3">
              <label class="form-check-label" for="flexRadioDefault3">
                Refund Charge
              </label>
            </div>
          </div>
          <div class="mx-5 px-5 mb-4">
            <div class="form-check d-flex gap-4 align-items-center">
              <input class="form-check-input client_details_form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault4">
              <label class="form-check-label" for="flexRadioDefault4">
                Change Client
              </label>
            </div>
          </div>
          <div class="mx-5 px-5 mb-4">
            <div class="form-check d-flex gap-4 align-items-center">
              <input class="form-check-input client_details_form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault5">
              <label class="form-check-label" for="flexRadioDefault5">
                Delete Charge
              </label>
            </div>
          </div>

        </div>
        <div class="text-center p-3">
          <button type="button" class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed w-80 py-3 round_5">confirm selection</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/client_details_modal.js">
</script>

<style scoped>
@import "../css/client_details_modal.css";
</style>
