import { store } from "../../../store";
import Mixin from "../../../mixin"
export default {
  name: "Sidebar",
  mixins: [Mixin],
  data() {
    return {
      store,
      isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
    };
  },
  methods: {
    closeSidebar: function () {
      this.store.active = false;
    },
    logout: function() {
      localStorage.removeItem('name');
      localStorage.removeItem('token');
      localStorage.removeItem("id");
      localStorage.removeItem("name");
      localStorage.removeItem("email");  
      localStorage.removeItem("role");
      localStorage.removeItem("isAdminVerified");
      window.location.href = '/';
    }
  },
};
