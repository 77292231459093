<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid">

      <div class="card border-0 px-5 py-5 rounded-0 shadow-sm" id="doc">
        <h1 class="fst-italic ff-poppins700">Cosmo <span class="text-site-maroon">Salon</span></h1>
        <div class="row g-3">
          <div class="col-12 col-sm-6">
            <p class="ff-poppins400 fs-20">{{professionalDetails.businessName}}</p>
            <p class="mb-0 fs-15">
              PO Box {{professionalDetails.zipCode}} <br>
              {{professionalDetails.streetAddress}} <br>
              {{professionalDetails.city}},{{professionalDetails.stateCode}} <br>
              {{professionalDetails.country}}
            </p>
            <p class="fs-15">Email : {{professionalDetails.email}}</p>
          </div>
          <div class="col-12 col-sm-6">
            <p class="ff-poppins400 fs-20">Invoice</p>
            <table border="0">
              <tr>
                <td style="color: #8DABC4">Invoice</td>
                <td> #{{orderDetails.invoiceId}}</td>
              </tr>
              <tr>
                <td style="min-width: 150px;color: #8DABC4">Billed On</td>
                <td>{{$filters.workingDate(orderDetails.createdAt)}}</td>
              </tr>
              <tr>
                <td style="color: #8DABC4">Terms</td>
                <td>On-Receipt</td>
              </tr>
              <tr>
                <td style="color: #8DABC4">Payment Status</td>
                <td>{{orderDetails.paymentStatus}}</td>
              </tr>
              <tr>
                <td style="color: #8DABC4">Invoice Status</td>
                <td>{{orderDetails.status}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-12 col-sm-6">
            <p class="ff-poppins400 fs-16 mb-2" style="color: #8DABC4">Bill To</p>
            <p class="ff-poppins500 fs-20 mb-2">{{billingAddress.name}}</p>
            <p class="mb-0 ff-poppins500 fs-16">
              {{billingAddress.addressOne}}<br>
              {{billingAddress.city}}, {{billingAddress.stateCode}} {{billingAddress.postCode}}<br>
              United States Of America
            </p>
          </div>
          <div class="col-12 col-sm-6" v-if="orderDetails.paymentStatus != 'Pending'">
            <div class="card border-2 rounded-0">
              <div class="d-flex">
                <span
                  class="bg-site-maroon px-5 py-2 text-center fs-20 fs-sm-18 text-white">{{orderDetails.paymentStatus == 'Paid' ? 'PAID' : orderDetails.paymentStatus == 'Partial Paid' ? 'PARTIAL PAID' : 'PENDING'}}</span>
                <span class="w-100 p-2 text-end fs-20 fs-sm-18" style="background: #E8E8E9;
                     border: 1.5px solid #BDBDBD;">on {{$filters.workingDate(orderDetails.paymentDate)}}</span>
              </div>
              <div class="card-body">
                <h2 class="mb-0 text-center">${{orderDetails.paymentAmount}} <span style="ont-weight: 400;
                   font-size: 20px;
                   line-height: 30px;
                   color: #A2A2A2;">USD</span></h2>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 table-responsive">
          <table border="0" class="w-100">
            <thead style="background: #E8E8E9;
            border: 2px solid #BDBDBD; padding: 5px 10px">
              <tr>
                <th class="py-2 px-3">Date</th>
                <th style="min-width: 120px">Name</th>
                <th>Type</th>
                <th style="min-width: 120px">Qty</th>
                <th>Price</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr style="border: 2px solid #BDBDBD;" v-for="(data, index) in itemList" :key="index">
                <td class="py-2 px-3">{{$filters.workingDate(orderDetails.createdAt)}}</td>
                <td>{{data.name}}</td>
                <td>{{data.type}}</td>
                <td>{{data.quantity}}</td>
                <td>${{data.price}}</td>
                <td>${{data.amount}}</td>
              </tr>
              <tr>
                <td colspan="3">
                </td>
                <td colspan="2">
                  <div class="d-flex gap-4">
                    <span style="min-width: 150px;">Subtotal</span>
                    <span>${{orderDetails.subTotal}}</span>
                  </div>
                  <div class="d-flex gap-4" v-if="parseFloat(orderDetails.discountAmount)">
                    <span style="min-width: 150px;">Discount</span>
                    <span>${{orderDetails.discountAmount}}</span>
                  </div>
                  <div class="d-flex gap-4">
                    <span style="min-width: 150px;">Total</span>
                    <span>${{orderDetails.total}}</span>
                  </div>
                  <div class="d-flex gap-4">
                    <span style="min-width: 150px;">Paid</span>
                    <span>${{orderDetails.paymentAmount ? orderDetails.paymentAmount : '0.00'}}</span>
                  </div>
                  <hr class="my-2" style="border-top: 2px solid #BDBDBD!important;">
                  <div class="d-flex gap-4">
                    <span class="ff-poppins500" style="min-width: 150px;">Amount Due</span>
                    <span>{{orderDetails.paymentAmount ? '$' + (parseFloat(orderDetails.total) - parseFloat(orderDetails.paymentAmount)) : '$' + orderDetails.total}}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h2 class="ff-poppins400 fs-16">Notes</h2>
          <p class="w_50 w-xs-100">
            This invoice can be used for tax purposes. To convert the amounts in your local
            currency, please use the average monthly exchange rate.

          </p>
        </div>
      </div>
      <div class="row gutters float-end mt-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="custom-actions-btns mb-5">
            <button type="button" class="
                  btn
                  text-uppercase
                  bg-site-darkRed
                  text-white
                  border-site-darkRed
                  rounded_5
                  me-2
                " data-bs-toggle="modal" data-bs-target="#addPayment" v-if="orderDetails.paymentStatus != 'Paid'">
              Pay
            </button>
            <button type="button" class="
                  btn
                  text-uppercase
                  bg-site-darkSeaGreen
                  text-white
                  border-site-darkSeaGreen
                  rounded_5
                  me-2 " v-if="orderDetails.paymentStatus != 'Paid'" @click="payFromClient">
                Pay from Client Card
            </button>
            <a href="javascript:void(0)" class="btn btn-secondary" @click="generatePDF">
              <i class="icon-printer"></i> Print
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addPayment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="addExpensesModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Card Information
            </h5>
            <button type="button" id="buttonClose" class="btn-close" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="payment-container preload" style="margin: 0 auto">
                  <div class="creditcard">
                    <div class="front">
                      <div id="ccsingle"></div>
                      <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471" xml:space="preserve">
                        <g id="Front">
                          <g id="CardBackground">
                            <g id="Page-1_1_">
                              <g id="amex_1_">
                                <path id="Rectangle-1_1_" class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z" />
                              </g>
                            </g>
                            <path class="darkcolor greydark"
                              d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                          </g>
                          <text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" class="st2 st3 st4">
                            {{
                                paymentInfo.cardNumber
                                  ? paymentInfo.cardNumber
                                  : "0123 4567 8910 1112"
                              }}
                          </text>
                          <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" class="st2 st5 st6">
                            {{
                                paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                              }}
                          </text>
                          <text transform="matrix(1 0 0 1 54.1074 389.8793)" class="st7 st5 st8">
                            cardholder name
                          </text>
                          <text transform="matrix(1 0 0 1 479.7754 388.8793)" class="st7 st5 st8">
                            expiration
                          </text>
                          <text transform="matrix(1 0 0 1 65.1054 241.5)" class="st7 st5 st8">
                            card number
                          </text>
                          <g>
                            <text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" class="st2 st5 st9">
                              {{ expireDate ? expireDate : "01/23" }}
                            </text>
                            <text transform="matrix(1 0 0 1 479.3848 417.0097)" class="st2 st10 st11">
                              VALID
                            </text>
                            <text transform="matrix(1 0 0 1 479.3848 435.6762)" class="st2 st10 st11">
                              THRU
                            </text>
                            <polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
                          </g>
                          <g id="cchip">
                            <g>
                              <path class="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                        c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                            </g>
                            <g>
                              <g>
                                <rect x="82" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
                              </g>
                              <g>
                                <path class="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                            c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                            C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                            c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                            c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                              </g>
                              <g>
                                <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
                              </g>
                              <g>
                                <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
                              </g>
                              <g>
                                <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g id="Back"></g>
                      </svg>
                    </div>
                    <div class="back">
                      <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
                        style="enable-background: new 0 0 750 471" xml:space="preserve">
                        <g id="Back">
                          <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
                        </g>
                        <g id="Back">
                          <g id="Page-1_2_">
                            <g id="amex_2_">
                              <path id="Rectangle-1_2_" class="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                        C0,17.9,17.9,0,40,0z" />
                            </g>
                          </g>
                          <rect y="61.6" class="st2" width="750" height="78" />
                          <g>
                            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                    C707.1,246.4,704.4,249.1,701.1,249.1z" />
                            <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
                            <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
                            <path class="st5"
                              d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                          </g>
                          <text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" class="st6 st7">
                            {{
                                paymentInfo.securityCode
                                  ? paymentInfo.securityCode
                                  : "985"
                              }}
                          </text>
                          <g class="st8">
                            <text transform="matrix(1 0 0 1 518.083 280.0879)" class="st9 st6 st10">
                              security code
                            </text>
                          </g>
                          <rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
                          <rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
                          <text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" class="st12 st13">
                            {{
                                paymentInfo.name ? paymentInfo.name : "JOHN DOE"
                              }}
                          </text>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="field-container mb-2">
                  <label class="payment-label" for="name">Name</label>
                  <input class="payment-input" id="name" maxlength="20" type="text" v-model="paymentInfo.name" />
                </div>
                <div class="field-container mb-2">
                  <label class="payment-label" for="cardnumber">Card Number</label>
                  <!-- <span id="generatecard">generate random</span> -->
                  <input id="cardnumber" type="text" pattern="^[0-9]$" v-on:keyup="formatCCnum" name="cardnumber" class="payment-input"
                    v-model="paymentInfo.cardNumber" />
                  <svg id="ccicon" class="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"></svg>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <div class="field-container">
                      <label class="payment-label" for="expirationdate">Expiration (mm/yy)</label>
                      <input id="expirationdate" class="payment-input" type="text" maxlength="5" pattern="[0-9]*"
                        inputmode="numeric" v-model="expireDate" />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="field-container">
                      <label class="payment-label" for="securitycode">Security Code</label>
                      <input id="securitycode" class="payment-input" type="text" maxlength="4" pattern="[0-9]*"
                        v-model="paymentInfo.securityCode" />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="field-container">
                      <label class="payment-label" for="securitycode">Amount</label>
                      <input class="payment-input" type="text" v-model="paymentInfo.amount" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="
                  btn
                  text-uppercase
                  bg-site-darkRed
                  text-white
                  border-site-darkRed
                  rounded_5
                  py-3
                  px-5
                  me-4
                " @click="completePayment">
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/checkout-details.js">
</script>

<style scoped>
  .edit-button {
    background-color: #49abab;
  }
</style>
