<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div class="custom-container-fluid">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <div>
        <h3 class="fw-600 mb-0">Edit Products</h3>
      </div>
    </div>
    <div class="mt-4">
      <form>
        <div class="row g-4 mb-4">
          <div class="col-12 col-sm-6">
            <label for="file" class="add_product_file_upload">Upload File</label>
            <input type="file" id="file" ref="image" @change="onImageChange($event)" class="d-none">
          </div>
          <div class="col-12 col-sm-6">
            <label class="form-label text-blueish">UPLOADING : &nbsp;</label>
            <!-- <div class="d-flex gap-3">
              <div>
                <img src="../../../assets/images/past_img.svg" width="50">
              </div>
              <div class="w-100">
                <a class="float-end btn p-0 border-0"><i class="bi bi-x-circle"></i></a>
                <p class="mb-1 text-blueish">546 KB</p>
                <input class="seek_slider" type="range" min="0" max="100" maxlength="100" value="2">
                <p class="mb-0 text-green">100 % Completed</p>
              </div>
            </div> -->
            <img v-if="previewImage" :src="previewImage" height="100" width="150"/>
            <img v-else :src="$imageURL + 'product/' + productDetails.image" height="100" width="150"/>
          </div>
        </div>
        <div class="row g-4 mb-4">
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label">Category</label>
            <select v-model="selectedCategory" class="form-select mb-3" >
                <option v-for="(row, index) in productCategory" :key="index" :value="{ id: row.id, name: row.name }">
                    {{ row.name }}
                </option>
            </select>
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label">Brand</label>
            <select v-model="selectedBrand" class="form-select mb-3" >
                <option v-for="(row, index) in productBrand" :key="index" :value="{ id: row.id, name: row.name }">
                    {{ row.name }}
                </option>
            </select>
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label text-blueish">Product Name</label>
            <input type="text" v-model="productDetails.name" class="form-control" placeholder="Enter name">
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label text-blueish">Client Price</label>
            <input type="text" v-model="productDetails.clientPrice" class="form-control" placeholder="Enter price">
          </div>
          <div class="col-12 col-sm-6 col-xl-6">
            <label class="form-label text-blueish">Optional Information</label>
            <textarea class="form-control rounded-2" rows="4"
                v-model="productDetails.information" placeholder="Enter information"></textarea>
          </div>
        </div>
        <h4>Inventory</h4>
        <div class="row g-4 mb-4">
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label">My Cost</label>
            <input type="text" v-model="productDetails.inventoryCost" class="form-control" placeholder="Enter cost">
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label text-blueish">Inventory Quantity</label>
            <input type="text" v-model="productDetails.inventoryQuantity" class="form-control" placeholder="Enter inventory quantity">
          </div>
          <div class="col-12 col-sm-6 col-xl-3">
            <label class="form-label text-blueish">Low Quantity</label>
            <input type="text" v-model="productDetails.lowQuantity" class="form-control" placeholder="Enter low quantity">
          </div>
        </div>
        <div class="mt-5">
          <button type="button" @click="updateProduct" class="btn bg-site-darkRed border-site-darkRed text-white ff-poppins700 rounded_4 px-3">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script src="../js/edit-products.js">
</script>

<style scoped>
@import "../css/add_products.css";
</style>
