import Loading from "vue-loading-overlay";
import {authHeader} from "../../../auth";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
    name: "Client",
    components: {
        Loading,
        VPagination
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            clientList: [],
            name: "",
            phoneNo: "",
            email: "",
            page:1,
            limit: 10,
            totalPage: 0,
            singleClient: {},
            isAdminVerified: localStorage.getItem('isAdminVerified') == "true" ? true : false
        }
    },
    async created() {
        document.title = "Cosmo Salon - Client";
        let params = '?page=' + this.page + '&limit=' + this.limit;
        let config = {
            method: "GET",
            url: this.$serverURL + this.$api.client.getAllURL + params,
            headers: authHeader()
        };
        await this.getClientList(config);
    },
    methods: {
        updateHandle: async function() {
            let params = '?page=' + this.page + '&limit=' + this.limit;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.client.getAllURL + params,
                headers: authHeader()
            };
            await this.getClientList(config);
        },
        getClientList: async function(config) {
            this.isLoading = true;
            
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.clientList = response.data.data.data;
                    this.totalPage = response.data.data.pageCount;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        checkValidation: function() {
            if(!this.name) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert client name!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.phoneNo) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert contact no!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert email!"
                });
                this.isLoading = false;
                return false;
            }
            return true;
        },
        addClient: async function() {
            if(this.checkValidation()){
                this.isLoading = true;
                let data = {
                    fullName: this.name,
                    phoneNo: this.phoneNo,
                    email: this.email
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.client.requestURL,
                    data: data,
                    headers: authHeader()
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.name = "";
                        this.phoneNo = "";
                        this.email = "";
                    }
                    this.isLoading = false;
                    this.updateHandle();
                    document.getElementById("closeAdd").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
            
        },
        deleteClient: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
                }).then(async(result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let config = {
                            method: "DELETE",
                            url: this.$serverURL + this.$api.client.removeClient + id,
                            headers: authHeader()
                        };
                        this.$axios(config).then((response) => {
                            if(response.status == 200) {
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                  });
                            }
                            this.isLoading = false;
                            this.updateHandle();
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
        },
        setId: function(index) {
            this.singleClient = this.clientList[index];
        },
        checkUpdateValidation: function() {
            if(!this.singleClient.fullName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert Client name!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.singleClient.phoneNo) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert contact no!"
                });
                this.isLoading = false;
                return false;
            }
            if(!this.singleClient.email) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please insert email!"
                });
                this.isLoading = false;
                return false;
            }
            return true;
        },
        updateClient: function() {
            if(this.checkUpdateValidation()){
                this.isLoading = true;
                let data = {
                    fullName: this.singleClient.fullName,
                    phoneNo: this.singleClient.phoneNo,
                    email: this.singleClient.email
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.client.getAllURL + '/' + this.singleClient.id,
                    data: data,
                    headers: authHeader()
                };
                this.$axios(config).then((response) => {
                    if(response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', this.$swal.stopTimer)
                              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                          });
                    }
                    this.isLoading = false;
                    this.updateHandle();
                    document.getElementById("closeUpdate").click();
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!"
                    });
                });
            }
            
        }
    }
}
