<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid" v-if="isAdminVerified">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 class="fw-600">Appointments</h3>
        </div>
      </div>
      <div style="margin-top: 5%;">
        <div class="card p-3 shadow border-0 round-17">
          <div class="modal-header border-0">
            <h5 class="modal-title text-site-dark fs-20 fw-600 ff-poppins400" id>Appointment List</h5>
          </div>
          <div class="table-container">
            <div class="table-section">
              <div class="table-responsive">
                <table class="custom_table custom_table_header_white">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th style="min-width: 100px">Invoice</th>
                      <th style="min-width: 100px">Customer Name</th>
                      <th style="min-width: 100px">Total Amount</th>
                      <th style="min-width: 100px">Paid Amount</th>
                      <th style="min-width: 100px">Requested Date</th>
                      <th style="min-width: 100px">Start Time</th>
                      <th style="min-width: 100px">End TIme</th>
                      <th style="min-width: 100px">Payment Status</th>
                      <th style="min-width: 100px">Service Status</th>
                      <th style="min-width: 100px">Booking Status</th>
                      <th style="min-width: 100px">Appointment Status</th>
                      <!-- <th >Created Date</th> -->
                      <th style="min-width: 250px">Options</th>
                    </tr>
                  </thead>
                  <tbody v-if="orderList.length > 0">
                    <tr v-for="(data, index) in orderList" :key="index">
                      <td class="fw-900" data-label="Sl :">{{index + 1}}</td>
                      <td data-label="Invoice :">{{data.invoiceId}}</td>
                      <td data-label="Customer Name :">{{data.customerName}}</td>
                      <td data-label="Total Amount :">
                        <a class="bg-site-darkSeaGreen round-10 px-2 text-white">${{data.total}}</a>
                      </td>
                      <td data-label="Paid Amount :">
                        <a
                          class="bg-site-darkSeaGreen round-10 px-2 text-white"
                        >${{data.paymentAmount}}</a>
                      </td>
                      <td
                        data-label="Requested Date :"
                      >{{$filters.formatDate(data.serviceRequestDate)}}</td>
                      <td data-label="Requested Time Slot :">{{data.startTime}}</td>
                      <td data-label="Requested Time Slot :">{{data.endTime}}</td>
                      <!-- <td data-label="Payment Status :">{{data.paymentStatus}}</td> -->
                      <td data-label="Invoice Status :">
                        <select
                          v-model="data.paymentStatus"
                          class="form-select"
                          :onchange="
                          () => {
                            updatePaymentStatus(data);
                          }
                        "
                        >
                          <option value="Paid">Paid</option>
                          <option :disabled="data.paymentStatus=='Paid'" value="Pending">Pending</option>
                        </select>
                      </td>
                      <td
                        data-label="Service Status :"
                        v-if="data.serviceStatus=='In Line' && data.isApproved!='Denied'"
                      >
                        <button
                          @click="setData(data)"
                          type="button"
                          class="btn add_service_btn"
                          data-bs-target="#getInLine"
                          data-bs-toggle="modal"
                        >Get In Line</button>
                      </td>
                      <td data-label="Service Status :" v-else>{{data.serviceStatus}}</td>
                      <td data-label="Booking Status :">{{data.isApproved}}</td>
                      <td data-label="Invoice Status :">
                        <select
                          v-model="data.status"
                          class="form-select"
                          :onchange="
                          () => {
                            updateOrder(data);
                          }
                        "
                        >
                          <option value="PENDING">PENDING</option>
                          <option value="COMPLETED">COMPLETED</option>
                        </select>
                      </td>
                      <!-- <td>{{$filters.formatDate(data.createdAt)}}</td> -->
                      <td data-label="Options :">
                        <div class="d-flex justify-content-end flex-wrap gap-2">
                          <button
                            @click="approval('Approved', data.id)"
                            type="button"
                            class="btn btn-outline-primary px-3"
                            :disabled="data.isApproved != 'Not Approved'"
                          >{{ data.isApproved == 'Approved' ? 'Approved' : 'Approve' }}</button>
                          <button
                            @click="approval('Denied', data.id)"
                            type="button"
                            class="btn btn-outline-danger px-3"
                            :disabled="data.isApproved != 'Not Approved'"
                          >{{ data.isApproved == 'Denied' ? 'Denied' : 'Deny' }}</button>
                          <button
                            title="view"
                            @click="$router.push('/checkout-details/' + data.invoiceId)"
                            class="btn edit-button px-4"
                          >
                            <i class="bi bi-eye text-white"></i>
                          </button>
                          <!-- <button title="delete" @click="deleteOrder(data.id)" class="btn btn-sm btn-danger action_btn">
                          <i class="fas fa-trash"></i></button>-->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="9">
                        <p class="mb-0 text-center">No Data Found !</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <nav
            class="page-navigation justify-content-center d-flex mt-3"
            aria-label="page-navigation"
          >
            <v-pagination
              v-if="orderList.length > 0"
              v-model="page"
              @update:modelValue="updateHandler"
              :pages="totalPage"
              :range-size="1"
              active-color="#FF8400"
            ></v-pagination>
          </nav>
        </div>
      </div>
    </div>
    <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
      <p style="text-align:center;">{{$adminText}}</p>
    </div>
    <div
      class="modal fade"
      id="getInLine"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="addClientModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content round-20">
          <div class="modal-header border-0">
            <h5
              class="modal-title text-site-dark fs-20 fw-600 ff-poppins400"
              id="addClientModalLabel"
            >
              Get In Line
              <!-- <span class="m-0 text-site-dark fs-13 fw-400 ff-poppins400 d-block">Lorem ipsum  dolor sit amet </span> -->
            </h5>
            <button
              type="button"
              id="closeAdd"
              class="btn-close text-site-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-4 g-4">
              <div class="col-12 col-sm-12">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Service Request Date</label>
                <p
                  class="alert alert-primary"
                  role="alert"
                  style="text-align: center"
                >{{$filters.formatDate(singleAppointment.serviceRequestDate)}}</p>
              </div>
              <div class="col-12 col-sm-12">
                <!-- <label class="form-label text-site-dark ff-poppins400 fs-17"></label> -->
                <p
                  class="alert alert-primary"
                  role="alert"
                  style="text-align: center"
                >Service Duration:&nbsp;{{singleAppointment.serviceDuration+' min'}},&ensp;Requested Time Slot:&nbsp;{{singleAppointment.startTime + ' - ' + singleAppointment.endTime}}</p>
              </div>
              <div class="col-12 col-sm-12">
                <label class="form-label text-site-dark ff-poppins400 fs-17">Select Time Slot</label>
                <div class="w_100 w-sm-100">
                  <div class="schedule-time-section">
                    <div class="row gy-3">
                      <div v-for="(time, row) in times" :key="row" class="col-4 col-lg-6 col-xl-4">
                        <input
                          type="checkbox"
                          v-model="selectedTime[row]"
                          @change="
                            () => {
                              handleAssignValue(
                                row,
                                selectedTime[row],
                                time
                              );
                            }
                          "
                          :value="time"
                          class="btn-check"
                          :id="'check' + row +'box'"
                          :disabled="reserved[row] == true"
                        />
                        <label
                          class="btn shadow schedule-time-section-checkbox"
                          :for="'check'+ row +'box'"
                        >{{time}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center p-3">
            <button
              type="button"
              class="btn text-uppercase bg-site-darkRed text-white border-site-darkRed rounded-0 w-100 py-3"
              @click="bookGetInLine"
            >Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/appointment-list.js">
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0;
    vertical-align: middle !important;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table.custom_table.custom_table_header_grayish tbody tr {
    border-bottom: 3px solid #b3b0b0 !important;
    background-color: #f7f6f6 !important;
  }
  table.custom_table.custom_table_header_white tbody tr td {
    text-align: right !important;
    background-color: #f7f6f6 !important;
  }
  table tr {
    border-bottom: 3px solid #ddd !important;
    display: block;
    margin-bottom: 0.725em;
    background-color: #b3b0b0 !important;
  }
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right !important;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
@import url("../../../assets/css/pagination.css");

.edit-button {
  background-color: #49abab;
}
.schedule-time-section {
  height: 268px;
  overflow-x: hidden;
  overflow-y: auto;
}
.schedule-time-section-checkbox {
  border: none !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-check:checked + .schedule-time-section-checkbox {
  color: #ffffff !important;
  background-color: var(--site-maroon) !important;
  border-color: var(--site-maroon) !important;
}
</style>
