<template>
    <div class="body_section ms-0 px-3 overflow-y-auto d-flex align-items-center justify-content-center">
        <div class="login_page_form_section">
            <div class="text-center">
                <h5 class="ff-poppins600 text-black fs-20 mb-0">Welcome to</h5>
                <h4 class="ff-poppins700 fs-23"><span class="text-black">cosmo</span><span
                        class="text-site-maroon">Salon</span></h4>
            </div>
            <form>
                <div class="mb-4">
                    <label class="form-label">Email</label>
                    <input type="email" v-model="email" class="form-control form-control-lg"
                        placeholder="Enter your mail address" required>
                </div>
                <div class="mb-4">
                    <label class="form-label">Password</label>
                    <input type="password" v-on:keyup.enter="signIn" v-model="password"
                        class="form-control form-control-lg" placeholder="Enter your password" required>
                </div>
                <div class="text-end mb-4">
                    <a class="btn border-0 p-0 text-decoration-underline text-site-dark fs-17 ff-poppins400 text-uppercase"
                        @click="$router.push('/forgot-password')">forget password</a>
                </div>
                <div class="text-center">
                    <button type="button" @click="signIn"
                        class="btn bg-site-darkRed border-site-darkRed ff-poppins700 fs-20 text-white px-5 text-uppercase">Login</button>
                    <p class="text-site-darkRed ff-poppins700 fs-13 text-uppercase mt-3">Don’t you have account? <a
                            class="btn border-0 p-0 text-site-darkRed" @click="$router.push('/signup')">create an
                            account</a></p>
                </div>
            </form>
        </div>
    </div>
</template>

<script src="../js/Signin.js"></script>

<style scoped>
@import "../../../assets/css/login.scss";
</style>
