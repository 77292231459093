<template>
  <div>
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
    <div class="custom-container-fluid">
      <div class="add_service_card py-3 px-2 px-sm-3 px-md-4">
        <form @submit.prevent="updateService()">
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Service Name</label>
              <input v-model="serviceDetails.name" class="form-control text-site-gray-dark rounded-0" placeholder="Enter service name">
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Total duration</label>
              <select class="form-select"  v-model="serviceDetails.duration" aria-label="Default select example">
                <option style="display:none" value selected>Select Duration</option>
                <option value="15">15 min</option>
                <option value="30">30 min</option>
                <option value="45">45 min</option>
                <option value="60">60 min</option>
                <option value="75">75 min</option>
                <option value="90">90 min</option>
                <option value="105">105 min</option>
                <option value="120">120 min</option>
              </select>
              <!-- <input v-model="serviceDetails.duration" class="form-control text-site-gray-dark rounded-0" placeholder="Enter total duration"> -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Price</label>
              <input v-model="serviceDetails.price" class="form-control text-site-gray-dark rounded-0" placeholder="Enter price">
            </div>
          </div>
                   <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">
                Category
              </label>
              <!-- <v-select style="background-color: white;" :options="categoryList" v-model="category"></v-select> -->
              <v-select
                style="background: #fff;"
                :options="categoryList"
                @option:selected="getSubCategories"
                v-model="category"
              ></v-select>
              <!-- <Multiselect
                v-model="category"
                id="inputVendor"
                :options="categoryList"
                autocomplete="off"
                :searchable="true"
                @select="getSubCategories"
              /> -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">
                Sub Category
              </label>
              <v-select
                style="background: #fff;"
                :options="subCategoryList"
                v-model="subCategory"
              ></v-select>
              <!-- <Multiselect
                v-model="subCategory"
                id="inputVendor"
                :options="subCategoryList"
                autocomplete="off"
                :searchable="true"
              /> -->
            </div>
          </div>
          <div class="mb-4">
            <label class="form-label text-site-dark">Service Details</label>
            <textarea v-model="serviceDetails.details" class="form-control text-site-gray-dark" rows="4"
              placeholder="Enter service details"></textarea>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6">
              <div class="card p-3 justify-content-center min-height-150">
                <div class="d-flex justify-content-between gap-4 align-items-center">
                  <div>
                    <h4 class="fs-18 ff-poppins400 mb-2 text-site-dark">Starting at Price</h4>
                    <p class="text-site-gray-dark m-0">Your online booking site will indicate this is a staring price
                    </p>
                  </div>
                  <div>
                    <div class="form-check form-switch">
                      <input v-model="serviceDetails.isStartingPrice" class="form-check-input shadow-none" type="checkbox" role="switch"
                        id="flexSwitchCheckDefault">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="card p-3 min-height-150 justify-content-center">
                <div class="d-flex justify-content-between gap-4 align-items-center">
                  <div>
                    <h4 class="fs-18 ff-poppins400 mb-2 text-site-dark">Price Hidden</h4>
                    <p class="text-site-gray-dark m-0">Your online booking site will not show the dollar price of this
                      service It will just show “ Price Varies”.</p>
                  </div>
                  <div>
                    <div class="form-check form-switch">
                      <input v-model="serviceDetails.isPriceHidden" class="add_service_card_form-check-input form-check-input shadow-none" type="checkbox"
                        role="switch" id="flexSwitchCheckDefault">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4 mb-4">
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Colors
                <span class="text-site-dark d-block fs-12">Choose a color display in calender</span>
              </label>
              <div class="card p-3 justify-content-center">
                <div class="">
                  <div class="d-flex gap-2 flex-wrap">
                    <input class="form-check-input add_service_card_checkbox" :style="'background-color:' + data.code + '!important;border-color: ' + data.code + '!important;'" v-model="color" type="radio" :id="index"
                      :value="{name: data.name, code: data.code}" v-for="(data, index) in colorList" :key="index">
                  </div>

                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Category
                <span class="text-site-dark d-block fs-12">
                  &nbsp;
                </span>
              </label>
                <v-select style="background-color: white;" :options="categoryList" v-model="category"></v-select>
              
            </div> -->
            <div class="col-12 col-sm-6 col-md-4">
              <label class="form-label text-site-dark">Website
                <span class="text-site-dark d-block fs-12">&nbsp;</span>
              </label>
              <div class="card p-3 justify-content-center min-height-150">
                <h4 class="ff-poppins400 fs-18 text-site-dark">Show on Website</h4>
                <p class="text-site-gray-dark">Your Online booking site will
                  indicate this is a starting price</p>
                <div class="form-check form-switch">
                  <input v-model="serviceDetails.isShownWebsite" class="add_service_card_form-check-input form-check-input shadow-none" type="checkbox" role="switch"
                    id="flexSwitchCheckDefault">
                </div>
              </div>
            </div>
          </div>
          {{typeof(serviceDetails.image)}}
          <div class="mb-2">
            <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="113" width="200" />
            <img v-else-if="serviceDetails.image && serviceDetails.image != 'null'" :src="$imageURL + 'service/' + serviceDetails.image" height="113" width="200" />
            <img :src="$imageURL + 'service-sub-category/' + serviceDetails.subCategoryImage" v-else-if="serviceDetails.subCategoryImage" height="113" width="200">
            <img v-else src="../../../../assets/images/no-image.jpg" height="113" width="200" />
          </div>
          <div class="text-center">
            <label for="file-upload" class="me-2 btn addAttachment_btn">Add Photo</label>
            <input id="file-upload" type="file" style="display: none" ref="serviceImage" accept="image/*"
                      @change="uploadFeaturedImage" />
            <button type="submit" class="ms-2 btn submit_btn">Update</button>
          </div>
        </form>
      </div>
    </div>
    <AddCategory />
  </div>
</template>

<script src="../js/update_service.js">
</script>

<style scoped>
  @import "../css/add_service.css";
</style>