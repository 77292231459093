<template>
  <div>
    <div class="sidebar" :class="[store.active ? 'close' : '']">
      <div class="logo_section d-flex justify-content-center align-items-center position-relative ps-3 pe-3">
        <a class="sidebar_toggle_btn" @click="store.active =! store.active"
          :class="[store.active ? 'sidebar_active_toggle' : '']">
          <i class="bi bi-arrow-right"></i>
        </a>
        <h4 class="text-center">
          <span class="logo_section_title">COSMO</span>
          <span class="d-block logo_section_title_sub">Professional DASHBOARD</span>
        </h4>
      </div>
      <ul class="list-unstyled sidebar_body">
        <!-- <li class="mb-1 sidebar_list">
          <router-link tag="a" to="/dashboard" class="sidebar_link">
            <span class="material-icons-outlined sidebar_link_icon">dashboard</span>
            <span class="sidebar_link_text">Dashboard</span>
          </router-link>
        </li> -->
        <li class="mb-1 sidebar_list">
          <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
            data-bs-target="#dashboard-collapse1" aria-expanded="false">
            <span class="bi bi-grid sidebar_link_icon"></span>
            <span class="sidebar_link_text">Dashboard</span>
          </a>
          <div class="collapse sidebar_submenu mt-1" id="dashboard-collapse1">
            <ul class="list-unstyled">
              <li class="mb-1" @click="store.active =! store.active">
                <router-link tag="a" to="/dashboard" class="sidebar_submenu_link">Dashboard
                </router-link>
              </li>
              <li class="mb-1" @click="store.active =! store.active">
                <router-link tag="a" to="/analytics" class="sidebar_submenu_link">Analytical Dashboard</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/calendar" class="sidebar_link">
            <span class="bi bi-calendar sidebar_link_icon"></span>
            <span class="sidebar_link_text">Calendar</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
                <router-link tag="a" to="/profile" class="sidebar_link">
                  <span class="bi bi-person-circle sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Profile</span>
                </router-link>
              </li>
            <li class="mb-1 sidebar_list" @click="store.active =! store.active">
                <router-link tag="a" to="/portfolio" class="sidebar_link">
                    <span class="bi bi-palette-fill sidebar_link_icon"></span>
                    <span class="sidebar_link_text">Portfolio</span>
                </router-link>
            </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link to="/checkout" class="sidebar_link">
            <span class="bi bi-cart-dash sidebar_link_icon"></span>
            <span class="sidebar_link_text">Checkout</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link to="/appointment" class="sidebar_link">
            <span class="bi bi-journal-plus sidebar_link_icon"></span>
            <span class="sidebar_link_text">Appointment</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link to="/checkout-list" class="sidebar_link">
            <span class="fas fa-chart-pie sidebar_link_icon"></span>
            <span class="sidebar_link_text">Order List</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link to="/appointment-list" class="sidebar_link">
            <span class="bi bi-journal-plus sidebar_link_icon"></span>
            <span class="sidebar_link_text">Appointment List</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/clients" class="sidebar_link">
            <span class="bi bi-person-fill sidebar_link_icon"></span>
            <span class="sidebar_link_text">Clients</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/products" class="sidebar_link">
            <span class="bi bi-p-square sidebar_link_icon"></span>
            <span class="sidebar_link_text">Products</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/service-list" class="sidebar_link">
            <span class="bi bi-tools sidebar_link_icon"></span>
            <span class="sidebar_link_text">Services</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/expenses" class="sidebar_link">
            <span class="bi bi-cash sidebar_link_icon"></span>
            <span class="sidebar_link_text">Expenses</span>
          </router-link>
        </li>
        <li class="mb-1 sidebar_list" @click="store.active =! store.active">
          <router-link tag="a" to="/report" class="sidebar_link">
            <span class="bi bi-receipt sidebar_link_icon"></span>
            <span class="sidebar_link_text">Reports</span>
          </router-link>
        </li>

        <li class="mb-1 sidebar_list">
          <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
            data-bs-target="#settings-collapse1" aria-expanded="false">
            <span class="bi bi-gear sidebar_link_icon"></span>
            <span class="sidebar_link_text">Settings</span>
          </a>
          <div class="collapse sidebar_submenu mt-1" id="settings-collapse1">
            <ul class="list-unstyled">
              <!-- <li class="mb-1 sidebar_list" v-if="isAdminVerified">
                <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                  data-bs-target="#service-collapse1" aria-expanded="false">
                  <span class="bi bi-grid sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Service</span>
                </a>
                <div class="collapse sidebar_submenu mt-1" id="service-collapse1">
                  <ul class="list-unstyled">
                    <li class="mb-1">
                      <router-link tag="a" to="/service-color" class="sidebar_submenu_link">Service Color</router-link>
                    </li>
                  </ul>
                </div>
                <div class="submenu">
                  <span class="submenu_title">Service</span>
                  <hr class="m-0 mb-1 bg-white" />
                  <ul class="list-unstyled">
                  </ul>
                </div>
              </li> -->
              <li class="mb-1 sidebar_list" v-if="isAdminVerified">
                <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                  data-bs-target="#product-collapse1" aria-expanded="false">
                  <span class="bi bi-p-square sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Product</span>
                </a>
                <div class="collapse sidebar_submenu mt-1" id="product-collapse1">
                  <ul class="list-unstyled">
                    <li class="mb-1" @click="store.active =! store.active">
                      <router-link tag="a" to="/product-category" class="sidebar_submenu_link">Category
                      </router-link>
                    </li>
                    <li class="mb-1" @click="store.active =! store.active">
                      <router-link tag="a" to="/product-brand" class="sidebar_submenu_link">Brand</router-link>
                    </li>
                  </ul>
                </div>
                <div class="submenu">
                  <span class="submenu_title">Product</span>
                  <hr class="m-0 mb-1 bg-white" />
                  <ul class="list-unstyled">
                    <li class="p-0" @click="store.active =! store.active">
                      <router-link tag="a" to="/product-category" class="submenu_link">Category</router-link>
                    </li>
                    <li class="p-0" @click="store.active =! store.active">
                      <router-link tag="a" to="/product-brand" class="submenu_link">Brand</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="mb-1 sidebar_list" v-if="isAdminVerified">
                <a class="sidebar_link sidebar_link_toggle position-relative collapsed" data-bs-toggle="collapse"
                  data-bs-target="#expense-collapse1" aria-expanded="false">
                  <span class="bi bi-cash sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Expense</span>
                </a>
                <div class="collapse sidebar_submenu mt-1" id="expense-collapse1">
                  <ul class="list-unstyled">
                    <li class="mb-1" @click="store.active =! store.active">
                      <router-link tag="a" to="/expense-category" class="sidebar_submenu_link">Category
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="submenu">
                  Expense
                  <hr class="m-0 mb-1 bg-white" />
                  <ul class="list-unstyled">
                    <li class="p-0" @click="store.active =! store.active">
                      <router-link tag="a" to="/expense-category" class="submenu_link">Category</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- <li class="mb-1 sidebar_list">
                <router-link tag="a" to="/platform-payment" class="sidebar_link">
                  <span class="bi bi-credit-card sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Platform Payment</span>
                </router-link>
              </li> -->
              <li @click="store.active =! store.active" class="mb-1 sidebar_list" v-if="isAdminVerified">
                <router-link tag="a" to="/payment-log" class="sidebar_link">
                  <span class="bi bi-wallet2 sidebar_link_icon"></span>
                  <span class="sidebar_link_text">Payment Log</span>
                </router-link>
              </li>
              <li @click="store.active =! store.active" class="mb-1 sidebar_list" v-if="isAdminVerified">
                <router-link tag="a" to="/payment-info" class="sidebar_link">
                  <span class="bi bi-card-list sidebar_link_icon"></span>
                  <span class="sidebar_link_text">My Card Information</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="sidebar_footer d-flex align-items-center justify-content-start">
        <a class="btn border-0 d-flex gap-4 align-items-center
         fs-16 ff-poppins400 fw-600 text-site-dark" @click="logout">
          <span class="material-icons-outlined">login</span>
          Log Out</a>
      </div>
    </div>
    <div id="side-drawer-void" class="position-fixed" @click="store.active = false"></div>
  </div>
</template>

<script src="../js/sidebar.js"></script>

<style scoped>
  @import "../css/sidebar.css";
</style>
