<template>
<div>
  <loading :active='isLoading' :is-full-page="fullPage" :loader='loader' />
  <div class="custom-container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3>More</h3>
      </div>
      <div>
        <button @click="goRoute()" class="btn add_service_btn">add service</button>
      </div>
    </div>
    <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-4 mt-5">
      <div class="col">
        <div class="more_card" @click="$router.push('/service-list')">
          <div class="more_card_top">
            <img src="../../../assets/images/services.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Services</h5>
        </div>
      </div>
      <div class="col">
        <div class="more_card" @click="$router.push('/products')">
          <div class="more_card_top">
            <img src="../../../assets/images/products.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Products</h5>
        </div>
      </div>
      <div class="col">
        <div class="more_card" @click="$router.push('/analytics')">
          <div class="more_card_top">
            <img src="../../../assets/images/analytics.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Analytics</h5>
        </div>
      </div>
      <div class="col">
        <div class="more_card" @click="$router.push('/report')">
          <div class="more_card_top">
            <img src="../../../assets/images/report.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Report</h5>
        </div>
      </div>
      <!-- <div class="col">
        <div class="more_card">
          <div class="more_card_top">
            <img src="../../../assets/images/share.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Share</h5>
        </div>
      </div> -->
      <div class="col">
        <div class="more_card" @click="$router.push('/expenses')">
          <div class="more_card_top">
            <img src="../../../assets/images/analytics.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Expenses</h5>
        </div>
      </div>
      <!-- <div class="col">
        <div class="more_card">
          <div class="more_card_top">
            <img src="../../../assets/images/gift.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Gift Card</h5>
        </div>
      </div> -->
      <!-- <div class="col">
        <div class="more_card">
          <div class="more_card_top">
            <img src="../../../assets/images/more-icon1.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Text
            Marketing</h5>
        </div>
      </div> -->
      <!-- <div class="col">
        <div class="more_card">
          <div class="more_card_top">
            <img src="../../../assets/images/carbon_text-creation.svg">
          </div>
          <h5 class="ff-poppins400 fw-400 fs-18 text-site-dark mt-2 mb-0">Text
            Marketing</h5>
        </div>
      </div> -->
    </div>
  </div>
</div>
</template>

<script src="../js/more.js">
</script>

<style scoped>
@import "../css/more.css";
</style>
